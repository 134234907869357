import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactItemSlider from 'react-items-slider';
import { Modal } from 'react-bootstrap';
import * as classServices from '../../services/learnWithBthriveServices';
import EventModal from '../../commonComponents/eventmodal';
class LearnItemSlider extends Component {
  state = {
    classes: [],
    classData: '',
  };

  componentDidMount = async () => {
    const { data } = await classServices.getUpcomingClasses();
    if (data) {
      let classes = data.classes;
      classes.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));
      this.setState({ classes: classes });
    }
  };

  handleModalShowHide(data) {
    this.setState({ showHide: !this.state.showHide });
    this.setState({ classData: data });
  }

  getListOfClasses = () => {
    if (this.state.classes.length === 0) return null;
    return (
      <ReactItemSlider productsInView="max" arrowSize="small">
        {this.state.classes.map((classDetail, index) => {
          return (
            <Link key={index} onClick={() => this.handleModalShowHide(classDetail)}>
              <div className="classes-box">
                <img src={classDetail.imagePath} />
                <h5>{classDetail.name}</h5>
                <p>{classDetail.date}</p>
                <p>
                  <span>{classDetail.time}</span>
                </p>
                {/* <span className="status live">Live</span> */}
              </div>
            </Link>
          );
        })}
      </ReactItemSlider>
    );
  };
  render() {
    return (
      <div>
        <div className="learn-itemislider">
          <div className="heading-parts">
            <h3>Upcoming Classes</h3>
            <Link to="/past-classes">All Classes</Link>
          </div>
          <div className="slider-parts">
            <div>{this.getListOfClasses()}</div>
            <Modal show={this.state.showHide} className="upcommingevent-modal ">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <EventModal data={this.state.classData} type="class" />
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default LearnItemSlider;
