import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import Bloggerback from './bloggerSection/bloggerback';
import BloggerListSection from './bloggerSection/bloggerListSection';
import MediaQuery from 'react-responsive';

class BloggerListPage extends Component {
  state = {
    user: '',
  };
  async componentDidMount() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }

  render() {
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <div className="row">
                <div className="col-2 wow slideInLeft"></div>
                <div className="col-8 wow bounceIn">
                  <Bloggerback />
                  <BloggerListSection user={this.state.user} />
                </div>
                <div className="col-2 wow slideInLeft"></div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                <div className="row">
                  <div className="col-2 wow slideInLeft"></div>
                  <div className="col-12 wow bounceIn" style={{ marginTop: '-100px' }}>
                    <Bloggerback />
                    <BloggerListSection user={this.state.user} />
                  </div>
                  <div className="col-2 wow slideInLeft"></div>
                </div>
              </div>
            </div>
            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default BloggerListPage;
