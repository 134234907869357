import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import httpAdminService from '../../../services/httpAdminService';
import * as postAdminService from '../../../services/postAdminService';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { RplFetcher } from '../../../lib/helpers';

class EditLearnPost extends Component {
  fileArray = [];
  sendFile = [];
  state = {
    selectedTags: this.props.data.interest,
    description: this.props.data.description,
    interestList: [],
    old_arr: [],
    file: this.props.data.images.concat(this.props.data.videos),
    link: this.props.data.link,
    web_link: this.props.data.web_link,
  };

  componentDidMount = async () => {
    try {
      this.fileArray = this.props.data.images.concat(this.props.data.videos);
      this.sendFile = this.props.data.images.concat(this.props.data.videos);
      if (this.props.data.link) this.toggleLinkpart();
      if (this.props.data.web_link) this.toggleWebsitepart();
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/getInterest`);
      this.setState({ interestList: data.interests });
      this.setState({ old_arr: data.interests });
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  //   constructor(props) {
  //     super(props);
  //   }
  toggleLinkpart = () => {
    this.setState({ linkpart: !this.state.linkpart });
  };
  toggleWebsitepart = () => {
    this.setState({ websitepart: !this.state.websitepart });
  };
  uploadMultipleFiles = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 20242880) {
        toast.error('File size exceeded. Max size should be 20MB.');
        continue;
      }
      this.sendFile.push(e.target.files[i]);
      let obj = {
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        fileurl: URL.createObjectURL(e.target.files[i]),
      };
      this.fileArray.push(obj);
    }

    this.setState({ file: this.sendFile });
  };
  getExtension(name) {
    if (name) {
      return name.split('.')[name.split('.').length - 1].toLowerCase();
    }
  }
  onRemoveFileSelection(index) {
    this.fileArray.splice(index, 1);
    this.sendFile.splice(index, 1);
    this.setState({ file: this.sendFile });
  }

  handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  };

  handleKeyPress(e) {
    let originalArr = JSON.parse(JSON.stringify(this.state.old_arr));
    if (e.target.value) {
      let interestList = this.state.old_arr.filter((item, index) => {
        if (item['name'].toLowerCase().includes(e.target.value.toLowerCase())) {
          return item;
        }
      });
      this.setState({ interestList: interestList });
    } else {
      this.setState({ interestList: [...originalArr] });
    }
  }

  tags(item) {
    const selectedInterest = this.state.selectedTags.map((a) => a._id);
    if (selectedInterest.indexOf(item._id) == -1) {
      this.state.selectedTags.push(item);
    }
  }

  removeTags(item) {
    var array = [...this.state.selectedTags]; // make a separate copy of the array
    var index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedTags: array });
    }
  }

  isUrl(s) {
    var shortLink = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;
    var fullLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return fullLink.test(s) || shortLink.test(s);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.link) {
      let checkUrl = this.isUrl(this.state.link);
      if (!checkUrl) {
        toast.error('Link is invalid');
        return;
      }
    }
    if (this.state.web_link) {
      let checkUrlWebsite = this.isUrl(this.state.web_link);
      if (!checkUrlWebsite) {
        toast.error('Website Link is invalid');
        return;
      }
    }
    const files = this.state.file;
    if (this.state.description == '' && this.state.link == '' && files.length == 0) {
      toast.error('Please enter description');
      return;
    }
    if (files.length == 0 && this.state.description == '') {
      toast.error('Please enter description or add image');
      return;
    }
    //this.loader("block");
    const interests = this.state.selectedTags.map((a) => a._id);
    const formData = new FormData();
    formData.append('id', this.props.data._id);
    formData.append('type', this.props.data.type);
    formData.append('description', this.state.description);
    formData.append('link', this.state.link);
    formData.append('web_link', this.state.web_link);
    if (this.props.data.model === 'bookClubPost') formData.append('model', 'bookClubPost');
    for (let i = 0; i < interests.length; i++) {
      formData.append('interest', interests[i]);
    }
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    const { error } = await postAdminService.editLearnWithBthrivePost(formData);
    if (error) {
      //this.loader("none");
      return;
    } else {
      this.props.onSubmit();
      this.setState({ showHide: !this.state.showHide });
      this.setState({ linkpart: false });
      this.setState({ websitepart: false });
      this.setState({ selectedTags: [] });
      this.setState({ link: '' });
      this.setState({ web_link: '' });
      this.fileArray = [];
      this.sendFile = [];
      //this.loader("none");

      return;
    }
  };
  onEditorStateChange = (e) => {
    this.setState({ description: e.target.value });
  };

  render() {
    if (!this.props.data) return null;
    return (
      <>
        <div className="heading-part">
          <h3>{this.props.data.type == 'post' ? 'Edit your Post' : 'Edit your question'}</h3>
        </div>
        <div className="write-messge-post">
          <div className="textarea-part">
            {this.props.data.type == 'post' ? (
              <textarea
                rows="3"
                id="description"
                name="description"
                className="demo-editor"
                onChange={this.onEditorStateChange}
                placeholder="Write your Post"
                value={this.state.description}
              ></textarea>
            ) : (
              <textarea
                rows="3"
                id="description"
                name="description"
                className="demo-editor"
                onChange={this.onEditorStateChange}
                placeholder="Ask your Question"
                value={this.state.description}
              ></textarea>
            )}
          </div>
          <div className="form-group preview">
            <div className="row" style={{ maxHeight: '200px', width: '100%' }}>
              {this.fileArray.map((item, index) => {
                if (!item.name)
                  item = {
                    name: item,
                    fileurl: item,
                  };

                return (
                  <div className="col-md-3">
                    <i className="fa fa-times close-icon" onClick={() => this.onRemoveFileSelection(index)}></i>
                    {this.getExtension(item.name) == 'png' ||
                    this.getExtension(item.name) == 'jpg' ||
                    this.getExtension(item.name) == 'jpeg' ? (
                      <img src={item.fileurl} alt="..." height="120" width="120" />
                    ) : (
                      <video height="120" width="120" controls>
                        <source src={item.fileurl} type="video/mp4" />
                      </video>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="tags-part">
            <ul>
              {this.state.selectedTags.map((item, index) => {
                return (
                  <li key={index}>
                    {item.name}
                    <a href="#" onClick={() => this.removeTags(item)}>
                      <i className="fa fa-times-circle-o"></i>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          {this.state.linkpart && this.state.link ? (
            <div>
              <LinkPreview
                fetcher={RplFetcher}
                url={this.state.link}
                width="580px"
                height="250px"
                imageHeight="320px"
                descriptionLength="80"
                className="link-preview ml-5"
              />
            </div>
          ) : null}
          <div className={this.state.linkpart ? 'link-part active' : 'link-part'}>
            <div className="form-group">
              <input
                type="text"
                name="link"
                onChange={this.handleInputChange}
                className="form-control"
                placeholder="Enter Your Link"
                value={this.state.link}
              />
            </div>
          </div>
          {this.state.websitepart && this.state.web_link ? (
            <div>
              <LinkPreview
                fetcher={RplFetcher}
                url={this.state.web_link}
                width="580px"
                height="250px"
                imageHeight="320px"
                descriptionLength="80"
                className="link-preview ml-5 mt-3"
              />
            </div>
          ) : null}
          <div className={this.state.websitepart ? 'link-part active' : 'link-part'}>
            <div className="form-group">
              <input
                type="text"
                name="web_link"
                onChange={this.handleInputChange}
                className="form-control"
                placeholder="Enter Your Website Link"
                value={this.state.web_link}
              />
            </div>
          </div>
          <div className="post-footer-part">
            <div className="left-part">
              <ul>
                <li style={{ cursor: 'pointer!important' }}>
                  <div className="upload-image ">
                    <label>
                      <i className="fa fa-picture-o"></i>
                      <input
                        type="file"
                        className="form-control"
                        accept=".jpeg,.jpg,.png"
                        onChange={this.uploadMultipleFiles}
                        multiple
                      />{' '}
                    </label>
                  </div>
                </li>
                <li>
                  <div className="upload-image">
                    <label>
                      <i className="fa fa-play-circle"></i>
                      <input
                        type="file"
                        className="form-control"
                        accept=".mp4"
                        onChange={this.uploadMultipleFiles}
                      />{' '}
                    </label>
                  </div>
                </li>
                <li>
                  <Link onClick={this.toggleLinkpart}>
                    <i className="fa fa-link"></i>
                  </Link>
                </li>
                <li>
                  <Link onClick={this.toggleWebsitepart}>
                    <i className="fa fa-globe"></i>
                  </Link>
                </li>
              </ul>
              <span className="imagesizedefine">Max size of video should be 20MB</span>
            </div>
            <div className="right-part">
              <div className="button-part">
                <button className="btn btn-primary" onClick={this.handleSubmit} type="button">
                  Save
                </button>
              </div>
              <div className="search-part">
                <input
                  className="form-control mr-sm-2"
                  onChange={(e) => this.handleKeyPress(e)}
                  type="search "
                  placeholder="Add Tags"
                  aria-label="Search"
                />
                <div className="tags-search">
                  <ul>
                    {this.state.interestList.map((user, index) => (
                      <li key={index} onClick={() => this.tags(user)}>
                        <Link
                          to={{
                            pathName: '#',
                            state: { user: this.props.user },
                          }}
                        >
                          {user.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditLearnPost;
