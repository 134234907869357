import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';

import HomePage from './homePageComponents/homePage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OtpVerification from './loginComponents/otpVerification';
import LoginPage from './loginComponents/loginPage';
import SignupPage from './loginComponents/signupPage';
import ResetPasswordPage from './loginComponents/resetPasswordPage';
import ForgotPasswordPage from './loginComponents/forgotPasswordPage';
import GetStartPage from './loginComponents/getStartPage';
import SignOut from './loginComponents/signOut';
import AdminSignOut from './adminComponent/commonComponents/adminSignOut';
import ChooseInterestPage from './loginComponents/chooseInterestPage';
import AboutusPage from './aboutusPageComponents/aboutusPage';
import UserhomePage from './userhomePageComponents/userhomePage';
import PostDetailPage from './userhomePageComponents/postDetailPage';
import UserProfilePage from './userProfileComponents/userProfilePage';
import ProtectedRoute from './loginComponents/protectedRoute';
import ProtectedAdminRoute from './loginComponents/protectedAdminRoute';
import ConditionalRoute from './loginComponents/conditionalRoute';
import UserApp from './userApp';
import EditProfilePage from './userProfileComponents/editProfilePage';
import AdminloginPage from './adminComponent/loginComponent/adminloginPage';
import AdminforgotPasswordPage from './adminComponent/loginComponent/adminforgotPasswordPage';
import Dashboard from './adminComponent/dashboardComponent/dashboard';
import Userlist from './adminComponent/userComponent/userlist';
import Bloggerrlist from './adminComponent/bloggerComponent/bloggerlist';
import Tutorial1 from './tutorial/tutorial1';
import Tutorial2 from './tutorial/tutorial2';
import Tutorial3 from './tutorial/tutorial3';
import Tutorial4 from './tutorial/tutorial4';
import Tutorial5 from './tutorial/tutorial5';
import Tutorial6 from './tutorial/tutorial6';
import pastEvents from './eventsComponents/pastEvents';
import SpacesPage from './spacesComponent/spacesPage';
import CollectionListPage from './collectionsComponent/collectionListPage';
import collectionDetailPage from './collectionsComponent/collectionDetailPage';
import LearnPage from './learnComponent/learnPage';
import BookclubPage from './bookclubComponent/bookclubPage';
import EventsList from './adminComponent/eventsComponents/eventsList';
import BloggerProfile from './blogersComponent/bloggerProfileComponents/bloggerProfile';
import InterestList from './adminComponent/interestComponents/interestList';
import Contactlist from './adminComponent/contactComponent/contactlist';
import UserProfileDetailPage from './adminComponent/userProfileComponents/userProfileDetailPage';
import Useractivitylist from './adminComponent/userProfileComponents/useractivitylist';
import Userusage from './adminComponent/userProfileComponents/userusage';
import SearchResult from './searchComponent/searchResult';
import LearnBThriveDetail from './adminComponent/eventsComponents/learnBThriveDetail';
import LearnDetail from './learnComponent/learnDetail';
import BookClublist from './adminComponent/bookClubComponent/bookClublist';
import BookClubDetail from './adminComponent/bookClubComponent/bookClubDetail';
import reportlist from './adminComponent/reportsComponent/reportlist';
import EditPasswordSection from './userProfileComponents/editPasswordSection';
import AdminEditPassword from './adminComponent/adminEditPassword';
import ClassSection from './learnComponent/learnSection/classSection';
import BloggerListPage from './blogersComponent/bloggerListPage';
import AdminPostDetailPage from './adminComponent/commonComponents/adminPostDetailPage';
import AdminCollectionDetailPage from './adminComponent/commonComponents/adminCollectionDetailPage';
import ScrollTop from './scrollTop/scrollTop';
import Guideline from './Guideline/guideline';
import FaqSection from './Guideline/faqSection';
import PrivacyPolicy from './Guideline/privacy';
import AdminOtpVerification from './adminComponent/loginComponent/adminOtpVerificationPage';
import AdminResetPasswordPage from './adminComponent/loginComponent/adminresetPasswordPage';
import TermsAndCondition from './Guideline/termsAndCondition';
class App extends Component {
  state = {
    userData: {
      email: '',
    },
  };
  getEmail = async (email) => {
    const userData = { email: email };
    this.setState({ userData });
  };
  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <ScrollTop />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/signOut" component={SignOut} />
          <Route
            exact
            path="/login"
            a1={LoginPage}
            render={(props) => <LoginPage submitEmail={(email) => this.getEmail(email)} {...props} />}
          />
          <Route
            exact
            path="/registration"
            a1={SignupPage}
            render={(props) => <SignupPage submitEmail={(email) => this.getEmail(email)} {...props} />}
          />
          <Route
            exact
            path={`/verify`}
            render={(props) => <OtpVerification email={this.state.userData.email} forgotPassword={false} {...props} />}
          />
          <Route
            exact
            path="/forgot-password"
            render={(props) => <ForgotPasswordPage submitEmail={(email) => this.getEmail(email)} {...props} />}
          />
          <Route
            exact
            path={`/forgotPassword/otpVerification`}
            render={(props) => <OtpVerification email={this.state.userData.email} forgotPassword={true} {...props} />}
          />
          <Route exact path="/forgotPassword/resetPassword" render={(props) => <ResetPasswordPage {...props} />} />
          <Route exact path="/get-start" component={GetStartPage} />
          <Route exact path="/choose-interest" render={(props) => <ChooseInterestPage {...props} />} />
          <Route exact path="/about-us" component={AboutusPage} />
          <ProtectedRoute exact path="/post-detail/:type/:id" component={PostDetailPage} />
          <ProtectedAdminRoute exact path="/admin/post-detail/:type/:id" component={AdminPostDetailPage} />
          <ProtectedRoute exact path="/user-profile" component={UserProfilePage} />
          <Route path="/user" component={UserApp} />
          <ProtectedRoute exact path="/user-home" component={UserhomePage} />
          <ProtectedRoute exact path="/edit-profile" component={EditProfilePage} />
          <ProtectedRoute exact path="/edit-password" component={EditPasswordSection} />
          <ProtectedRoute exact path="/tutorial1" component={Tutorial1} />
          <ProtectedRoute exact path="/tutorial2" component={Tutorial2} />
          <ProtectedRoute exact path="/tutorial3" component={Tutorial3} />
          <ProtectedRoute exact path="/tutorial4" component={Tutorial4} />
          <ProtectedRoute exact path="/tutorial5" component={Tutorial5} />
          <ProtectedRoute exact path="/tutorial6" component={Tutorial6} />
          <ProtectedRoute exact path="/past-events" component={pastEvents} />
          <ProtectedRoute exact path="/past-classes" component={ClassSection} />
          <ProtectedRoute exact path="/spaces" component={SpacesPage} />
          <ProtectedRoute exact path="/collections" component={CollectionListPage} />
          <ProtectedRoute exact path="/collection-detail/:id" component={collectionDetailPage} />
          <ProtectedAdminRoute exact path="/admin/collection-detail/:id" component={AdminCollectionDetailPage} />
          <ProtectedRoute exact path="/learn-with-bthrive" component={LearnPage} />
          <ProtectedRoute exact path="/learn-with-bthrive-detail/:id" component={LearnDetail} />

          <ProtectedRoute exact path="/book-club" component={BookclubPage} />
          <ProtectedRoute exact path="/blogger-list" component={BloggerListPage} />
          <ProtectedRoute exact path="/blogger-profile/:id" component={BloggerProfile} />
          <ProtectedRoute exact path="/search-result/:searchKeyWord/:type" component={SearchResult} />
          <Route exact path="/admin/login" component={AdminloginPage} />
          <Route exact path="/admin/forgot-password" component={AdminforgotPasswordPage} />
          <Route exact path="/admin/otp-verification" component={AdminOtpVerification} />
          <Route exact path="/admin/reset-password" component={AdminResetPasswordPage} />
          <ProtectedAdminRoute exact path="/admin/changePassword" component={AdminEditPassword} />
          <ProtectedAdminRoute exact path="/admin/dashboard" component={Dashboard} />
          <ProtectedAdminRoute exact path="/admin/user-list" component={Userlist} />
          <ProtectedAdminRoute exact path="/admin/blogger-list" component={Bloggerrlist} />
          <Route exact path="/admin/signOut" component={AdminSignOut} />
          <ProtectedAdminRoute exact path="/admin/event-list" component={EventsList} />
          <ProtectedAdminRoute exact path="/admin/interest-list" component={InterestList} />

          <ProtectedAdminRoute exact path="/admin/all-reports-list" component={reportlist} />
          <ProtectedAdminRoute exact path="/admin/contact-list" component={Contactlist} />
          <ProtectedAdminRoute exact path="/admin/user-profile-detail/:id" component={UserProfileDetailPage} />
          <ProtectedAdminRoute exact path="/admin/user-activity-list/:id" component={Useractivitylist} />
          <ProtectedAdminRoute exact path="/admin/user-usage/:id" component={Userusage} />
          <ProtectedAdminRoute exact path="/admin/learn-b-thrive-detail/:id" component={LearnBThriveDetail} />
          <ProtectedAdminRoute exact path="/admin/book-club" component={BookClublist} />
          <ProtectedAdminRoute exact path="/admin/book-club-detail/:id" component={BookClubDetail} />
          <Route exact path="/guideline" component={Guideline} />
          <Route exact path="/faq" component={FaqSection} />
          <Route exact path="/privacy-policy" component={PrivacyPolicy} />
          <Route exact path="/terms-and-condition" component={TermsAndCondition} />
        </Switch>
      </React.Fragment>
    );
  }
}
export default App;
