import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
class Sidebar extends Component {
  state = {
    segment: 'posts',
  };
  async componentDidMount() {
    const url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    this.setState({
      segment: segment,
    });
  }
  render() {
    return (
      <>
        <div className="row flex-column flex-md-row">
          <div className="col-12 col-md-2 p-0  flex-shrink-1">
            <nav className="navbar navbar-expand navbar flex-md-column flex-row align-items-start ">
              <div className="collapse navbar-collapse ">
                <div className="user-home-sidebar">
                  <div className="main-part">
                    <ul className="flex-md-column flex-row navbar-nav w-100 justify-content-between">
                      <li className={this.state.segment == 'user-home' ? 'active' : ''}>
                        <Link to="/user-home">
                          <img src="/../assets/images/icons/post.png" alt="Post" /> <span>Posts</span>
                        </Link>
                      </li>
                      <li className={this.state.segment == 'spaces' ? 'active' : ''}>
                        <Link to="/spaces">
                          <img src="/../assets/images/icons/space.png" alt="Space" /> <span>Spaces</span>
                        </Link>
                      </li>
                      <li className={this.state.segment == 'collections' ? 'active' : ''}>
                        <Link to="/collections">
                          <img src="/../assets/images/icons/collection.png" alt="Collection" /> <span>Collections</span>
                        </Link>
                      </li>
                      <li className={this.state.segment == 'learn-with-bthrive' ? 'active' : ''}>
                        <Link to="/learn-with-bthrive">
                          <img src="/../assets/images/icons/learn.png" alt="Learn with B-Thrive" />{' '}
                          <span>Learn with B-Thrive</span>
                        </Link>
                      </li>
                      <li className={this.state.segment == 'book-club' ? 'active' : ''}>
                        <Link to="/book-club">
                          <img src="/../assets/images/icons/book.png" alt="Book Club" /> 
                          <span>Book Club</span><div style={{color:'black',fontSize:12}}>Coming soon</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </>
    );
  }
}

export default Sidebar;
