import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import httpService from '../services/httpService';

class EventModal extends Component {
  async attendEvent(id, type) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/attendEvent/${id}/${type}`);
      if (data) {
        toast.success(data.message.replace(data.message[0], data.message[0].charAt(0).toUpperCase()));

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  render() {
    if (!this.props.data) return null;
    return (
      <div className="collection">
        <h4 className="text-center">Upcoming {this.props.data.type}</h4>
        <p className="text-center">Click the button to attend this {this.props.data.type} </p>
        <div className="upcommong-display-section ">
          <div className="main-section">
            <div className="post-block mb-4 wow fadeInUp">
              <div className="row">
                <div className="col-lg-6">
                  <div className="collection-image-part">
                    <img src={this.props.data.imagePath} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="collection-content">
                    <div className="collection-toppart">
                      <div className="left-part">
                        <Link className="nav-link">
                          <p>{this.props.data.name}</p>
                        </Link>
                      </div>
                    </div>
                    <div className="upcommong-points">
                      <ul>
                        <li>
                          <i className="fa fa-calendar"></i>
                          {this.props.data.date}
                        </li>
                        <li>
                          <img src="../../assets/images/icons/clock.png" className="mr-1" style={{ width: '18px' }} />
                          {this.props.data.time}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="submit-buttons text-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              this.attendEvent(this.props.data._id, this.props.type);
            }}
          >
            Attend This {this.props.data.type}
          </button>
          <a href="https://calendar.google.com/calendar/u/0/r?pli=1" target="_blank">
            <button className="btn btn-primary">Add {this.props.data.type} Manually to Calendar</button>
          </a>
        </div>
      </div>
    );
  }
}

export default EventModal;
