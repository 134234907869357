import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import httpAdminService from '../../../services/httpAdminService';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import EventModal from './../../../commonComponents/eventmodal';
class EventsSection extends Component {
  state = {
    deleteId: '',
    eventdata: '',
  };
  deleteModalShowHide = (id) => {
    this.setState({ deleteId: id });
    this.setState({ deleteShowHide: !this.state.deleteShowHide });
  };

  async handleDelete() {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/deleteEvents/${this.state.deleteId}`,
      );
      if (data) {
        this.setState({ deleteShowHide: !this.state.deleteShowHide });
        toast.success(data.message);
        this.props.delete();

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  handleModalShowHide(data) {
    this.setState({ showHide: !this.state.showHide });
    this.setState({ eventdata: data });
  }

  render() {
    if (!this.props.data) return null;
    return (
      <div className="events-display-section">
        <div className="post-display-section">
          <div className="main-section">
            {this.props.data.map((item) => {
              return (
                <>
                  <div className="post-block mb-4 wow fadeInUp">
                    <div className="row">
                      <div className="col-md-3">
                        <Link
                          className="nav-link"
                          onClick={
                            !window.location.href.includes('admin') ? () => this.handleModalShowHide(item) : null
                          }
                        >
                          <div className="collection-image-part">
                            <img src={item.imagePath} alt="Events" />
                          </div>
                        </Link>
                      </div>
                      <div className="col-md-9">
                        <div className="collection-content">
                          <div className="collection-toppart">
                            <div className="left-part">
                              <Link
                                className="nav-link"
                                onClick={
                                  !window.location.href.includes('admin') ? () => this.handleModalShowHide(item) : null
                                }
                              >
                                <p>{item.name}</p>
                              </Link>
                            </div>
                            {window.location.href.includes('admin') && (
                              <div className="right-part-1">
                                <Link className="dotsbar ">
                                  <i className="fa fa-ellipsis-v"></i>
                                  <div className="post-dot-option">
                                    <ul>
                                      <li>
                                        <Link onClick={() => this.deleteModalShowHide(item._id)}>
                                          <i className="fa fa-trash ml-4 mt-4"></i>
                                          Delete Event
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </Link>
                                <Link className="smile-icon d-flex" style={{ background: '#ececec' }}>
                                  <i
                                    className="fa fa-database "
                                    style={{
                                      marginTop: '6px',
                                      marginRight: '1.4px',
                                    }}
                                  ></i>{' '}
                                  {item.type}
                                </Link>
                              </div>
                            )}
                          </div>
                          <div className="events-points">
                            <ul>
                              <li>
                                <i className="fa fa-calendar"></i> {item.date}
                              </li>
                              {window.location.href.includes('admin') && (
                                <li className="ml-1"> RSVPs - {item.attendees ? item.attendees.length : 0}</li>
                              )}
                              <li>
                                <i className="fa fa-clock-o"></i> {item.time}
                              </li>
                              <li className="ml-1">
                                {' '}
                                Host{' '}
                                <img
                                  src={
                                    item.host_email
                                      ? item.host_email.profilePicture
                                      : '../../../assets/images/user/01.jpg'
                                  }
                                  alt="Events"
                                />{' '}
                                {item.host_email ? item.host_email.name : item.host_email}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
            <Modal show={this.state.showHide} className="upcommingevent-modal ">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <EventModal data={this.state.eventdata} type={this.state.eventdata?.type} />
              </Modal.Body>
            </Modal>
            <Modal show={this.state.deleteShowHide} className="addcollection-modal">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.deleteModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <div className="dashboard-contactus-section">
                  <div className="top-section text-center">
                    <h3>Delete Event</h3>
                    <p>Are you sure, You Want to delete this Event?</p>
                  </div>
                  <div className="d-flex new-btn">
                    <div className="submit-buttons  new-collection  " style={{ marginRight: '15px' }}>
                      <Link type="button" className="" style={{ color: 'black' }} onClick={() => this.handleDelete()}>
                        Yes
                      </Link>
                    </div>
                    <div className="submit-buttons  new-collection " style={{ marginLeft: '15px' }}>
                      <Link type="button" style={{ color: 'black' }} onClick={() => this.deleteModalShowHide()}>
                        No
                      </Link>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default EventsSection;
