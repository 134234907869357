import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
class Tutorial1 extends Component {
  state = {
    user: null,
  };

  componentDidMount = () => {
    window.addEventListener('keydown', this.redirect);
  };

  redirect = (e) => {
    if (e.key === 'ArrowRight') this.props.history.push('/tutorial2');
    if (e.key === 'Escape') this.props.history.push('/user-home');
  };

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.redirect);
  };

  render() {
    return (
      <div className="tutorial-part">
        <div className="tutorial1">
          <Link to="user-home" className="link1">
            Skip
          </Link>
          <Link to="tutorial2" className="link2">
            Next
          </Link>
        </div>
      </div>
    );
  }
}

export default Tutorial1;
