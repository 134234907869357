import { Component } from 'react';
import { toast } from 'react-toastify';
import httpService from '../services/httpService';

class SignOut extends Component {
  state = {};
  componentDidMount = async () => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/logout`);
      localStorage.removeItem('token');
    } catch (error) {
      toast.error(error.message);
    }
    return this.props.history.push('/login');
  };
  render() {
    return null;
  }
}

export default SignOut;
