import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
class Userlisttable extends Component {
  state = {
    postPerPage: 10,
    currentPage: Number(1),
    pageItem: {},
    arrOfCurrButtons: [],
    numOfButtons: [],
  };

  onPageChangeEvent = (start, end) => {
    console.log(start, end);
    this.setState({
      pageItem: {
        start: start,
        end: end,
      },
    });
  };

  setPageNo = async () => {
    const numOfPages = Math.ceil(this.props.user.length / this.state.postPerPage);
    let numOfButtons = [];
    for (let i = 1; i <= numOfPages; i++) {
      numOfButtons.push(i);
    }
    await this.setState({ numOfButtons: numOfButtons });
  };

  prevPageClick = () => {
    if (this.state.currentPage === 1) {
      this.setState({ currentPage: this.state.currentPage });
    } else {
      this.setState({ currentPage: this.state.currentPage - 1 });
    }
    this.componentDidMount();
  };

  nextPageClick = () => {
    if (this.state.currentPage === this.state.numOfButtons.length) {
      this.setState({ currentPage: this.state.currentPage });
    } else {
      this.setState({ currentPage: this.state.currentPage + 1 });
    }
    this.componentDidMount();
  };

  changeNumber = (data) => {
    this.setState({ currentPage: data });
    this.componentDidMount();
  };

  componentDidMount = async () => {
    await this.setPageNo();
    this.setState({
      pageItem: {
        start: 0,
        end: this.state.postPerPage,
      },
    });

    let tempNumberOfButtons = [...this.state.arrOfCurrButtons];

    let dotsInitial = '...';
    let dotsLeft = '... ';
    let dotsRight = ' ...';

    if (this.state.numOfButtons.length < 6) {
      tempNumberOfButtons = this.state.numOfButtons;
    } else if (this.state.currentPage >= 1 && this.state.currentPage <= 3) {
      tempNumberOfButtons = [1, 2, 3, 4, dotsInitial, this.state.numOfButtons.length];
    } else if (this.state.currentPage === 4) {
      const sliced = this.state.numOfButtons.slice(0, 5);
      tempNumberOfButtons = [...sliced, dotsInitial, this.state.numOfButtons.length];
    } else if (this.state.currentPage > 4 && this.state.currentPage < this.state.numOfButtons.length - 2) {
      // from 5 to 8 -> (10 - 2)
      const sliced1 = this.state.numOfButtons.slice(this.state.currentPage - 2, this.state.currentPage);
      // sliced1 (5-2, 5) -> [4,5]
      const sliced2 = this.state.numOfButtons.slice(this.state.currentPage, this.state.currentPage + 1);
      // sliced1 (5, 5+1) -> [6]
      tempNumberOfButtons = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, this.state.numOfButtons.length];
      // [1, '...', 4, 5, 6, '...', 10]
    } else if (this.state.currentPage > this.state.numOfButtons.length - 3) {
      // > 7
      const sliced = this.state.numOfButtons.slice(this.state.numOfButtons.length - 4);
      // slice(10-4)
      tempNumberOfButtons = [1, dotsLeft, ...sliced];
    } else if (this.state.currentPage === dotsInitial) {
      // [1, 2, 3, 4, "...", 10].length = 6 - 3  = 3
      // arrOfCurrButtons[3] = 4 + 1 = 5
      // or
      // [1, 2, 3, 4, 5, "...", 10].length = 7 - 3 = 4
      // [1, 2, 3, 4, 5, "...", 10][4] = 5 + 1 = 6
      this.setState({
        currentPage: this.state.arrOfCurrButtons[this.state.arrOfCurrButtons.length - 3] + 1,
      });
    } else if (this.state.currentPage === dotsRight) {
      this.setState({ currentPage: this.state.arrOfCurrButtons[3] + 2 });
    } else if (this.state.currentPage === dotsLeft) {
      this.setState({ currentPage: this.state.arrOfCurrButtons[3] - 2 });
    }
    console.log(tempNumberOfButtons);
    this.setState({ arrOfCurrButtons: tempNumberOfButtons });
    const value = this.state.currentPage * this.state.postPerPage;

    this.onPageChangeEvent(value - this.state.postPerPage, value);
    new WOW.WOW({
      live: false,
    }).init();
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    // alert(e.target.value)
    this.props.onSortChange(e.target.value);
  }

  hitBlockAPI(id) {
    this.props.onBlockChange(id);
  }

  render() {
    if (!this.props.user) return null;
    return (
      <div>
        <div className="shorting-dashboard mt-3 ">
          <div className="main-section">
            <div className="left-part">
              <p>You have a total of {this.props.user.length} users</p>
            </div>
            <div className="right-part">
              <div className="form-group">
                <label>Sort By :</label>
                <select className="fomr-control" onChange={this.handleChange}>
                  <option value="All_Time">All Time</option>
                  <option value="last_minute">Last Minute</option>
                  <option value="last_hour">Last Hours</option>
                  <option value="last_week">Last Week</option>
                  <option value="last_month">Last Month</option>
                  <option value="last_quarter">Last Quarter</option>
                  <option value="Last_Year">Last Year</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="user-list-page wow fadeInUp">
          <div className="main-section">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">User</th>
                  <th scope="col">Posts</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Collections</th>
                  <th scope="col">Status</th>
                  <th scope="col">Created At</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {this.props.user.slice(this.state.pageItem.start, this.state.pageItem.end).map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className="left-part">
                        <img
                          src={item.profilePicture ? item.profilePicture : '../../../assets/images/user/01.jpg'}
                          alt="User Profile"
                        />
                        <p>{item.name}</p> <span>{item.email}</span>
                      </div>
                    </td>
                    <td>{item.total_posts}</td>
                    <td>{item.total_comments}</td>
                    <td>{item.total_collections}</td>
                    <td>{item.status}</td>
                    <td>{item.accountCreationDate}</td>
                    <td>
                      {' '}
                      <div className="right-part">
                        <Link className="dotsbar" to="#">
                          <i className="fa fa-ellipsis-v"></i>
                          <div className="post-dot-option">
                            <ul>
                              <li>
                                <Link to={`/admin/user-profile-detail/${item._id}`}>
                                  <i className="fa fa-eye"></i> View Detail{' '}
                                </Link>
                              </li>
                              <li>
                                {item.status == 'active' ? (
                                  <Link onClick={() => this.hitBlockAPI(item._id)}>
                                    <i className="fa fa-ban"></i> Block This User
                                  </Link>
                                ) : (
                                  <Link onClick={() => this.hitBlockAPI(item._id)}>
                                    <i className="fa fa-ban"></i> Unlock This User
                                  </Link>
                                )}
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="table-filter-info mt-2">
              <div className="dt-pagination">
                <ul className="dt-pagination-ul">
                  <li className={`dt-item ${this.state.currentPage === 1 ? 'disabled' : ''}`}>
                    <a className="dt-link" onClick={this.prevPageClick}>
                      Prev
                    </a>
                  </li>
                  {this.state.arrOfCurrButtons?.map((data, index) => {
                    return (
                      <li key={index} className={`dt-item ${this.state.currentPage === data ? 'active' : ''}`}>
                        <a className="dt-link" onClick={() => this.changeNumber(data)}>
                          {data}
                        </a>
                      </li>
                    );
                  })}
                  <li
                    className={`dt-item ${this.state.currentPage === this.state.numOfButtons.length ? 'disabled' : ''}`}
                  >
                    <a className="dt-link" onClick={this.nextPageClick}>
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Userlisttable;
