import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Modal } from 'react-bootstrap';
import httpService from '../../../services/httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import Form from '../../../macroComponents/form/form';
import Joi from 'joi-browser';
import * as postService from '../../../services/postServices';
import ShowMoreText from 'react-show-more-text';

class Blogs extends Form {
  state = {
    listData: '',
    formData: {
      link: '',
      description: '',
    },
    isEdit: false,
    errors: {},
  };
  id = '';

  schema = {
    link: Joi.string().min(3).required(),
    description: Joi.string().min(3).required(),
    //  selectedFile1: Joi.required(),
  };

  async componentDidMount() {
    this.blogApi();
  }

  isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }

  doSubmit = async () => {
    let checkUrl = this.isUrl(this.state.formData.link);
    if (!checkUrl) {
      toast.error('Blog Link is invalid');
      return;
    }
    if (this.id) {
      const { error } = await postService.editBlog(this.state.formData, this.id);
      if (error) return;
      if (this.state.showHide) {
        this.setState({
          isEdit: false,
          formData: {
            link: '',
            description: '',
          },
        });
        this.id = '';
      }
      this.setState({ showHide: !this.state.showHide });
      this.blogApi();
      return;
    } else {
      const { error } = await postService.addBlog(this.state.formData);
      if (error) return;
      if (this.state.showHide) {
        this.setState({
          isEdit: false,
          formData: {
            link: '',
            description: '',
          },
        });
        this.id = '';
      }
      this.setState({ showHide: !this.state.showHide });
      this.blogApi();
      return;
    }
  };

  async deleteApi(data) {
    const { error } = await postService.deleteBlog(data._id);
    if (error) return;
    this.blogApi();
    return;
  }

  async blogApi() {
    // this.loader('block');
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/blogList`);

      if (data) {
        data.blogList.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
        });
        const listData = data.blogList;
        this.setState({ listData: listData });

        // this.loader('none');

        return;
      }
    } catch (error) {
      // this.loader('none');
      toast.error(error.message);
      return;
    }
  }
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
    if (this.state.showHide) {
      this.setState({
        isEdit: false,
        formData: {
          link: '',
          description: '',
        },
      });
      this.id = '';
    }
  }
  hiteditApi(data) {
    this.setState({
      formData: {
        link: data.link,
        description: data.description,
      },
      isEdit: true,
    });
    this.id = data._id;
    this.handleModalShowHide();
  }
  hitdeleteApi(data) {
    const getConfirm = window.confirm('Are you sure you want to delete blog');
    if (getConfirm) {
      this.deleteApi(data);
    }
  }
  render() {
    return (
      <>
        <div className="learn-itemislider mb-0 mt-5">
          <div className="heading-parts">
            <h3>Blogs List</h3>
            <Link onClick={() => this.handleModalShowHide()}>Make a Post</Link>
          </div>
          <Modal show={this.state.showHide} className="messge-post-modal ">
            <Modal.Body>
              <div className="postsection blogspost">
                <a className="closeicon" onClick={() => this.handleModalShowHide()}>
                  <i className="fa fa-close"></i>
                </a>
                <div className="heading-part">
                  <h3>Make a Post</h3>
                </div>
                <div className="write-messge-post">
                  <div className="link-part active">
                    {this.renderInput('description', 'Blog Description', 'Enter Blog Description')}
                  </div>
                  <div className="link-part active">{this.renderInput('link', 'Blog Link', 'Enter Blog Link')}</div>
                  <div className="post-footer-part">
                    <div className="right-part">
                      {this.state.isEdit ? (
                        <div className="button-part">{this.renderButton('Update Blog', this.handleSubmit)}</div>
                      ) : (
                        <div className="button-part">{this.renderButton('Post Blog', this.handleSubmit)}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div className="user-post-section mt-3">
          {this.state.listData.length ? (
            this.state.listData.map((data, index) => {
              return (
                <div className="post-display-section ">
                  <div className="main-section">
                    <div className="post-block mb-3 wow fadeInUp">
                      <div className="post-toppart">
                        <div className="left-part">
                          <Link className="nav-link">
                            <img src={data.createdBy.profilePicture} alt="User Profile" />
                            <p>{data.createdBy.name}</p> <span>Posted : {data.creationDate}</span>
                          </Link>
                        </div>
                        <div className="right-part">
                          <Link className="dotsbar">
                            <i className="fa fa-ellipsis-v"></i>
                            <div className="post-dot-option">
                              <ul>
                                <li>
                                  <Link onClick={() => this.hiteditApi(data)}>
                                    <i className="fa fa-edit"></i> Edit Blog
                                  </Link>
                                </li>
                                <li>
                                  <Link onClick={() => this.hitdeleteApi(data)}>
                                    <i className="fa fa-trash"></i> Delete Blog
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="messge-box blogs-part">
                        <h5>
                          <ShowMoreText
                            /* Default options */
                            lines={3}
                            more="Read more"
                            less="Read less"
                            onClick={this.executeOnClick}
                            expanded={false}
                          >
                            {data.description}
                          </ShowMoreText>
                        </h5>
                        <a href={data.link} target="_blank">
                          {data.link}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <h1 className="no-data-found">no Post made yet</h1>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Blogs;
