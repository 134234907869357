import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
class Collectionback extends Component {
  render() {
    return (
      <div>
        <div className="back-post">
          <div className="main-section">
            <h3>
              <Link to={window.location.href.includes('admin') ? '/admin/dashboard' : '/collections'}>
                <i className="fa fa-angle-double-left"></i>
              </Link>{' '}
              Viewing Collection{' '}
            </h3>
          </div>
        </div>
      </div>
    );
  }
}

export default Collectionback;
