import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import { toast } from 'react-toastify';
import ReportShorting from './reportSection/reportShorting';
import Reportlisttable from './reportSection/reportlisttable';
import moment from 'moment';
import httpAdminService from './../../services/httpAdminService';
import { Modal } from 'react-bootstrap';
class Reportlist extends Component {
  state = {
    reports: '',
    deleteId: '',
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/reports`);
      if (data) {
        data.reports.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState({ reports: data.reports });
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }

  reportApi = async (sort = '') => {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/reports?sort=${sort}`);
      if (data) {
        data.reports.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState({ reports: data.reports });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  DeleteReportApi = async () => {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/deletePost/${this.state.deleteId}`,
      );
      if (data) {
        this.setState({ deleteShowHide: !this.state.deleteShowHide });
        this.componentDidMount();
        toast.success(data.message);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  deleteModalShowHide = (id) => {
    this.setState({ deleteId: id });
    this.setState({ deleteShowHide: !this.state.deleteShowHide });
  };

  render() {
    if (!this.state.reports) return null;
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.props.user} {...this.props} />
                <ReportShorting user={this.state.reports} {...this.props} onSortChange={this.reportApi} />
                <Reportlisttable user={this.state.reports} onDelete={this.deleteModalShowHide} {...this.props} />
              </div>
            </div>
          </div>
        </div>
        <ScrollUpButton />
        <Modal show={this.state.deleteShowHide} className="addcollection-modal">
          <Modal.Body>
            <Link className="closeicon" onClick={() => this.deleteModalShowHide()}>
              <i className="fa fa-close"></i>
            </Link>
            <div className="dashboard-contactus-section">
              <div className="top-section text-center">
                <h3>Delete Post</h3>
                <p>Are you sure, You Want to delete this Post?</p>
              </div>
              <div className="d-flex new-btn">
                <div className="submit-buttons  new-collection  " style={{ marginRight: '15px' }}>
                  <Link type="button" className="" style={{ color: 'black' }} onClick={() => this.DeleteReportApi()}>
                    Yes
                  </Link>
                </div>
                <div className="submit-buttons  new-collection " style={{ marginLeft: '15px' }}>
                  <Link type="button" style={{ color: 'black' }} onClick={() => this.deleteModalShowHide()}>
                    No
                  </Link>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Reportlist;
