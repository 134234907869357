import React from 'react';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
import ImageUploader from 'react-images-upload';
import * as registerService from './../services/registerServices';
import { toast } from 'react-toastify';

class GetStartPage extends Form {
  state = {
    formData: {
      name: '',
      location: '',
      origin: '',
      isBlogger: '',
      blogLink: '',
    },
    selectedFile1: null,
    errors: {},
    isFormSubmitted:false
  };
  schema = {
    name: Joi.string().min(3).max(30).required(),
    location: Joi.string().min(3).max(20).required(),
    origin: Joi.string().max(20).allow(''),
    isBlogger: Joi.string().required(),
    blogLink: Joi.allow(''),
  };
  doSubmit = async () => {
    this.setState({isFormSubmitted:true});
    if (+this.state.formData.isBlogger == 1) {
      if (this.state.formData.blogLink.length < 7) {
        toast.error('Please enter Blog Link with atleast 6 characters');
        return;
      }
      let checkUrl = this.isUrl(this.state.formData.blogLink);
      if (!checkUrl) {
        toast.error('Blog Link is invalid');
        return;
      }
    }
    if (+this.state.formData.isBlogger == -1) {
      toast.error('Please Specify you are a blogger or not');
      return;
    }
    if (!this.state.selectedFile1) {
      toast.error('Please Select Profile Picture');
      return;
    }
    const formData = new FormData();
    {
      let data = { ...this.state.formData };
      for (const item in data) {
        if (data[item] === '') {
          data[item] = 'N/A';
        }
        formData.append(item, data[item]);
      }
    }

    formData.append('profilePicture', this.state.selectedFile1, this.state.selectedFile1.name);

    const { error } = await registerService.setUpProfileService(formData);
    if (error) return;
    return this.props.history.push({
      pathname: `/choose-interest`,
      state: this.state.formData,
    });
  };
  onFileSelection = async (event) => {
    let file = event[0];
    if (file && file.size < 2880) {
      toast.error('Minimum File size should be 1MB.');
    } else if (file && file.size > 5242880) {
      toast.error('File size exceeded. Max size should be 5MB.');
    } else {
      // Update the state
      await this.setState({ selectedFile1: event[0] });
    }
  };
  isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(s);
  }
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <React.Fragment>
        <div className="loginpage">
          <div className="container-fluid login-header">
            <Header {...this.props} />
          </div>
          <div className="login-section ">
            <div className="container">
              <div className="process-bar">
                <ul>
                  <li className="active wow bounceIn">
                    <span>
                      <i className="fa fa-check"></i>
                    </span>
                    <p>Your Profile</p>
                  </li>
                  <li className=" wow bounceIn">
                    <span>2</span>
                    <p>Interests</p>
                  </li>
                  <li className=" wow bounceIn">
                    <span>3</span>
                    <p>Finish</p>
                  </li>
                </ul>
              </div>
              <div className="top-section text-center">
                <h3>Welcome To B-Thrive…</h3>
                <p>
                  Together, let’s inspire action towards empowerment and
                  <br /> forward movement within the global Black community.{' '}
                </p>
              </div>
              <div className="form-section get-startted wow bounceIn">
                <form>
                  <div className={`user-profile-image ${Object.keys(this.state.errors).length>0 && this.state.selectedFile1==null ? 'imageError' : ''}`}>
                 
                    <ImageUploader
                      withIcon={true}
                      withPreview={true}
                      buttonText={<img src="assets/images/icons/camera.png" alt="Camera" />}
                      onChange={this.onFileSelection}
                      imgExtension={['.jpg', '.jpeg', '.gif', '.png']}
                      maxFileSize={5242880}
                      singleImage={true}
                   
                    />
                    {
                     Object.keys(this.state.errors).length>0 && this.state.selectedFile1==null && 
                      <p  style={{fontSize:'13px',color:'#ff0018', textAlign:'center'}}>PLEASE SELECT PROFILE PICTURE</p>
                    }
                  </div>
                    
                  {this.renderInput('name', 'Whats Your Full Name?', 'Enter Full Name')}
                  {this.renderInput('location', 'Where are you based?', 'Enter Location')}
                  {this.renderInput('origin', 'Origin (Optional)', 'Enter Origin')}
                  {this.renderSelect('isBlogger', 'Are you a blogger?', [
                    { _id: '-1', name: 'Select' },
                    { _id: '0', name: 'No' },
                    { _id: '1', name: 'Yes' },
                  ])}
                  {+this.state.formData.isBlogger == 1
                    ? this.renderInput('blogLink', 'Whats the link to your Blog?', 'Enter Link')
                    : null}
                  {this.renderButton('Continue', this.handleSubmit)}
                </form>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default GetStartPage;
