import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import About from './tabspart/about';
import Post from './tabspart/post';
import Collection from './tabspart/collection';
import Blogs from './tabspart/blogs';
import httpAdminService from '../../../services/httpAdminService';
import { toast } from 'react-toastify';
class ProfileTabs extends Component {
  state = {
    adminUsersData: '',
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersProfileDetail/${this.props.match.params.id}`,
      );
      this.setState({ adminUsersData: data.adminUsersData[0] });
      return;
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  render() {
    return (
      <div className="tabs-profile-section mt-3">
        <div className="tabs-section">
          <Tabs>
            <TabList>
              <Tab>About</Tab>
              <Tab>Post </Tab>
              <Tab>Collections</Tab>
              {this.props.user && this.props.user.isBlogger == true ? <Tab>Blog Posts </Tab> : ''}
            </TabList>
            <TabPanel>
              <About user={this.state.adminUsersData} />
            </TabPanel>
            <TabPanel>
              <Post user={this.state.adminUsersData} />
            </TabPanel>
            <TabPanel>
              <Collection user={this.state.adminUsersData} />
            </TabPanel>
            <TabPanel>
              <Blogs user={this.state.adminUsersData} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ProfileTabs;
