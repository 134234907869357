import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { Tab, Tabs, TabList } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Modal } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import WOW from 'wowjs';
import httpService from './../../services/httpService';
import { toast } from 'react-toastify';
import ShowMoreText from 'react-show-more-text';
import httpAdminService from '../../services/httpAdminService';
import moment from 'moment';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import Prince1 from '../../assets/images/icons/Prince1.png';
import Heart1 from '../../assets/images/icons/Heart1.png';
import Linkify from 'react-linkify';
import { RplFetcher } from '../../lib/helpers';

class AdminPostDetail extends Component {
  state = {
    commentList: '',

    errors: {},
    allLikeData: [],
    allLikeCount: [],
    postId: '',
  };
  emojiModalShowHide(postId = '', type = '') {
    if (postId) {
      this.setState({ postId: postId });
      this.hitAllLikeDataApi(postId, (type = ''));
    }
    if (type) {
      if (type === 'all') type = '';
      this.hitAllLikeDataApi(this.state.postId, type);
    } else {
      this.setState({ emojishowHide: !this.state.emojishowHide });
    }
  }

  async hitAllLikeDataApi(postId, type) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/likeList?id=${postId}&type=${type}`);

      if (data) {
        const allLikeData = data.likeList;
        this.setState({ allLikeData: allLikeData });
        this.setState({ allLikeCount: data.likes });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  tabType = 'comment';
  selectedTab = 0;
  async componentDidMount() {
    var qs = require('qs');
    const type = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).type;
    if (type) {
      this.tabType = 'insight';
      this.selectedTab = 1;
      this.hitAPI('insight');
    } else this.commentList('comment');
    new WOW.WOW({
      live: false,
    }).init();
  }

  async commentList(name) {
    let model;
    if (window.location.href.includes('/bookClubPost')) model = 'bookClubPost';
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/comment/${this.props.match.params.id}/${name}?model=${model}`,
      );
      if (data) {
        data.commentList.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
        });
        const commentList = data.commentList;
        this.setState({ commentList: commentList });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  hitAPI(tabName) {
    this.tabType = tabName;
    if (tabName === 'comment') {
      this.selectedTab = 0;
      this.commentList('comment');
    } else {
      this.selectedTab = 1;
      this.commentList('insight');
    }
  }

  copyToClipboard(text) {
    var dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.success('Link Copied');
  }
  handleModalShowHide1() {
    this.setState({ showHide: !this.state.showHide });
  }
  nFormatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
  };

  render() {
    let model;
    if (window.location.href.includes('/bookClubPost')) model = 'bookClubPost';
    if (!this.props.postDetail[0]) return null;
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    return (
      <div>
        <div className="back-post">
          <div className="main-section">
            <h3>
              <Link onClick={() => this.props.history.goBack()}>
                <i className="fa fa-angle-double-left"></i>
              </Link>{' '}
              Viewing {this.props.postDetail[0].type === 'post' ? 'Post' : 'Question'}{' '}
            </h3>
          </div>
        </div>
        <div className="post-display-section ">
          <div className="main-section">
            <div className="post-block mb-3 wow fadeInUp">
              <div className="post-toppart">
                <div className="left-part">
                  <Link
                    className="nav-link"
                    to={`/admin/user-profile-detail/${this.props.postDetail[0].createdBy._id}`}
                  >
                    <img
                      className={this.props.postDetail[0].createdBy.isBlogger ? 'pic2' : ''}
                      src={
                        this.props.postDetail[0].createdBy.profilePicture
                          ? this.props.postDetail[0].createdBy.profilePicture
                          : '/../../assets/images/user/01.jpg'
                      }
                      alt="User Profile"
                    />

                    <Link to={`/admin/user-profile-detail/${this.props.postDetail[0].createdBy._id}`}>
                      <p className="" style={{ marginTop: '-20px' }}>
                        {this.props.postDetail[0].createdBy.name}
                        {this.props.postDetail[0].createdBy.isBlogger ? (
                          <span style={{ fontSize: '13px' }}>
                            {' '}
                            <i className="fa fa-circle ml-1 mr-2" style={{ fontSize: '8px', color: 'black' }}></i>
                            Blogger
                          </span>
                        ) : null}
                      </p>{' '}
                    </Link>
                    <span>Posted : {this.props.postDetail[0].creationDate}</span>
                  </Link>
                </div>
                <div className="right-part">
                  <Link className="dotsbar" to="#">
                    <i className="fa fa-ellipsis-v"></i>
                    <div className="post-dot-option">
                      <ul>
                        <li>
                          <Link onClick={() => this.copyToClipboard(`${window.location.href}`)}>
                            <i className="fa fa-link"></i> Copy Link
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Link>
                  {this.props.postDetail[0].web_link ? (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      className="source-globe"
                      href={
                        this.props.postDetail[0].web_link.includes('https://www.') ||
                        this.props.postDetail[0].web_link.includes('http')
                          ? this.props.postDetail[0].web_link
                          : `https://www.${this.props.postDetail[0].web_link.replace('www.', '')}`
                      }
                    >
                      <i className="fa fa-globe"></i> Source
                    </a>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="messge-box">
                <p>
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Read more"
                    less="Read less"
                    onClick={this.executeOnClick}
                    expanded={false}
                  >
                    {this.props.postDetail[0].description}
                  </ShowMoreText>
                </p>
                {this.props.postDetail[0].link ? (
                  <>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={
                        this.props.postDetail[0].link.includes('https://www.') ||
                        this.props.postDetail[0].link.includes('http')
                          ? this.props.postDetail[0].link
                          : `https://www.${this.props.postDetail[0].link.replace('www.', '')}`
                      }
                      width="680px"
                      height="350px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-1"
                    />
                    <a
                      href={
                        this.props.postDetail[0].link.includes('https://www.') ||
                        this.props.postDetail[0].link.includes('http')
                          ? this.props.postDetail[0].link
                          : `https://www.${this.props.postDetail[0].link.replace('www.', '')}`
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="postlinks"
                    >
                      <i className="fa fa-link"></i> {this.props.postDetail[0].link}
                    </a>
                  </>
                ) : (
                  ''
                )}
                {this.props.postDetail[0].web_link ? (
                  <>
                    <LinkPreview
                      fetcher={RplFetcher}
                      url={
                        this.props.postDetail[0].web_link.includes('https://www.') ||
                        this.props.postDetail[0].web_link.includes('http')
                          ? this.props.postDetail[0].web_link
                          : `https://www.${this.props.postDetail[0].web_link.replace('www.', '')}`
                      }
                      width="680px"
                      height="350px"
                      imageHeight="320px"
                      descriptionLength="80"
                      className="link-preview ml-1"
                    />
                    <a
                      href={
                        this.props.postDetail[0].web_link.includes('https://www.') ||
                        this.props.postDetail[0].web_link.includes('http')
                          ? this.props.postDetail[0].web_link
                          : `https://www.${this.props.postDetail[0].web_link.replace('www.', '')}`
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="postlinks"
                    >
                      <i className="fa fa-globe"></i> {this.props.postDetail[0].web_link}
                    </a>
                  </>
                ) : (
                  ''
                )}
              </div>
              {this.props.postDetail[0].images.length || this.props.postDetail[0].videos.length ? (
                <div className="image-post">
                  {this.props.postDetail[0].images.length ? (
                    <img src={this.props.postDetail[0].images[0]} alt="Event" />
                  ) : this.props.postDetail[0].videos.length ? (
                    <video controls height="400px" width="100%">
                      <source src={this.props.postDetail[0].videos[0]} type="video/mp4"></source>
                    </video>
                  ) : null}
                  <Link onClick={() => this.handleModalShowHide1()} className="view-all-image">
                    {' '}
                    {this.props.postDetail[0].images.length + this.props.postDetail[0].videos.length > 1 ? (
                      <p> View all </p>
                    ) : (
                      <p style={{ padding: '27px' }}> View </p>
                    )}
                  </Link>
                </div>
              ) : (
                ''
              )}

              <Modal show={this.state.showHide} className="allimage-modal ">
                <Modal.Body>
                  <div className="allimagepopup text-center">
                    <a className="closeicon" onClick={() => this.handleModalShowHide1()}>
                      <i className="fa fa-close"></i>
                    </a>
                    <div className="slider-part ">
                      <Carousel infiniteLoop useKeyboardArrows autoPlay>
                        {this.props.postDetail[0].images.map((name, index) => (
                          <div className="imageviews">
                            <img src={name} alt="Slider 1" />
                          </div>
                        ))}
                        {this.props.postDetail[0].videos.map((name, index) => (
                          <div>
                            <video controls>
                              <source src={name} type="video/mp4" />
                            </video>
                          </div>
                        ))}
                      </Carousel>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal show={this.state.emojishowHide} className="emoji-modal ">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="collection">
                    <h4 className="">Reactions </h4>
                    <div className="reaction-parts">
                      <Tabs>
                        <TabList id="style-1">
                          <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                            All {this.nFormatter(this.state.allLikeCount.total_likes)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_1)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_2)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Raised-fist1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_3)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Folded-hand1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_4)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                            <span>
                              <img className="emoji-list-icon2" src={Heart1} />
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_5)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_6)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src={Prince1} />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.allLikeCount.total_likes_7)}
                          </Tab>
                        </TabList>
                      </Tabs>
                      <div className="likeuser-list">
                        <ul>
                          {this.state.allLikeData.length
                            ? this.state.allLikeData.map((data, index) => {
                                return (
                                  <li>
                                    <Link to={`/admin/user-profile-detail/${data.createdBy._id}`}>
                                      <h5>
                                        {data.createdBy.name}
                                        {data.createdBy.isBlogger ? (
                                          <span style={{ fontSize: '13px' }}>
                                            {' '}
                                            <i
                                              className="fa fa-circle ml-1 mr-2"
                                              style={{
                                                fontSize: '8px',
                                                color: 'black',
                                              }}
                                            ></i>
                                            Blogger
                                          </span>
                                        ) : null}
                                      </h5>
                                      {data.createdBy.location ? (
                                        <span>{data.createdBy.location}</span>
                                      ) : (
                                        <span style={{ color: 'white' }}>Noida</span>
                                      )}
                                      <img
                                        className={data.createdBy.isBlogger ? 'pic2' : ''}
                                        src={
                                          data.createdBy.profilePicture
                                            ? data.createdBy.profilePicture
                                            : '../../assets/images/user/01.jpg'
                                        }
                                        alt="User Profile"
                                      />
                                    </Link>
                                  </li>
                                );
                              })
                            : ''}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <div className="bottom-part">
                <div className="left-part">
                  <ul>
                    {this.props.postDetail[0].interest.map((tagName, index) => (
                      <li>{tagName.name}</li>
                    ))}
                  </ul>
                </div>
                <div className="right-part">
                  <div className="liked-user  mt-5 mb-5">
                    <Link onClick={() => this.emojiModalShowHide(this.props.postDetail[0]._id)}>
                      <span>
                        {' '}
                        <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="showing-comment">
                <div className="start-messege">
                  <p>Showing {this.tabType}</p>
                </div>
                <div className="comments-section">
                  <div className="tabs-section">
                    {this.props.postDetail[0].type === 'post' && !model ? (
                      <Tabs selectedIndex={this.selectedTab}>
                        <TabList>
                          <Tab onClick={() => this.hitAPI('comment')}>Comments</Tab>
                          <Tab onClick={() => this.hitAPI('insight')}>Insights </Tab>
                        </TabList>
                      </Tabs>
                    ) : (
                      ''
                    )}

                    <div className="comments-list mt-3">
                      {this.state.commentList.length
                        ? this.state.commentList.map((item, index) => (
                            <div className="list-parts">
                              <div className="post-block mb-3 wow fadeInUp">
                                <div className="post-toppart">
                                  <div className="left-part">
                                    <Link
                                      className="nav-link"
                                      to={`/admin/user-profile-detail/${this.props.postDetail[0].createdBy._id}`}
                                    >
                                      <img
                                        className={
                                          item.type === 'insight'
                                            ? 'insightimg'
                                            : item.createdBy.isBlogger
                                            ? 'blogger'
                                            : ''
                                        }
                                        src={
                                          item.createdBy.profilePicture
                                            ? item.createdBy.profilePicture
                                            : '../../assets/images/user/01.jpg'
                                        }
                                        alt="User Profile"
                                      />
                                      <p>
                                        {item.createdBy.name}
                                        {item.createdBy.isBlogger ? (
                                          <span style={{ fontSize: '13px' }}>
                                            {' '}
                                            <i
                                              className="fa fa-circle ml-1 mr-2"
                                              style={{
                                                fontSize: '8px',
                                                color: 'black',
                                              }}
                                            ></i>
                                            Blogger
                                          </span>
                                        ) : null}
                                      </p>{' '}
                                      <span>Posted : {item.creationDate}</span>
                                    </Link>
                                  </div>
                                  {item.type === 'insight' ? (
                                    <div className="right-part">
                                      <Link className="insight-icons">
                                        <img src="../../assets/images/icons/icon.png" alt="" />
                                      </Link>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                                <div className="messge-box">
                                  <p>
                                    <Linkify componentDecorator={componentDecorator}>
                                      {item.description.length > 200 ? (
                                        <ShowMoreText
                                          lines={3}
                                          more="Read more"
                                          less="Read less"
                                          onClick={this.executeOnClick}
                                          expanded={false}
                                        >
                                          {item.description}
                                        </ShowMoreText>
                                      ) : (
                                        item.description
                                      )}
                                    </Linkify>
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminPostDetail;
