import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
class ContactShorting extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    this.props.onSortChange(e.target.value);
  }
  render() {
    if (!this.props.contactData) return null;
    return (
      <div className="shorting-dashboard mt-3 ">
        <div className="main-section">
          <div className="left-part">
            <p>You have a total of {this.props.contactData.length} Contact Query</p>
          </div>
          <div className="right-part">
            <div className="form-group">
              <label>Sort By :</label>
              <select className="fomr-control" onChange={this.handleChange}>
                <option value="All_Time">All Time</option>
                <option value="last_minute">Last Minute</option>
                <option value="last_hour">Last Hours</option>
                <option value="last_week">Last Week</option>
                <option value="last_month">Last Month</option>
                <option value="last_quarter">Last Quarter</option>
                <option value="Last_Year">Last Year</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactShorting;
