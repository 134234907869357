import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import ReadMoreAndLess from 'react-read-more-less';
import * as postService from '../../services/postServices';
import { Tab, Tabs, TabList } from 'react-tabs';
import { Modal } from 'react-bootstrap';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import Prince1 from '../../assets/images/icons/Prince1.png';
import Prince2 from '../../assets/images/icons/Prince2.png';
import Prince3 from '../../assets/images/icons/Prince3.png';
import Prince4 from '../../assets/images/icons/Prince4.png';
import Heart1 from '../../assets/images/icons/Heart1.png';
import { resolveImageLink } from '../../helpers/helpers';

class CollectionDetailSection extends Component {
  state = {
    formData1: {
      type: '',
      postId: '',
    },
    collectionLikeList: [],
    collectionLikeCount: '',
    postId: '',
    deleteId: '',
    ikeshowHide: false,
    likeshowHide2: false,
    likeshowHide3: false,
    likeshowHide4: false,
    likeshowHide5: false,
    likeshowHide7: false,
  };

  likePopup = () => {
    this.setState({ likeshowHide: !this.state.likeshowHide });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide: false,
      });
    }, 6000);
  };
  likePopup2 = () => {
    this.setState({ likeshowHide2: !this.state.likeshowHide2 });
    this.setState({
      likeshowHide: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide2: false,
      });
    }, 6000);
  };
  likePopup3 = () => {
    this.setState({ likeshowHide3: !this.state.likeshowHide3 });
    this.setState({
      likeshowHide2: false,
      likeshowHide: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide3: false,
      });
    }, 6000);
  };
  likePopup4 = () => {
    this.setState({ likeshowHide4: !this.state.likeshowHide4 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide4: false,
      });
    }, 6000);
  };
  likePopup5 = () => {
    this.setState({ likeshowHide5: !this.state.likeshowHide5 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide5: false,
      });
    }, 6000);
  };
  likePopup7 = () => {
    this.setState({ likeshowHide7: !this.state.likeshowHide7 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide7: false,
      });
    }, 6000);
  };

  emojiModalShowHide(postId = '', type = '') {
    if (postId) {
      this.setState({ postId: postId });
      this.hitcollectionLikeList(postId, (type = ''));
    }
    if (type) {
      if (type == 'all') type = '';
      this.hitcollectionLikeList(this.state.postId, type);
    } else {
      this.setState({ emojishowHide: !this.state.emojishowHide });
    }
  }

  async hitcollectionLikeList(postId, type) {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/collectionLikeList?id=${postId}&type=${type}`,
      );

      if (data) {
        const collectionLikeList = data.collectionLikeList;
        this.setState({ collectionLikeList: collectionLikeList });
        this.setState({ collectionLikeCount: data.likes });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async hitAllLikeDataApi(postId, type) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/likeList?id=${postId}&type=${type}`);

      if (data) {
        const allLikeData = data.likeList;
        this.setState({ allLikeData: allLikeData });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  async hitLikeApi(postId, likeType, index) {
    if (window.location.href.includes('admin')) return;
    this.state.formData1.type = likeType;
    this.state.formData1.postId = postId;

    const { data, error } = await postService.createCollectionLike(this.state.formData1);
    if (error) {
      // this.loader('none');
      return;
    } else {
      if (data.isLike == 1) {
        this.props.onLikeChange2(likeType, index, 1);
      } else {
        this.props.onLikeChange2(likeType, index, 0);
      }
      // alert('Liked successfully')

      return;
    }
  }
  constructor(props) {
    super(props);
  }
  nFormatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
  };
  deleteCollection = async () => {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/deleteCollection/${this.state.deleteId}`,
      );

      if (data) {
        toast.success(data.message);
        this.deleteModalShowHide('');
        return await this.props.history.push('/collections');
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  deleteModalShowHide = (id) => {
    this.setState({ deleteId: id });
    this.setState({ deleteShowHide: !this.state.deleteShowHide });
  };
  render() {
    if (!this.props.collectionDetail[0] || !this.props.collectionDetail[0].createdBy) return null;
    return (
      <>
        <div className="collection-section collectiondetail[0] mt-3">
          <div className="collection-display-section ">
            <div className="main-section">
              <div className="post-block mb-3 wow fadeInUp">
                <div className="row">
                  <div className="col-4">
                    <div
                      className="collection-image-part collback"
                      style={{
                        backgroundImage: `url(${this.props.collectionDetail[0].image})`,
                      }}
                    ></div>
                  </div>
                  <div className="col-8">
                    <div className="collection-content">
                      <div className="collection-toppart">
                        <div className="left-part">
                          <Link className="nav-link">
                            <p>{this.props.collectionDetail[0].name}</p>
                          </Link>
                        </div>
                        <div className="right-part">
                          {this.props.collectionDetail[0].bookClubPost ? (
                            <Link className="smile-icon">
                              {' '}
                              <p className="back">
                                <i className="fa fa-database"></i>{' '}
                                {this.nFormatter(
                                  this.props.collectionDetail[0].post.length +
                                    this.props.collectionDetail[0].bookClubPost?.length,
                                )}{' '}
                                posts
                              </p>
                            </Link>
                          ) : (
                            <Link className="smile-icon">
                              <p className="back">
                                <i className="fa fa-database"></i>{' '}
                                {this.nFormatter(this.props.collectionDetail[0].post.length)} posts
                              </p>
                            </Link>
                          )}
                          {!window.location.href.includes('admin') &&
                          this.props.user._id == this.props.collectionDetail[0].createdBy._id ? (
                            <Link className="dotsbar">
                              <i className="fa fa-ellipsis-v"></i>
                              <div className="post-dot-option">
                                <ul>
                                  <li>
                                    <Link onClick={() => this.deleteModalShowHide(this.props.collectionDetail[0]._id)}>
                                      <i className="fa fa-trash"></i> Delete Collection
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </Link>
                          ) : null}
                        </div>
                      </div>
                      <div className="messge-box">
                        <ReadMoreAndLess
                          ref={this.ReadMore}
                          className="read-more-content"
                          charLimit={350}
                          readMoreText="Read more"
                          readLessText="Read less"
                        >
                          {this.props.collectionDetail[0].description}
                        </ReadMoreAndLess>
                      </div>
                      <div className="post-toppart">
                        <div className="left-part">
                          <Link className="nav-link">
                            <img
                              className={this.props.collectionDetail[0].createdBy.isBlogger ? 'blogger' : ''}
                              src={
                                this.props.collectionDetail[0].createdBy.profilePicture
                                  ? resolveImageLink(
                                      this.props.collectionDetail[0].createdBy?.profilePicture,
                                      this.props.collectionDetail[0].createdBy._id,
                                    )
                                  : '../../assets/images/user/01.jpg'
                              }
                              alt="User Profile"
                            />
                            <p>
                              {this.props.collectionDetail[0].createdBy.name}
                              {this.props.collectionDetail[0].createdBy.isBlogger ? (
                                <span style={{ fontSize: '13px' }}>
                                  {' '}
                                  <i className="fa fa-circle ml-1 mr-2" style={{ fontSize: '8px', color: 'black' }}></i>
                                  Blogger
                                </span>
                              ) : null}
                            </p>{' '}
                            <span>Posted : {this.props.collectionDetail[0].creationDate}</span>
                          </Link>
                        </div>
                        <div className="right-part">
                          <div className="liked-user">
                            <Link onClick={() => this.emojiModalShowHide(this.props.collectionDetail[0]._id)}>
                              {this.props.collectionDetail[0].likeList.length
                                ? `Liked by ${
                                    this.props.collectionDetail[0].likeList[
                                      this.props.collectionDetail[0].likeList.length - 1
                                    ].createdBy.name
                                  } ${this.props.collectionDetail[0].likeList.length > 1 ? `and others` : ''} `
                                : ''}
                            </Link>
                          </div>
                          <div className="reactionpart">
                            <Link
                              className="smile-icon"
                              onClick={() => this.emojiModalShowHide(this.props.collectionDetail[0]._id)}
                            >
                              {this.props.collectionDetail[0].my_like.length ? (
                                this.props.collectionDetail[0].my_like[0].type == '11' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup1.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '12' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup2.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '13' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup3.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '14' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup4.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '21' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise1.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '22' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise2.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '23' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise3.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '24' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise4.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '31' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist1.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '32' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist2.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '33' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist3.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '34' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist4.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '41' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand1.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '42' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand2.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '43' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand3.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '44' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand4.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '61' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Princess1.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '62' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Princess2.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '63' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Princess3.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '64' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src="/../assets/images/icons/Princess4.png" />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '71' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src={Prince1} />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '72' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src={Prince2} />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '73' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src={Prince3} />
                                  </span>
                                ) : this.props.collectionDetail[0].my_like[0].type == '74' ? (
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon3" src={Prince4} />
                                  </span>
                                ) : (
                                  <span>
                                    {' '}
                                    <img
                                      className="emoji-list-icon3"
                                      style={{ fontSize: '25px!important' }}
                                      src={Heart1}
                                    />
                                  </span>
                                )
                              ) : (
                                <span>
                                  {' '}
                                  <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                                </span>
                              )}{' '}
                            </Link>

                            <div className="emoji-list">
                              <ul>
                                <li className="dropUp" onClick={() => this.likePopup()}>
                                  <Link>
                                    <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup1.png" />

                                    <div className={this.state.likeshowHide ? 'emoji-list_active' : 'emoji-list2'}>
                                      <ul>
                                        <li className="dropUp">
                                          <div className="d-flex">
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 11)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon "
                                                style={{
                                                  position: 'absolute',
                                                  left: '-30px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Thumbsup1.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 12)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-24px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Thumbsup2.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 13)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-20px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Thumbsup3.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 14)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-16px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Thumbsup4.png"
                                              />
                                            </Link>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Link>
                                </li>

                                <li className="dropUp3" onClick={() => this.likePopup2()}>
                                  <Link>
                                    <img className="emoji-list-icon" src="/../assets/images/icons/Handraise1.png" />

                                    <div className={this.state.likeshowHide2 ? 'emoji-list3_active' : 'emoji-list3'}>
                                      <ul>
                                        <li className="dropUp">
                                          <div className="d-flex">
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 21)}
                                              className="mr-3 ml-4"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-30px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Handraise1.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 22)}
                                              className="mr-3"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-40px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Handraise2.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 23)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-50px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Handraise3.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 24)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-45px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Handraise4.png"
                                              />
                                            </Link>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Link>
                                </li>
                                <li className="dropUp4" onClick={() => this.likePopup3()}>
                                  <Link>
                                    <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist1.png" />
                                    <div className={this.state.likeshowHide3 ? 'emoji-list4_active' : 'emoji-list4'}>
                                      <ul>
                                        <li className="dropUp">
                                          <div className="d-flex">
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 31)}
                                              className="mr-3 ml-4"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-30px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Raised-fist1.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 32)}
                                              className="mr-3"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-40px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Raised-fist2.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 33)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-50px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Raised-fist3.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 34)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-45px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Raised-fist4.png"
                                              />
                                            </Link>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Link>
                                </li>
                                <li className="dropUp5" onClick={() => this.likePopup4()}>
                                  <Link>
                                    <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand1.png" />

                                    <div className={this.state.likeshowHide4 ? 'emoji-list5_active' : 'emoji-list5'}>
                                      <ul>
                                        <li className="dropUp">
                                          <div className="d-flex">
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 41)}
                                              className="mr-3 ml-4"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-30px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Folded-hand1.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 42)}
                                              className="mr-3"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-40px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Folded-hand2.png"
                                              />
                                            </Link>

                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 43)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-50px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Folded-hand3.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 44)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-45px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Folded-hand4.png"
                                              />
                                            </Link>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Link>
                                </li>
                                <li className="dropUp">
                                  <Link onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 5)}>
                                    <img
                                      className="emoji-list-icon"
                                      style={{
                                        fontSize: '25px!important',
                                      }}
                                      src={Heart1}
                                    />
                                  </Link>
                                </li>
                                <li className="dropUp6" onClick={() => this.likePopup5()}>
                                  <Link>
                                    <img className="emoji-list-icon" src="/../assets/images/icons/Princess1.png" />

                                    <div className={this.state.likeshowHide5 ? 'emoji-list6_active' : 'emoji-list6'}>
                                      <ul>
                                        <li className="dropUp">
                                          <div className="d-flex">
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 61)}
                                              className="mr-3 ml-4"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-30px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Princess1.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 62)}
                                              className="mr-3"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-40px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Princess2.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 63)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-50px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Princess3.png"
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 64)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-45px',
                                                  top: '-8px',
                                                }}
                                                src="/../assets/images/icons/Princess4.png"
                                              />
                                            </Link>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Link>
                                </li>
                                <li className="dropUp7" onClick={() => this.likePopup7()}>
                                  <Link>
                                    <img className="emoji-list-icon" src={Prince1} />
                                    <div className={this.state.likeshowHide7 ? 'emoji-list7_active' : 'emoji-list7'}>
                                      <ul>
                                        <li className="dropUp">
                                          <div className="d-flex">
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 71)}
                                              className="mr-3 ml-4"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-30px',
                                                  top: '-8px',
                                                }}
                                                src={Prince1}
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 72)}
                                              className="mr-3"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-40px',
                                                  top: '-8px',
                                                }}
                                                src={Prince2}
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 73)}
                                              className="mr-3"
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-50px',
                                                  top: '-8px',
                                                }}
                                                src={Prince3}
                                              />
                                            </Link>
                                            <Link
                                              onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 74)}
                                              className=""
                                            >
                                              <img
                                                className="emoji-list-icon"
                                                style={{
                                                  position: 'absolute',
                                                  left: '-60px',
                                                  top: '-8px',
                                                }}
                                                src={Prince4}
                                              />
                                            </Link>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal show={this.state.emojishowHide} className="emoji-modal ">
                    <Modal.Body>
                      <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                        <i className="fa fa-close"></i>
                      </Link>
                      <div className="collection">
                        <h4 className="">Reactions </h4>
                        <div className="reaction-parts">
                          <Tabs>
                            <TabList id="style-1">
                              <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                                All {this.nFormatter(this.state.collectionLikeCount.total_likes)}
                              </Tab>
                              <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                                <span>
                                  {' '}
                                  <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                                </span>{' '}
                                {this.nFormatter(this.state.collectionLikeCount.total_likes_1)}
                              </Tab>
                              <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                                <span>
                                  {' '}
                                  <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                                </span>{' '}
                                {this.nFormatter(this.state.collectionLikeCount.total_likes_2)}
                              </Tab>
                              <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                                <span>
                                  {' '}
                                  <img
                                    className="emoji-list-icon2"
                                    src="/../assets/images/icons/Raised-fist1.png"
                                  />{' '}
                                </span>{' '}
                                {this.nFormatter(this.state.collectionLikeCount.total_likes_3)}
                              </Tab>
                              <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                                <span>
                                  {' '}
                                  <img
                                    className="emoji-list-icon2"
                                    src="/../assets/images/icons/Folded-hand1.png"
                                  />{' '}
                                </span>{' '}
                                {this.nFormatter(this.state.collectionLikeCount.total_likes_4)}
                              </Tab>
                              <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                                <span>
                                  <img className="emoji-list-icon2" src={Heart1} />
                                </span>{' '}
                                {this.nFormatter(this.state.collectionLikeCount.total_likes_5)}
                              </Tab>
                              <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                                <span>
                                  {' '}
                                  <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                                </span>{' '}
                                {this.nFormatter(this.state.collectionLikeCount.total_likes_6)}
                              </Tab>
                              <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                                <span>
                                  {' '}
                                  <img className="emoji-list-icon2" src={Prince1} />{' '}
                                </span>{' '}
                                {this.nFormatter(this.state.collectionLikeCount.total_likes_7)}
                              </Tab>
                            </TabList>
                          </Tabs>
                          <div className="likeuser-list">
                            <ul>
                              {this.state.collectionLikeList.length
                                ? this.state.collectionLikeList.map((data, index) => {
                                    return (
                                      <li>
                                        <Link
                                          to={
                                            this.props.user._id != data.createdBy._id
                                              ? `/blogger-profile/${data.createdBy._id}`
                                              : `/user-profile`
                                          }
                                        >
                                          <h5>
                                            {data.createdBy.name}
                                            {data.createdBy.isBlogger ? (
                                              <span style={{ fontSize: '13px' }}>
                                                {' '}
                                                <i
                                                  className="fa fa-circle ml-1 mr-2"
                                                  style={{
                                                    fontSize: '8px',
                                                    color: 'black',
                                                  }}
                                                ></i>
                                                Blogger
                                              </span>
                                            ) : null}
                                          </h5>
                                          {data.createdBy.location ? (
                                            <span>{data.createdBy.location}</span>
                                          ) : (
                                            <span style={{ color: 'white' }}>Noida</span>
                                          )}
                                          <img
                                            className={data.createdBy.isBlogger ? 'blogger' : ''}
                                            src={
                                              data.createdBy.profilePicture
                                                ? resolveImageLink(data.createdBy.profilePicture, data.createdBy._id)
                                                : '../../assets/images/user/01.jpg'
                                            }
                                            alt="User Profile"
                                          />
                                        </Link>
                                      </li>
                                    );
                                  })
                                : ''}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  <Modal show={this.state.deleteShowHide} className="addcollection-modal">
                    <Modal.Body>
                      <Link className="closeicon" onClick={() => this.deleteModalShowHide()}>
                        <i className="fa fa-close"></i>
                      </Link>
                      <div className="dashboard-contactus-section">
                        <div className="top-section text-center">
                          <h3>Delete Collection</h3>
                          <p>Are you sure, You Want to delete this Collection?</p>
                        </div>
                        <div className="d-flex new-btn justify-content-center">
                          <div className="submit-buttons delete_btn" style={{ marginRight: '15px' }}>
                            <Link
                              type="button"
                              className=""
                              style={{ color: 'black' }}
                              onClick={() => this.deleteCollection()}
                            >
                              Yes
                            </Link>
                          </div>
                          <div className="submit-buttons delete_btn" style={{ marginLeft: '15px' }}>
                            <Link type="button" style={{ color: 'black' }} onClick={() => this.deleteModalShowHide()}>
                              No
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="collection-section collectiondetail[0] mt-3">
            <div className="collection-display-section1 ">
              <div className="main-section">
                <div className="post-block mb-3 wow fadeInUp">
                  <div className="row">
                    <div className="col-5">
                      <div
                        className="collection-image-part collback"
                        style={{
                          backgroundImage: `url(${this.props.collectionDetail[0].image})`,
                        }}
                      ></div>
                    </div>
                    <div className="col-7">
                      <div className="collection-content">
                        <div className="collection-toppart">
                          <div className="right-part">
                            {this.props.collectionDetail[0].bookClubPost ? (
                              <Link className="smile-icon">
                                <p className="back">
                                  <i className="fa fa-database"></i>{' '}
                                  {this.nFormatter(
                                    this.props.collectionDetail[0].post.length +
                                      this.props.collectionDetail[0].bookClubPost?.length,
                                  )}{' '}
                                  posts
                                </p>
                              </Link>
                            ) : (
                              <Link className="smile-icon">
                                <p className="back">
                                  <i className="fa fa-database"></i>{' '}
                                  {this.nFormatter(this.props.collectionDetail[0].post.length)} posts
                                </p>
                              </Link>
                            )}
                            {!window.location.href.includes('admin') &&
                            this.props.user._id == this.props.collectionDetail[0].createdBy._id ? (
                              <Link className="dotsbar">
                                <i className="fa fa-ellipsis-v"></i>
                                <div className="post-dot-option">
                                  <ul>
                                    <li>
                                      <Link
                                        onClick={() => this.deleteModalShowHide(this.props.collectionDetail[0]._id)}
                                      >
                                        <i className="fa fa-trash"></i> Delete Collection
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            ) : null}
                          </div>
                          <div className="left-part">
                            <Link className="nav-link">
                              <p>{this.props.collectionDetail[0].name}</p>
                            </Link>
                          </div>
                        </div>
                        <div className="messge-box">
                          <ReadMoreAndLess
                            ref={this.ReadMore}
                            className="read-more-content"
                            charLimit={100}
                            readMoreText="Read more"
                            readLessText="Read less"
                          >
                            {this.props.collectionDetail[0].description}
                          </ReadMoreAndLess>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="post-toppart">
                    <div className="left-part">
                      <Link className="nav-link">
                        <img
                          className={this.props.collectionDetail[0].createdBy.isBlogger ? 'blogger' : ''}
                          src={
                            this.props.collectionDetail[0].createdBy.profilePicture
                              ? resolveImageLink(
                                  this.props.collectionDetail[0].createdBy.profilePicture,
                                  this.props.collectionDetail[0].createdBy._id,
                                )
                              : '../../assets/images/user/01.jpg'
                          }
                          alt="User Profile"
                        />
                        <p>
                          {this.props.collectionDetail[0].createdBy.name}
                          {this.props.collectionDetail[0].createdBy.isBlogger ? (
                            <span style={{ fontSize: '13px' }}>
                              {' '}
                              <i className="fa fa-circle ml-1 mr-2" style={{ fontSize: '8px', color: 'black' }}></i>
                              Blogger
                            </span>
                          ) : null}
                        </p>{' '}
                        <span>Posted : {this.props.collectionDetail[0].creationDate}</span>
                      </Link>
                    </div>
                    <div className="right-part">
                      <div className="liked-user">
                        <Link onClick={() => this.emojiModalShowHide(this.props.collectionDetail[0]._id)}>
                          {this.props.collectionDetail[0].likeList.length
                            ? `Liked by ${
                                this.props.collectionDetail[0].likeList[
                                  this.props.collectionDetail[0].likeList.length - 1
                                ].createdBy.name
                              } ${this.props.collectionDetail[0].likeList.length > 1 ? `and others` : ''} `
                            : ''}
                        </Link>
                      </div>
                      <div className="reactionpart">
                        <Link
                          className="smile-icon"
                          onClick={() => this.emojiModalShowHide(this.props.collectionDetail[0]._id)}
                        >
                          {this.props.collectionDetail[0].my_like.length ? (
                            this.props.collectionDetail[0].my_like[0].type == '11' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup1.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '12' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup2.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '13' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup3.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '14' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup4.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '21' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise1.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '22' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise2.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '23' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise3.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '24' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise4.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '31' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist1.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '32' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist2.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '33' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist3.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '34' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Raised-fist4.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '41' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand1.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '42' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand2.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '43' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand3.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '44' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Folded-hand4.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '61' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Princess1.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '62' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Princess2.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '63' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Princess3.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '64' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src="/../assets/images/icons/Princess4.png" />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '71' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src={Prince1} />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '72' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src={Prince2} />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '73' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src={Prince3} />
                              </span>
                            ) : this.props.collectionDetail[0].my_like[0].type == '74' ? (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" src={Prince4} />
                              </span>
                            ) : (
                              <span>
                                {' '}
                                <img className="emoji-list-icon3" style={{ fontSize: '25px!important' }} src={Heart1} />
                              </span>
                            )
                          ) : (
                            <span>
                              {' '}
                              <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                            </span>
                          )}{' '}
                        </Link>

                        <div className="emoji-list">
                          <ul>
                            <li className="dropUp" onClick={() => this.likePopup()}>
                              <Link>
                                <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup1.png" />

                                <div className={this.state.likeshowHide ? 'emoji-list_active' : 'emoji-list2'}>
                                  <ul>
                                    <li className="dropUp">
                                      <div className="d-flex">
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 11)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon "
                                            style={{
                                              position: 'absolute',
                                              left: '-30px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Thumbsup1.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 12)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-24px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Thumbsup2.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 13)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-20px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Thumbsup3.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 14)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-16px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Thumbsup4.png"
                                          />
                                        </Link>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            </li>

                            <li className="dropUp3" onClick={() => this.likePopup2()}>
                              <Link>
                                <img className="emoji-list-icon" src="/../assets/images/icons/Handraise1.png" />

                                <div className={this.state.likeshowHide2 ? 'emoji-list3_active' : 'emoji-list3'}>
                                  <ul>
                                    <li className="dropUp">
                                      <div className="d-flex">
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 21)}
                                          className="mr-3 ml-4"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-30px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Handraise1.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 22)}
                                          className="mr-3"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-40px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Handraise2.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 23)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-50px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Handraise3.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 24)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-45px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Handraise4.png"
                                          />
                                        </Link>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            </li>
                            <li className="dropUp4" onClick={() => this.likePopup3()}>
                              <Link>
                                <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist1.png" />
                                <div className={this.state.likeshowHide3 ? 'emoji-list4_active' : 'emoji-list4'}>
                                  <ul>
                                    <li className="dropUp">
                                      <div className="d-flex">
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 31)}
                                          className="mr-3 ml-4"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-30px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Raised-fist1.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 32)}
                                          className="mr-3"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-40px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Raised-fist2.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 33)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-50px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Raised-fist3.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 34)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-45px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Raised-fist4.png"
                                          />
                                        </Link>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            </li>
                            <li className="dropUp5" onClick={() => this.likePopup4()}>
                              <Link>
                                <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand1.png" />

                                <div className={this.state.likeshowHide4 ? 'emoji-list5_active' : 'emoji-list5'}>
                                  <ul>
                                    <li className="dropUp">
                                      <div className="d-flex">
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 41)}
                                          className="mr-3 ml-4"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-30px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Folded-hand1.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 42)}
                                          className="mr-3"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-40px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Folded-hand2.png"
                                          />
                                        </Link>

                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 43)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-50px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Folded-hand3.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 44)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-45px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Folded-hand4.png"
                                          />
                                        </Link>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            </li>
                            <li className="dropUp">
                              <Link onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 5)}>
                                <img
                                  className="emoji-list-icon"
                                  style={{
                                    fontSize: '25px!important',
                                  }}
                                  src={Heart1}
                                />
                              </Link>
                            </li>
                            <li className="dropUp6" onClick={() => this.likePopup5()}>
                              <Link>
                                <img className="emoji-list-icon" src="/../assets/images/icons/Princess1.png" />

                                <div className={this.state.likeshowHide5 ? 'emoji-list6_active' : 'emoji-list6'}>
                                  <ul>
                                    <li className="dropUp">
                                      <div className="d-flex">
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 61)}
                                          className="mr-3 ml-4"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-30px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Princess1.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 62)}
                                          className="mr-3"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-40px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Princess2.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 63)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-50px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Princess3.png"
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 64)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-45px',
                                              top: '-8px',
                                            }}
                                            src="/../assets/images/icons/Princess4.png"
                                          />
                                        </Link>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            </li>
                            <li className="dropUp7" onClick={() => this.likePopup7()}>
                              <Link>
                                <img className="emoji-list-icon" src={Prince1} />
                                <div className={this.state.likeshowHide7 ? 'emoji-list7_active' : 'emoji-list7'}>
                                  <ul>
                                    <li className="dropUp">
                                      <div className="d-flex">
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 71)}
                                          className="mr-3 ml-4"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-30px',
                                              top: '-8px',
                                            }}
                                            src={Prince1}
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 72)}
                                          className="mr-3"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-40px',
                                              top: '-8px',
                                            }}
                                            src={Prince2}
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 73)}
                                          className="mr-3"
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-50px',
                                              top: '-8px',
                                            }}
                                            src={Prince3}
                                          />
                                        </Link>
                                        <Link
                                          onClick={() => this.hitLikeApi(this.props.collectionDetail[0]._id, 74)}
                                          className=""
                                        >
                                          <img
                                            className="emoji-list-icon"
                                            style={{
                                              position: 'absolute',
                                              left: '-60px',
                                              top: '-8px',
                                            }}
                                            src={Prince4}
                                          />
                                        </Link>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <Modal show={this.state.emojishowHide} className="emoji-modal ">
                      <Modal.Body>
                        <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                          <i className="fa fa-close"></i>
                        </Link>
                        <div className="collection">
                          <h4 className="">Reactions </h4>
                          <div className="reaction-parts">
                            <Tabs>
                              <TabList id="style-1">
                                <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                                  All {this.nFormatter(this.state.collectionLikeCount.total_likes)}
                                </Tab>
                                <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                                  </span>{' '}
                                  {this.nFormatter(this.state.collectionLikeCount.total_likes_1)}
                                </Tab>
                                <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                                  <span>
                                    {' '}
                                    <img
                                      className="emoji-list-icon2"
                                      src="/../assets/images/icons/Handraise1.png"
                                    />{' '}
                                  </span>{' '}
                                  {this.nFormatter(this.state.collectionLikeCount.total_likes_2)}
                                </Tab>
                                <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                                  <span>
                                    {' '}
                                    <img
                                      className="emoji-list-icon2"
                                      src="/../assets/images/icons/Raised-fist1.png"
                                    />{' '}
                                  </span>{' '}
                                  {this.nFormatter(this.state.collectionLikeCount.total_likes_3)}
                                </Tab>
                                <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                                  <span>
                                    {' '}
                                    <img
                                      className="emoji-list-icon2"
                                      src="/../assets/images/icons/Folded-hand1.png"
                                    />{' '}
                                  </span>{' '}
                                  {this.nFormatter(this.state.collectionLikeCount.total_likes_4)}
                                </Tab>
                                <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                                  <span>
                                    <img className="emoji-list-icon2" src={Heart1} />
                                  </span>{' '}
                                  {this.nFormatter(this.state.collectionLikeCount.total_likes_5)}
                                </Tab>
                                <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                                  <span>
                                    {' '}
                                    <img
                                      className="emoji-list-icon2"
                                      src="/../assets/images/icons/Princess1.png"
                                    />{' '}
                                  </span>{' '}
                                  {this.nFormatter(this.state.collectionLikeCount.total_likes_6)}
                                </Tab>
                                <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                                  <span>
                                    {' '}
                                    <img className="emoji-list-icon2" src={Prince1} />{' '}
                                  </span>{' '}
                                  {this.nFormatter(this.state.collectionLikeCount.total_likes_7)}
                                </Tab>
                              </TabList>
                            </Tabs>
                            <div className="likeuser-list">
                              <ul>
                                {this.state.collectionLikeList.length
                                  ? this.state.collectionLikeList.map((data, index) => {
                                      return (
                                        <li>
                                          <Link
                                            to={
                                              this.props.user._id != data.createdBy._id
                                                ? `/blogger-profile/${data.createdBy._id}`
                                                : `/user-profile`
                                            }
                                          >
                                            <h5>
                                              {data.createdBy.name}
                                              {this.props.collectionDetail[0].createdBy.isBlogger ? (
                                                <span style={{ fontSize: '13px' }}>
                                                  {' '}
                                                  <i
                                                    className="fa fa-circle ml-1 mr-2"
                                                    style={{
                                                      fontSize: '8px',
                                                      color: 'black',
                                                    }}
                                                  ></i>
                                                  Blogger
                                                </span>
                                              ) : null}
                                            </h5>
                                            {data.createdBy.location ? (
                                              <span>{data.createdBy.location}</span>
                                            ) : (
                                              <span style={{ color: 'white' }}>Noida</span>
                                            )}
                                            <img
                                              className={data.createdBy.isBlogger ? 'blogger' : ''}
                                              src={
                                                data.createdBy.profilePicture
                                                  ? resolveImageLink(data.createdBy.profilePicture, data.createdBy._id)
                                                  : '../../assets/images/user/01.jpg'
                                              }
                                              alt="User Profile"
                                            />
                                          </Link>
                                        </li>
                                      );
                                    })
                                  : ''}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default withRouter(CollectionDetailSection);
