import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import Userlisttable from './userSection/userlisttable';
import httpAdminService from './../../services/httpAdminService';
import { toast } from 'react-toastify';
import moment from 'moment';

class Userlist extends Component {
  state = {
    usersData: '',
  };
  async componentDidMount() {
    this.userApi();
    new WOW.WOW({
      live: false,
    }).init();
  }
  async userApi(sort = '') {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersData?sort=${sort}&type=user`,
      );
      if (data) {
        data.usersData.forEach((item) => {
          item.accountCreationDate =
            item.accountCreationDate && moment(item.accountCreationDate).format('MMM DD, YYYY h:mm A');
        });
        this.setState({ usersData: data.usersData });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  async blockChangeApi(id) {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/userBlock/${id}`);
      if (data) {
        toast.success(data.message);
        this.userApi();
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  sortChange = (value) => {
    this.userApi(value);
  };

  blockChange = (id) => {
    this.blockChangeApi(id);
  };

  render() {
    if (!this.state.usersData) return null;
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.props.location.user} {...this.props} />
                <Userlisttable
                  user={this.state.usersData}
                  {...this.props}
                  onSortChange={this.sortChange}
                  onBlockChange={this.blockChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <ScrollUpButton /> */}
      </div>
    );
  }
}

export default Userlist;
