import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
class Footer extends Component {
  state = {};
  render() {
    return (
      <div className="footer-part ">
        <div className="container">
          <div className="main-section wow slideInUp">
            <div className="row">
              <div className="col-4">
                <div className="grid-section">
                  <div className="logo">
                    <Link to="/">
                      <img src="../assets/images/logo/color-logo.png" alt="B-Thrive logo" />{' '}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-4 sec-2">
                <div className="grid-section">
                  <div className="heading">
                    <h6>Company</h6>
                    <div className="list-part">
                      <ul>
                        <li>
                          <Link to="/guideline">B-Thrive Guidelines</Link>
                        </li>
                        <li>
                          <Link to="/about-us">About Us</Link>
                        </li>
                        <li>
                          <Link to="/faq">FAQs</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="grid-section">
                  <div className="heading">
                    <h6>Legal</h6>
                    <div className="list-part">
                      <ul>
                        <li>
                          <Link to="/terms-and-condition">Terms & Conditions</Link>
                        </li>
                        <li>
                          <Link to="/privacy-policy">Privacy Policy</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
