import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import About from './bloggertabspart/about';
import Post from './bloggertabspart/post';
import Collection from './bloggertabspart/collection';
import Blogs from './bloggertabspart/blogs';
import httpService from '../../../services/httpService';
import { toast } from 'react-toastify';

class ProfileTabs extends Component {
  state = {
    usersData: {},
  };
  async componentDidMount() {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/bloggersProfile/${this.props.match.params.id}`,
      );
      if (data) {
        this.setState({
          usersData: data.usersData[0],
        });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  render() {
    return (
      <div className="tabs-profile-section mt-3">
        <div className="tabs-section-1">
          <Tabs>
            <TabList>
              <Tab>About </Tab>
              <Tab>Post </Tab>
              <Tab>Collections</Tab>
              {this.state.usersData.isBlogger && <Tab>Blog Posts </Tab>}
            </TabList>

            <TabPanel>
              <About user={this.state.usersData} />
            </TabPanel>
            <TabPanel>
              <Post user={this.state.usersData} />
            </TabPanel>
            <TabPanel>
              <Collection user={this.state.usersData} />
            </TabPanel>
            <TabPanel>
              <Blogs user={this.state.usersData} />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default ProfileTabs;
