import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from '../commonComponents/footer';
import Header from '../commonComponents/header';
class TermsAndCondition extends Component {
  state = {};
  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="guide mb-5">
          <div className="guidebody">
            <div className="rules">
              <center>
                <h1>
                  <b>Terms and conditions</b>
                </h1>
              </center>
              <ul>
                <li>
                  These terms and conditions (“Agreement”) set forth the general terms and conditions of your use of the
                  bthrivecommunity.com website (“Website”), “b-thrivecommunity.com” mobile application (“Mobile
                  Application”) and any of their related products and services (collectively, “Services”). This
                  Agreement is legally binding between you (“User”, “you” or “your”) and B-Thrive (“B-Thrive”, “we”,
                  “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity,
                  you represent that you have the authority to bind such entity to this agreement, in which case the
                  terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you
                  do not agree with the terms of this agreement, you must not accept this agreement and may not access
                  and use the Services. By accessing and using the Services, you acknowledge that you have read,
                  understood, and agree to be bound by the terms of this Agreement. You acknowledge that this Agreement
                  is a contract between you and B-Thrive, even though it is electronic and is not physically signed by
                  you, and it governs your use of the Services.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Accounts and membership</b>
                  </h4>
                </li>
                <li>
                  You must be at least 13 years of age to use the Services. By using the Services and by agreeing to
                  this Agreement you warrant and represent that you are at least 13 years of age. If you create an
                  account on the Services, you are responsible for maintaining the security of your account and you are
                  fully responsible for all activities that occur under the account and any other actions taken in
                  connection with it. We may monitor and review new accounts before you may sign in and start using the
                  Services. Providing false contact information of any kind may result in the termination of your
                  account. You must immediately notify us of any unauthorized uses of your account or any other breaches
                  of security. We will not be liable for any acts or omissions by you, including any damages of any kind
                  incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or
                  any part thereof) if we determine that you have violated any provision of this Agreement or that your
                  conduct or content would tend to damage our reputation and goodwill. If we delete your account for the
                  foregoing reasons, you may not re-register for our Services. We may block your email address and
                  Internet protocol address to prevent further registration.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>User content</b>
                  </h4>
                </li>
                <li>
                  We do not own any data, information or material (collectively, “Content”) that you submit on the
                  Services in the course of using the Service. You shall have sole responsibility for the accuracy,
                  quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or
                  right to use of all submitted Content. We may, but have no obligation to, monitor and review the
                  Content on the Services submitted or created using our Services by you. You grant us permission to
                  access, copy, distribute, store, transmit, reformat, display and perform the Content of your user
                  account solely as required for the purpose of providing the Services to you. Without limiting any of
                  those representations or warranties, we have the right, though not the obligation, to, in our own sole
                  discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies
                  or is in any way harmful or objectionable. Unless specifically permitted by you, your use of the
                  Services does not grant us the license to use, reproduce, adapt, modify, publish or distribute the
                  Content created by you or stored in your user account for commercial, marketing or any similar
                  purpose.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Accuracy of information</b>
                  </h4>
                </li>
                <li>
                  Occasionally there may be information on the Services that contains typographical errors, inaccuracies
                  or omissions that may relate to pricing, promotions and offers. We reserve the right to correct any
                  errors, inaccuracies or omissions, and to change or update information or cancel orders if any
                  information on the Services or Services is inaccurate at any time without prior notice (including
                  after you have submitted your order). We undertake no obligation to update, amend or clarify
                  information on the Services including, without limitation, pricing information, except as required by
                  law. No specified update or refresh date applied on the Services should be taken to indicate that all
                  information on the Services or Services has been modified or updated.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Third party services</b>
                  </h4>
                </li>
                <li>
                  If you decide to enable, access or use third party services, be advised that your access and use of
                  such other services are governed solely by the terms and conditions of such other services, and we do
                  not endorse, are not responsible or liable for, and make no representations as to any aspect of such
                  other services, including, without limitation, their content or the manner in which they handle data
                  (including your data) or any interaction between you and the provider of such other services. You
                  irrevocably waive any claim against B-Thrive with respect to such other services. B-Thrive is not
                  liable for any damage or loss caused or alleged to be caused by or in connection with your enablement,
                  access or use of any such other services, or your reliance on the privacy practices, data security
                  processes or other policies of such other services. You may be required to register for or log into
                  such other services on their respective platforms. By enabling any other services, you are expressly
                  permitting B-Thrive to disclose your data as necessary to facilitate the use or enablement of such
                  other service.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Backups</b>
                  </h4>
                </li>
                <li>
                  We perform regular backups of the Website and its Content and will do our best to ensure completeness
                  and accuracy of these backups. In the event of the hardware failure or data loss we will restore
                  backups automatically to minimize the impact and downtime.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Links to other resources</b>
                  </h4>
                </li>
                <li>
                  Although the Services may link to other resources (such as websites, mobile applications, etc.), we
                  are not, directly or indirectly, implying any approval, association, sponsorship, endorsement, or
                  affiliation with any linked resource, unless specifically stated herein. Some of the links on the
                  Services may be “affiliate links”. This means if you click on the link and purchase an item, B-Thrive
                  will receive an affiliate commission. We are not responsible for examining or evaluating, and we do
                  not warrant the offerings of, any businesses or individuals or the content of their resources. We do
                  not assume any responsibility or liability for the actions, products, services, and content of any
                  other third parties. You should carefully review the legal statements and other conditions of use of
                  any resource which you access through a link on the Services. Your linking to any other off-site
                  resources is at your own risk.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Prohibited uses</b>
                  </h4>
                </li>
                <li>
                  In addition to other terms as set forth in the Agreement, you are prohibited from using the Services
                  or Content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any
                  unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules,
                  laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the
                  intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander,
                  disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race,
                  age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or
                  transmit viruses or any other type of malicious code that will or may be used in any way that will
                  affect the functionality or operation of the Services, third party products and services, or the
                  Internet; (h) to spam, phish, pharm, pretext, spider, crawl, or scrape; (i) for any obscene or immoral
                  purpose; or (j) to interfere with or circumvent the security features of the Services, third party
                  products and services, or the Internet. We reserve the right to terminate your use of the Services for
                  violating any of the prohibited uses.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Intellectual property rights</b>
                  </h4>
                </li>
                <li>
                  “Intellectual Property Rights” means all present and future rights conferred by statute, common law or
                  equity in or in relation to any copyright and related rights, trademarks, designs, patents,
                  inventions, goodwill and the right to sue for passing off, rights to inventions, rights to use, and
                  all other intellectual property rights, in each case whether registered or unregistered and including
                  all applications and rights to apply for and be granted, rights to claim priority from, such rights
                  and all similar or equivalent rights or forms of protection and any other results of intellectual
                  activity which subsist or will subsist now or in the future in any part of the world. This Agreement
                  does not transfer to you any intellectual property owned by B-Thrive or third parties, and all rights,
                  titles, and interests in and to such property will remain (as between the parties) solely with
                  B-Thrive. All trademarks, service marks, graphics and logos used in connection with the Services, are
                  trademarks or registered trademarks of B-Thrive or its licensors. Other trademarks, service marks,
                  graphics and logos used in connection with the Services may be the trademarks of other third parties.
                  Your use of the Services grants you no right or license to reproduce or otherwise use any of B-Thrive
                  or third party trademarks.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Limitation of liability</b>
                  </h4>
                </li>
                <li>
                  To the fullest extent permitted by applicable law, in no event will B-Thrive, its affiliates,
                  directors, officers, employees, agents, suppliers or licensors be liable to any person for any
                  indirect, incidental, special, punitive, cover or consequential damages (including, without
                  limitation, damages for lost profits, revenue, sales, goodwill, use of content, impact on business,
                  business interruption, loss of anticipated savings, loss of business opportunity) however caused,
                  under any theory of liability, including, without limitation, contract, tort, warranty, breach of
                  statutory duty, negligence or otherwise, even if the liable party has been advised as to the
                  possibility of such damages or could have foreseen such damages. To the maximum extent permitted by
                  applicable law, the aggregate liability of B-Thrive and its affiliates, officers, employees, agents,
                  suppliers and licensors relating to the services will be limited to an amount no greater than one euro
                  or any amounts actually paid in cash by you to B-Thrive for the prior one month period prior to the
                  first event or occurrence giving rise to such liability. The limitations and exclusions also apply if
                  this remedy does not fully compensate you for any losses or fails of its essential purpose.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Indemnification</b>
                  </h4>
                </li>
                <li>
                  You agree to indemnify and hold B-Thrive and its affiliates, directors, officers, employees, agents,
                  suppliers and licensors harmless from and against any liabilities, losses, damages or costs, including
                  reasonable attorneys’ fees, incurred in connection with or arising from any third party allegations,
                  claims, actions, disputes, or demands asserted against any of them as a result of or relating to your
                  Content, your use of the Services or any willful misconduct on your part.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Severability</b>
                  </h4>
                </li>
                <li>
                  All rights and restrictions contained in this Agreement may be exercised and shall be applicable and
                  binding only to the extent that they do not violate any applicable laws and are intended to be limited
                  to the extent necessary so that they will not render this Agreement illegal, invalid or unenforceable.
                  If any provision or portion of any provision of this Agreement shall be held to be illegal, invalid or
                  unenforceable by a court of competent jurisdiction, it is the intention of the parties that the
                  remaining provisions or portions thereof shall constitute their agreement with respect to the subject
                  matter hereof, and all such remaining provisions or portions thereof shall remain in full force and
                  effect.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Changes and amendments</b>
                  </h4>
                </li>
                <li>
                  We reserve the right to modify this Agreement or its terms related to the Services at any time at our
                  discretion. When we do, we will revise the updated date at the bottom of this page. We may also
                  provide notice to you in other ways at our discretion, such as through the contact information you
                  have provided. An updated version of this Agreement will be effective immediately upon the posting of
                  the revised Agreement unless otherwise specified. Your continued use of the Services after the
                  effective date of the revised Agreement (or such other act specified at that time) will constitute
                  your consent to those changes.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Acceptance of these terms</b>
                  </h4>
                </li>
                <li>
                  You acknowledge that you have read this Agreement and agree to all its terms and conditions. By
                  accessing and using the Services you agree to be bound by this Agreement. If you do not agree to abide
                  by the terms of this Agreement, you are not authorized to access or use the Services.
                </li>
                <li className="mt-5">
                  <h4>
                    <b>Contacting us</b>
                  </h4>
                </li>
                <li>
                  If you have any questions, concerns, or complaints regarding this Agreement, we encourage you to
                  contact us using the details below:
                  <br />
                  contact@b-thrivecommunity.com
                  <br />
                  This document was last updated on February 23, 2022
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default TermsAndCondition;
