import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import Cardsview from './dashboardSection/cardsview';
import Linechart from './dashboardSection/linechart';
import Dashboardshorting from './dashboardSection/dashboardShorting';
import httpAdminService from './../../services/httpAdminService';
import { toast } from 'react-toastify';

class Dashboard extends Component {
  state = {
    admin: '',
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/adminData`);
      if (data) {
        this.setState(data);
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
    new WOW.WOW({
      live: false,
    }).init();
  }

  dashSortingApi = async (sort = '') => {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/adminData?sort=${sort}`);
      if (data) {
        this.setState(data);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  render() {
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader user={this.state.admin} />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.state.admin} {...this.props} />
                <Dashboardshorting user={this.state.admin} {...this.props} onSortChange={this.dashSortingApi} />
                <Cardsview user={this.state} {...this.props} />
                <Linechart />
              </div>
            </div>
          </div>
        </div>
        <ScrollUpButton />
      </div>
    );
  }
}

export default Dashboard;
