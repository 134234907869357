import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../services/registerServices';
import MediaQuery from 'react-responsive';

class Header extends Form {
  state = {
    contactUs: '',
    formData: {
      name: '',
      email: '',
      message: '',
    },
    segment: '/',
    errors: {},
  };
  schema = {
    name: Joi.string().min(3).max(30).required(),
    email: Joi.string().min(3).required().email(),
    message: Joi.string().max(100).required(),
  };
  doSubmit = async () => {
    const { error } = await registerService.contactUs(this.state.formData);
    if (error) return;
    this.state.contactUs = false;
    this.setState({
      formData: {
        name: '',
        email: '',
        message: '',
      },
    });
  };
  constructor() {
    super();
    this.state.contactUs = false;
  }
  toggleContactUs = () => {
    this.setState({ contactUs: !this.state.contactUs });
  };
  async componentDidMount() {
    const url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    this.setState({
      segment: segment,
    });
  }
  render() {
    var toggleDropDown = (e) => {
      this.setState({ show: !this.state.show });
    };
    return (
      <>
        <div className="menu-design-home wow slideInDown">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="logo-part">
                <Link className="navbar-brand" to="/">
                  <img src="../assets/images/logo/logo.png" alt="B-Thrive logo" />{' '}
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mr-auto">
                  <li className={this.state.segment == 'about-us' ? 'nav-item menubutton1  active' : 'nav-item '}>
                    {' '}
                    <Link className="nav-link" to="/about-us">
                      About Us
                    </Link>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <Link className="nav-link" onClick={this.toggleContactUs}>
                      Contact Us
                    </Link>
                  </li>
                  <li
                    className={
                      this.state.segment == 'login' ? 'nav-item menubutton ml-50 active' : 'nav-item menubutton ml-50 '
                    }
                  >
                    {' '}
                    <Link className="nav-link" to="/login">
                      Login
                    </Link>
                  </li>
                  <li
                    className={
                      this.state.segment == 'registration' ? 'nav-item menubutton  active' : 'nav-item menubutton  '
                    }
                  >
                    {' '}
                    <Link className="nav-link" to="/registration">
                      Sign Up
                    </Link>
                  </li>
                </ul>
              </div>
              <div className={this.state.contactUs ? 'contactus-section active' : 'contactus-section'}>
                <Link onClick={this.toggleContactUs} className="closeicon">
                  <i className="fa fa-close"></i>
                </Link>
                <div className="top-section text-center">
                  <h3>Contact Us</h3>
                  <p>Kindly fill the detail below</p>
                </div>
                <div className="form-section wow bounceIn">
                  <form>
                    {this.renderInput('name', 'Name', 'Enter Name')}
                    {this.renderInput('email', 'Email', 'Enter Email')}
                    {this.renderTextArea('message', 'Message')}

                    {this.renderButton('Contact Us', this.handleSubmit)}
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <MediaQuery query="(max-width:991px)">
          <div className="menu-design-home2 wow slideInDown">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="logo-part">
                  <Link className="navbar-brand" to="/">
                    <img src="../assets/images/logo/logo.png" alt="B-Thrive logo" />{' '}
                  </Link>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggleDropDown}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                {this.state.show && (
                  <div className="user-profile-bar-3">
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                      <ul className="navbar-nav mr-auto">
                        <li className={this.state.segment == 'about-us' ? 'nav-item menubutton1  active' : 'nav-item '}>
                          {' '}
                          <Link className="nav-link" to="/about-us">
                            About Us
                          </Link>
                        </li>
                        <li className="nav-item">
                          {' '}
                          <Link className="nav-link" onClick={this.toggleContactUs}>
                            Contact Us
                          </Link>
                        </li>
                        <li
                          className={
                            this.state.segment == 'login'
                              ? 'nav-item menubutton ml-50 active'
                              : 'nav-item menubutton ml-50 '
                          }
                        >
                          {' '}
                          <Link className="nav-link" to="/login">
                            Login
                          </Link>
                        </li>
                        <li
                          className={
                            this.state.segment == 'registration'
                              ? 'nav-item menubutton  active'
                              : 'nav-item menubutton  '
                          }
                        >
                          {' '}
                          <Link className="nav-link" to="/registration">
                            Sign Up
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className={this.state.contactUs ? 'contactus-section active' : 'contactus-section'}>
                      <Link onClick={this.toggleContactUs} className="closeicon">
                        <i className="fa fa-close"></i>
                      </Link>
                      <div className="top-section text-center">
                        <h3>Contact Us</h3>
                        <p>Kindly fill the detail below</p>
                      </div>
                      <div className="form-section wow bounceIn">
                        <form>
                          {this.renderInput('name', 'Name', 'Enter Name')}
                          {this.renderInput('email', 'Email', 'Enter Email')}
                          {this.renderTextArea('message', 'Message')}

                          {this.renderButton('Contact Us', this.handleSubmit)}
                        </form>
                      </div>
                    </div>
                  </div>
                )}
              </nav>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default Header;
