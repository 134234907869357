import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Sidebar from './../commonComponents/sidebar';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import LearnItemSlider from './learnSection/learnItnemSlider';
import Events from './../commonComponents/events';
import Contactus from '../commonComponents/contactus';
import LearnWithBthrivePosts from '../commonComponents/learnWithBthriveAdminPosts';
import parse from 'html-react-parser';
import $ from 'jquery';
import MediaQuery from 'react-responsive';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class LearnPage extends Component {
  state = {
    user: [],
    listData: '',
    page: Number(1),
  };
  async componentDidMount() {
    this.postAPI('post', this.state.page);
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }
  componentWillUnmount() {
    // window.removeEventListener("scroll", this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  async postAPI() {
    this.loader('block');
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/learnWithBthrivePost/post?page=${Number(1)}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;

        this.setState({ listData: listData });

        this.loader('none');

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error('error coming from here');
      toast.error(error.message);
      return;
    }
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      // this.postAPI(this.tabType, this.state.page);
    }
  };

  onLike = () => {
    this.componentDidMount();
  };
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  render() {
    if (!this.state.listData) return null;
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2 wow slideInLeft">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <LearnItemSlider />
                  <LearnWithBthrivePosts user={this.state.user} data={this.state.listData} onLike={this.onLike} />
                </div>
                <div className="col-2 wow slideInLeft">
                  <Events />
                </div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>

        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1 learnwith">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                {/* <Contactus /> */}
                <div className="row">
                  <div className="col-2 wow slideInLeft">
                    <Sidebar {...this.props} />
                  </div>
                  <Modal show={this.state.showHide} className="upcommingevent-modal ">
                    <Modal.Body>
                      <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                        <i className="fa fa-close"></i>
                      </Link>
                      <Events />
                    </Modal.Body>
                  </Modal>
                  <div className="col-12 wow bounceIn">
                    <LearnItemSlider />
                    <LearnWithBthrivePosts user={this.state.user} data={this.state.listData} onLike={this.onLike} />
                  </div>
                </div>
              </div>
            </div>
            <div className="plus">
              <p className="plus-btn" onClick={() => this.handleModalShowHide()}>
                <i className="fa fa-plus "></i>
              </p>
            </div>
            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default LearnPage;
