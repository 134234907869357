import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import httpService from '../../../../services/httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import $ from 'jquery';
import CollectionListSection from '../../../../collectionsComponent/collectionsSection/collectionListSection';

class Collection extends Component {
  state = {
    listData: '',
    page: Number(1),
    user: '',
  };
  totalData = '';
  pageData = [];

  async componentDidMount() {
    this.collectionApi(this.state.page);
    //window.addEventListener("scroll", this.loadMore);
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  componentWillUnmount() {
    //window.removeEventListener("scroll", this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.collectionApi(this.state.page);
      }
    }
  };

  async collectionApi(page) {
    this.loader('block');
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/otherCollectionList/${this.props.user._id}/?page=${page}&type=my`,
      );

      if (data) {
        data.collectionList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
        });
        const listData = data.collectionList[0].data;
        this.totalData = data.collectionList[0].metadata[0] ? data.collectionList[0].metadata[0].total : '';
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });

        this.loader('none');

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }
  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.state.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.state.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.state.user.name, _id: this.state.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.state.user.name, _id: this.state.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
  };

  render() {
    return (
      <div>
        <CollectionListSection listData={this.state.listData} user={this.state.user} onLikeChange={this.handleLike} />
      </div>
    );
  }
}

export default Collection;
