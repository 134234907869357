import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
class Usertopmenubar extends Component {
  state = {};
  render() {
    return (
      <div className="usertopmenubar">
        <div className="main-section">
          <Link onClick={() => this.props.history.goBack()} className="backoptions">
            <i className="fa fa-angle-double-left"></i>
          </Link>
          <ul>
            <li className={`menulink ${this.props.location.pathname.includes('user-profile-detail') ? 'active' : ''}`}>
              <Link to={`/admin/user-profile-detail/${this.props.user._id}`}>Profile</Link>
            </li>
            <li className={`menulink ${this.props.location.pathname.includes('user-activity-list') ? 'active' : ''}`}>
              <Link to={`/admin/user-activity-list/${this.props.user._id}`}>Activity</Link>
            </li>
            <li className={`menulink ${this.props.location.pathname.includes('user-usage') ? 'active' : ''}`}>
              <Link to={`/admin/user-usage/${this.props.user._id}`}>Usage</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default Usertopmenubar;
