import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import { toast } from 'react-toastify';
import httpAdminService from '../../../services/httpAdminService';
class UserusageSection extends Component {
  state = {
    usersUsage: '',
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersUsage/${this.props.match.params.id}`,
      );
      if (data) {
        this.setState({ usersUsage: data });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  render() {
    return (
      <div>
        <div className="cards-view-part wow fadeInUp">
          <div className="main-section">
            <div className="row">
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Events Attended</p>
                  <h3>{this.state.usersUsage.eventAttendeesCount}</h3>
                </div>
              </div>
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Comments</p>
                  <h3>{this.state.usersUsage.commentCount}</h3>
                </div>
              </div>
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Reactions</p>
                  <h3>{this.state.usersUsage.reactionCount}</h3>
                </div>
              </div>
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Questions</p>
                  <h3>{this.state.usersUsage.questionCount}</h3>
                </div>
              </div>
              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>Collections</p>
                  <h3>{this.state.usersUsage.collectionCount}</h3>
                </div>
              </div>

              <div className="col-4">
                <div className="cards-part wow fadeInUp">
                  <p>No of Logins</p>
                  <h3>{this.state.usersUsage.numberOfLogins}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserusageSection;
