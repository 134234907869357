import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import ContactTabs from './contactSection/contactTabs';
class Contactlist extends Component {
  state = {
    contactData: '',
  };
  async componentDidMount() {}
  render() {
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.props.location.state.user} {...this.props} />
                <ContactTabs />
              </div>
            </div>
          </div>
        </div>
        {/* <ScrollUpButton /> */}
      </div>
    );
  }
}

export default Contactlist;
