import React from 'react';
import { Link } from 'react-router-dom';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import * as registerService from './../services/registerServices';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
class OtpVerification extends Form {
  state = {
    formData: { otp: '' },
    errors: {},
  };

  schema = {
    otp: Joi.number().required(),
  };

  componentDidMount = async () => {
    localStorage.removeItem('token');
    if (!this.props.location.email) return await this.props.history.push(`/login`);
    new WOW.WOW({
      live: false,
    }).init();
  };

  doSubmit = async () => {
    await localStorage.removeItem('token');
    const { data, error } = await registerService.otpVerification(this.state.formData, this.props.location.email);
    if (error) return;
    else {
      if (this.props.forgotPassword === true) {
        return await this.props.history.push({
          pathname: `/forgotPassword/resetPassword`,
          email: this.props.location.email,
        });
      } else {
        if (data.isregistered === false) {
          return await this.props.history.push('/get-start');
        } else {
          this.props.history.push(`/${data.profileType}`);
        }
      }
    }
  };

  render() {
    return (
      <div className="loginpage">
        <div className="container-fluid login-header">
          <Header {...this.props} />
        </div>
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="/assets/images/icons/login.png" alt="Login logo" />{' '}
              </Link>
              <h3>Please enter OTP</h3>
            </div>
            <div className="form-section wow bounceIn">
              <form>
                {this.renderInput('otp', 'One Time Password', 'Enter OTP')}
                {this.renderButton('Submit', this.handleSubmit)}
              </form>
            </div>
            <div className="login-footer text-center mt-5">
              <p>
                New to B-Thrive? <Link to="/registration">Create An Account</Link>
              </p>
              <p>
                Can't Login? <Link to="/forgot-password">Reset Password</Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default OtpVerification;
