import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
import httpService from '../services/httpService';
import { toast } from 'react-toastify';
import * as registerService from './../services/registerServices';
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

class ChooseInterestPage extends Component {
  state = {
    errors: {},
    interestList: null,
    selectedInterest: [],
    data: [],
    showHide: false,
  };

  constructor() {
    super();
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  async componentDidMount() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getInterest`);
      this.setState({ interestList: data.interests });
    } catch (error) {
      toast.error(error.message);
      return;
    }
    new WOW.WOW({
      live: false,
    }).init();
  }

  handleInputChange(e, userid) {
    let intererests = this.state.interestList;
    intererests.forEach((item) => {
      if (item._id == userid) {
        if (e.target.checked) {
          this.state.data.push(item);
        } else {
          if (this.state.data && this.state.data.length) this.state.data.splice(0, 1);
        }
      }
    });
    this.setState({ selectedInterest: this.state.data });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.interestList === null || this.state.interestList.length === 0) {
      this.handleSubmitfree(e);
      return;
    }
    let selectedInterest = [...this.state.selectedInterest];
    if (selectedInterest.length == 0) {
      toast.error('Please Choose Atleast One Interest');
      return;
    }
    const { data, error } = await registerService.saveInterest(selectedInterest);
    if (error) return;
    else {
      this.handleModalShowHide();
    }
  };
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  handleSubmitfree = async (e) => {
    e.preventDefault();
    this.handleModalShowHide();
  };
  redirectPage = async () => {
    return await this.props.history.push(`/tutorial1`);
  };
  render() {
    return (
      <div className="loginpage">
        <div className="container-fluid login-header">
          <Header {...this.props} />
        </div>
        <div className="login-section ">
          <div className="container">
            <div className="process-bar">
              <ul>
                <li className="active wow bounceIn">
                  <span>
                    <i className="fa fa-check"></i>
                  </span>
                  <p>Your Profile</p>
                </li>
                <li className=" active wow bounceIn">
                  <span>
                    <i className="fa fa-check"></i>
                  </span>
                  <p>Interests</p>
                </li>
                <li className=" wow bounceIn">
                  <span>3</span>
                  <p>Finish</p>
                </li>
              </ul>
            </div>
            <div className="top-section text-center">
              <h3>Your Journey Starts Here... </h3>
              <p>Select your interest (choose all that apply)</p>
            </div>
            <div className="choose-intrest wow bounceIn">
              <form>
                {this.state.interestList === null || this.state.interestList.length === 0 ? (
                  <h1 className="no-data-found">No Interests Assigned Yet</h1>
                ) : (
                  <ul>
                    {this.state.interestList.map((user, index) => (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            name="selectedInterest"
                            onChange={(e) => this.handleInputChange(e, user._id)}
                            value={user._id}
                            className="card-input-element"
                          />
                          <div className="card-input">
                            <img src={user.profilePicture} alt="Interests image" />
                            <div className="intrestname">
                              <p>{user.name}</p>
                            </div>
                          </div>
                        </label>
                      </li>
                    ))}
                  </ul>
                )}
                <div className="button-section">
                  <button onClick={this.handleSubmitfree} className="btn btn-warning-gradient btn-block" name="submit">
                    Skip for Later
                  </button>
                </div>
                <div className="button-section">
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-warning-gradient continues btn-block"
                    name="submit"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal show={this.state.showHide} className="welcomepopup-modal ">
          <Modal.Body>
            <div className="welcomepopup text-center">
              <p>Hi, {this.props.location.state?.name}</p>
              <h4 className="">Thank You For Joining The B-Thrive Community!</h4>
              <img src="assets/images/icons/welcome.jpg" alt="user" className="Welcome-icon" />
              <p>
                We’re excited to have you here. Complete your profile and join
                <br />
                our efforts to reclaim, rebuild, and regenerate our communities.
              </p>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.redirectPage()}>
                  Continue
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </div>
    );
  }
}

export default ChooseInterestPage;
