import httpService from './httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import httpAdminService from './httpAdminService';

export async function getEvents(sort, search = '', type = '') {
  try {
    const { data } = await httpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/getEvents?sort=${sort}&search=${search}&type=${type}`,
    );
    toast.success(data.message);
    data.events.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function getClasses(sort, search, type = '') {
  try {
    const { data } = await httpService.get(
      `${process.env.REACT_APP_APIENDPOINT}/getClasses?sort=${sort}&search=${search}&type=${type}`,
    );
    toast.success(data.message);
    data.classes.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function getPastEvents() {
  try {
    const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getEvents?type=past`);
    toast.success(data.message);
    data.events.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function getPastClasses() {
  try {
    const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getClasses?type=past`);
    toast.success(data.message);
    data.classes.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function getUpcomingEvents() {
  try {
    const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getEvents?type=upcoming`);
    toast.success(data.message);
    data.events.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function getUpcomingClasses() {
  try {
    const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getClasses?type=upcoming`);
    toast.success(data.message);
    data.classes.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminGetEvents(sort) {
  try {
    const { data } = await httpAdminService.get(
      `${process.env.REACT_APP_APIENDPOINT}/admin/adminGetEvents?sort=${sort}`,
    );
    toast.success(data.message);
    data.events.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);

    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}

export async function adminGetClasses(sort) {
  try {
    const { data } = await httpAdminService.get(
      `${process.env.REACT_APP_APIENDPOINT}/admin/adminGetClasses?sort=${sort}`,
    );
    toast.success(data.message);
    data.classes.forEach((item) => {
      item.date = item.date && moment(item.date).format('LL');
    });
    if (!data.message) toast.success(data);
    return { data };
  } catch (error) {
    if (error.response) toast.error(error.response.data.error);
    return { error };
  }
}
