import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import httpService from '../services/httpService';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import $ from 'jquery';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import BookClubPostQuestionList from './bookClubPostQuestionList';
class BookClubPostquestion extends Component {
  pageData = [];
  tabType = 'post';
  sortType = 'desc';
  totalData = '';
  state = {
    listData: '',
    page: Number(1),
  };

  async componentDidMount() {
    this.postAPI('post', this.state.page);

    window.addEventListener('scroll', this.loadMore);
    document.body.addEventListener('touchmove', this.loadMore);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
    document.body.removeEventListener('touchmove', this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  async postAPI(name, page, sort = '') {
    //this.loader("block");
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/bookClubPost/${name}?page=${page}&sort=${this.sortType}`,
      );
      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm a');
          item.description = parse(item.description);
        });

        const listData = data.postList[0].data;
        this.totalData = data.postList[0]?.metadata[0]?.total;
        this.pageData = [...this.pageData, ...listData];
        const finalData = this.filterlist(this.pageData);
        if (this.props.user.followedBookClub) this.setState({ listData: finalData });
        else this.setState({ listData: finalData[0] ? [finalData[0]] : [] });
        //this.loader("none");
        // this.props.onFollow();

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }
  filterlist = (data) => {
    let listData = data;
    for (var index = 0; index < listData.length; index++) {
      if (
        this.props.user.usersBlocked?.includes(listData[index].createdBy._id) ||
        listData[index].createdBy.usersBlocked?.includes(this.props.user._id)
      ) {
        listData.splice(index, 1);
        index--;
      }
    }
    return listData;
    //this.setState({ listData: listData });
  };
  onBlock = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.componentDidMount();
  };
  constructor(props) {
    super(props);
  }

  loadMore = () => {
    if (!this.props.user.followedBookClub) return;
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.postAPI(this.tabType, this.state.page);
      }
    }
  };

  onFilterChange = (e) => {
    this.pageData = [];
    this.setState({
      page: Number(1),
    });
    this.sortType = e.target.value;
    this.postAPI(this.tabType, Number(1), e.target.value);
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.props.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.props.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.props.user.name, _id: this.props.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.props.user.name, _id: this.props.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };

  render() {
    if (!this.props.user) return null;
    return (
      <div>
        {this.props.user.followedBookClub ? (
          <div className="shorting-post mt-3 ">
            <div className="main-section">
              <div className="left-part">
                {/* <p>Showing <span>{this.state.listData.length}</span> New {this.tabType == 'post' ? 'Posts' : 'Questions'}</p> */}
              </div>
              <div className="right-part">
                {this.state.listData.length > 1 ? (
                  <div className="form-group">
                    <label>Sort By :</label>
                    <select className="fomr-control" onChange={this.onFilterChange}>
                      <option value="desc">Latest</option>
                      <option value="popular">Most Popular</option>
                    </select>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <BookClubPostQuestionList
          listData={this.state.listData}
          {...this.props}
          user={this.props.user}
          onLikeChange={this.handleLike}
          onBlock={this.onBlock}
          onEdit={this.onBlock}
        />
      </div>
    );
  }
}

export default BookClubPostquestion;
