import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import httpService from '../services/httpService';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import Contactus from './contactus';
import Form from '../macroComponents/form/form';
import { Modal } from 'react-bootstrap';
import Joi from 'joi-browser';
import * as registerService from './../services/registerServices';
import Feedbacksupport from './feedbacksupport';

class UserHeader extends Form {
  state = {
    notification: '',
    notificationCount: 0,
    notificationList: [],
    isBarShow: false,
    isEmpty: false,
    searchKeyword: '',
    isPostList: false,
    isQuestionList: false,
    isEventList: false,
    timer: null,
    formData: {
      name: '',
      email: '',
      message: '',
    },
    errors: {},
  };
  ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };

  schema = {
    name: Joi.string().min(3).max(30).required(),
    email: Joi.string().min(3).required().email(),
    message: Joi.string().max(100).required(),
  };
  doSubmit = async () => {
    if (!this.ValidateEmail(this.state.formData.email)) {
      toast.error('Invalid Email');
      return;
    }
    this.contactModalShowHide();
    const { error } = await registerService.contactUs(this.state.formData);
    if (error) return;
    this.state.contactUs = false;
  };
  contactModalShowHide = () => {
    this.setState({ contactshowHide: !this.state.contactshowHide });
    this.setState({
      formData: {
        name: '',
        email: '',
        message: '',
      },
    });
  };
  handleModalShowHide = () => {
    this.setState({ showHide: !this.state.showHide });
  };
  constructor(props) {
    super(props);
    this.state.notification = false;
  }
  componentDidMount = () => {
    this.notification();
  };
  updateInput = async (event) => {
    clearTimeout(this.state.timer);
    if (event.key !== 'Enter') {
      const time = setTimeout(async () => {
        if (!event.target.value) {
          this.setState({
            isBarShow: false,
          });
        } else {
          this.setState({
            searchKeyword: event.target.value,
          });
          await this.hitSearchApi(event.target.value, '', 1);
        }
      }, 500);
      this.setState({ timer: time });
    }
  };
  onEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      return this.props.history.push(`/search-result/${this.state.searchKeyword}/post`);
    }
  };

  async hitSearchApi(postsearchValue, type = '', page = '') {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/searchResult?search=${postsearchValue}&type=${type}&page=${page}`,
      );

      if (data) {
        const postList = data.postList;
        const questionList = data.questionList;
        const eventList = data.eventList;
        if (postList.length || questionList.length || eventList.length) {
          this.setState({
            isBarShow: true,
            isEmpty: false,
            isPostList: postList.length ? true : false,
            isQuestionList: questionList.length ? true : false,
            isEventList: eventList.length ? true : false,
          });
        } else {
          this.setState({
            isBarShow: false,
            isEmpty: true,
          });
        }

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  toggleNotification = async () => {
    this.setState({ notification: !this.state.notification });
    if (!this.state.notification) {
      this.notification();
    }
  };
  async notification() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/notification`);
      if (data) {
        this.setState({ notificationList: data.notification });
        this.setState({ notificationCount: data.count });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  read = async (id, type, url) => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/read/${id}?type=${type}`);
      if (data) {
        if (url) return await this.props.history.push(url);
        else return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  readAll = () => {
    for (const n of this.state.notificationList) {
      let type;
      if (
        [11, 12, 13, 14, 21, 22, 23, 24, 31, 32, 33, 34, 41, 42, 43, 44, 5, 61, 62, 63, 64, 71, 72, 73, 74].includes(
          parseInt(n.type),
        ) &&
        (n.postType == 'post' || n.postType == 'question')
      )
        type = 'like';
      else if (n.type == 'comment' || n.type == 'insight') type = 'comment';
      else type = 'clike';
      this.read(n._id, type, '');
      // this.setState({ notificationList: [] });
      this.setState({ notificationCount: 0 });
      this.componentDidMount();
      //this.toggleNotification();
    }
  };

  hitApi(name) {
    window.location = '/search-result/' + this.state.searchKeyword + '/' + name;
  }
  contactUs = () => {
    return <Contactus contact={true} />;
  };
  render() {
    var toggleDropDown = (e) => {
      this.setState({ show: !this.state.show });
    };
    if (!this.props.user) return null;
    return (
      <>
        <div className="header-section">
          <div className="menu-design-userhome">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="logo-part">
                  <Link className="navbar-brand" to="/user-home">
                    <img src="/../assets/images/logo/logo.png" alt="B-Thrive logo" />{' '}
                  </Link>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <form className="form-inline serachbars my-2 my-lg-0">
                  <input
                    className="form-control"
                    type="search"
                    onKeyDown={this.onEnter}
                    onChange={this.updateInput}
                    placeholder="Search B-Thrive"
                    aria-label="Search"
                  />
                  {this.state.isBarShow ? (
                    <div className="serach-resilt">
                      <ul>
                        {this.state.isEmpty ? (
                          <li>
                            <span>No records found:</span>
                          </li>
                        ) : (
                          <div>
                            {this.state.isPostList ? (
                              <Link onClick={() => this.hitApi('post')}>
                                <li>
                                  <span>Post:</span> {this.state.searchKeyword}
                                </li>
                              </Link>
                            ) : (
                              ''
                            )}
                            {this.state.isQuestionList ? (
                              <Link onClick={() => this.hitApi('question')}>
                                <li>
                                  <span>Question:</span> {this.state.searchKeyword}
                                </li>
                              </Link>
                            ) : (
                              ''
                            )}
                            {this.state.isEventList ? (
                              <Link onClick={() => this.hitApi('event')}>
                                <li>
                                  <span>Event:</span> {this.state.searchKeyword}
                                </li>
                              </Link>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </form>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <Link className="nav-link search-user mb-0" to="/blogger-list">
                        <i className="fa fa-user"></i>
                      </Link>
                      <p className="partner mb-1 ">Partners</p>
                    </li>
                    <li className="nav-item">
                      {' '}
                      <Link className="nav-link noti-header" onClick={this.toggleNotification}>
                        <img
                          src="/../assets/images/icons/bell.png"
                          alt="Search User"
                          style={{
                            width: '20px',
                            marginBottom: '6px',
                            marginLeft: '-15px',
                            marginRight: '40px',
                          }}
                        />
                        <span>{this.state.notificationCount}</span>
                      </Link>
                    </li>
                    <li className="nav-item ">
                      {' '}
                      <Link className="nav-link user-profile">
                        <img
                          className={this.props.user.isBlogger ? 'blogger' : ''}
                          src={
                            this.props.user.profilePicture
                              ? this.props.user.profilePicture
                              : '/../assets/images/user/01.jpg'
                          }
                          alt="User Profile"
                        />{' '}
                        <span>{this.props.user.name}</span>
                        <div className="user-profile-bar">
                          <ul>
                            <li>
                              <Link to="/user-profile">
                                <i className="fa fa-user"></i> My Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="/edit-profile">
                                <i className="fa fa-edit"></i> Edit Profile
                              </Link>
                            </li>
                            {!(this.props.user.registeredWithGoogle || this.props.user.registeredWithLinkedin) && (
                              <li>
                                <Link to="/edit-password">
                                  <i className="fa fa-lock"></i> Edit Password
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link to="/signOut">
                                <i className="fa fa-sign-out"></i> Logout
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={this.state.notification ? 'notify-section active' : 'notify-section'}>
                  <Link onClick={this.toggleNotification} className="closeicon">
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="notification-list">
                    <div className="top-section">
                      <h3>
                        Notifications
                        <Link className="readallright" onClick={this.readAll}>
                          <img src="/../assets/images/icons/readall.png" alt="Read All" />
                        </Link>
                      </h3>
                    </div>
                    <div className="list-part">
                      <ul>
                        {this.state.notificationList.map((notify, index) => {
                          return (
                            <li key={index} className={notify.isRead ? 'list1' : 'list'}>
                              {[
                                21, 22, 23, 24, 31, 32, 33, 34, 41, 42, 43, 44, 5, 61, 62, 63, 64, 71, 72, 73, 74,
                              ].includes(parseInt(notify.type)) &&
                                notify.postType == 'post' && (
                                  <Link
                                    onClick={() =>
                                      this.read(
                                        notify._id,
                                        'like',
                                        notify.model
                                          ? `/post-detail/bookClubPost/${notify.postId}`
                                          : '/post-detail/post/' + notify.postId,
                                      )
                                    }
                                  >
                                    <img
                                      className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                      src={
                                        notify.createdBy.profilePicture
                                          ? notify.createdBy.profilePicture
                                          : '/../assets/images/user/01.jpg'
                                      }
                                      alt="user"
                                    />
                                    <span>{notify.createdBy.name}</span> Reacted on your Post
                                  </Link>
                                )}
                              {[11, 12, 13, 14].includes(parseInt(notify.type)) && notify.postType == 'post' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'like',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Liked your Post
                                </Link>
                              )}
                              {[
                                21, 22, 23, 24, 31, 32, 33, 34, 41, 42, 43, 44, 5, 61, 62, 63, 64, 71, 72, 73, 74,
                              ].includes(parseInt(notify.type)) &&
                                notify.postType == 'question' && (
                                  <Link
                                    onClick={() =>
                                      this.read(
                                        notify._id,
                                        'like',
                                        notify.model
                                          ? `/post-detail/bookClubPost/${notify.postId}`
                                          : '/post-detail/post/' + notify.postId,
                                      )
                                    }
                                  >
                                    <img
                                      className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                      src={
                                        notify.createdBy.profilePicture
                                          ? notify.createdBy.profilePicture
                                          : '/../assets/images/user/01.jpg'
                                      }
                                      alt="user"
                                    />
                                    <span>{notify.createdBy.name}</span> Reacted on your Question
                                  </Link>
                                )}
                              {[11, 12, 13, 14].includes(parseInt(notify.type)) && notify.postType == 'question' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'like',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Liked your Question
                                </Link>
                              )}
                              {notify.type == 'comment' && notify.postType == 'post' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'comment',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Commented on your Post
                                </Link>
                              )}
                              {notify.type == 'comment' && notify.postType == 'question' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'comment',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Answered your Question
                                </Link>
                              )}
                              {notify.type == 'insight' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'comment',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId + '?type=insight',
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Insighted on your Post
                                </Link>
                              )}
                              {!notify.type && (
                                <Link
                                  onClick={() => this.read(notify._id, 'clike', '/collection-detail/' + notify.postId)}
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Reacted on your Collection
                                </Link>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <MediaQuery query="(max-width:991px)">
          <div className="menu-design-userhome ">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="logo-part">
                  <Link className="navbar-brand" to="/user-home">
                    <img src="/../assets/images/logo/logo.png" alt="B-Thrive logo" />{' '}
                  </Link>
                </div>
                <form className="form-inline serachbars my-2 my-lg-0">
                  <input
                    className="form-control"
                    type="search"
                    onKeyDown={this.onEnter}
                    onChange={this.updateInput}
                    placeholder="Search B-Thrive"
                    aria-label="Search"
                  />
                  {this.state.isBarShow ? (
                    <div className="serach-resilt">
                      <ul>
                        {this.state.isEmpty ? (
                          <li>
                            <span>No records found:</span>
                          </li>
                        ) : (
                          <div>
                            {this.state.isPostList ? (
                              <Link onClick={() => this.hitApi('post')}>
                                <li>
                                  <span>Post:</span> {this.state.searchKeyword}
                                </li>
                              </Link>
                            ) : (
                              ''
                            )}
                            {this.state.isQuestionList ? (
                              <Link onClick={() => this.hitApi('question')}>
                                <li>
                                  <span>Question:</span> {this.state.searchKeyword}
                                </li>
                              </Link>
                            ) : (
                              ''
                            )}
                            {this.state.isEventList ? (
                              <Link onClick={() => this.hitApi('event')}>
                                <li>
                                  <span>Event:</span> {this.state.searchKeyword}
                                </li>
                              </Link>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </ul>
                    </div>
                  ) : (
                    ''
                  )}
                </form>

                <Link className="nav-link noti-header" onClick={this.toggleNotification}>
                  <img
                    src="/../assets/images/icons/bell.png"
                    alt="Search User"
                    style={{
                      width: '20px',
                      marginBottom: '6px',
                      marginLeft: '-15px',
                      marginRight: '40px',
                    }}
                  />
                  <span>{this.state.notificationCount}</span>
                </Link>

                <Link className="nav-link user-profile">
                  <img
                    className={this.props.user.isBlogger ? 'blogger' : ''}
                    src={
                      this.props.user.profilePicture ? this.props.user.profilePicture : '/../assets/images/user/01.jpg'
                    }
                    alt="User Profile"
                  />
                </Link>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={toggleDropDown}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul className="navbar-nav mr-auto" style={{ display: 'none' }}>
                    <li className="nav-item">
                      {' '}
                      <Link className="nav-link search-user" to="/blogger-list">
                        <img src="/../assets/images/icons/searchuser.png" alt="Search User" />
                      </Link>
                    </li>
                    <li className="nav-item ">
                      {' '}
                      <Link className="nav-link user-profile">
                        <img
                          className={this.props.user.isBlogger ? 'blogger' : ''}
                          src={
                            this.props.user.profilePicture
                              ? this.props.user.profilePicture
                              : '/../assets/images/user/01.jpg'
                          }
                          alt="User Profile"
                        />{' '}
                        <span>{this.props.user.name}</span>
                        <div className="user-profile-bar-2">
                          <ul>
                            <li>
                              <Link to="/user-profile">
                                <i className="fa fa-user"></i> My Profile
                              </Link>
                            </li>
                            <li>
                              <Link to="/edit-profile">
                                <i className="fa fa-edit"></i> Edit Profile
                              </Link>
                            </li>
                            {!(this.props.user.registeredWithGoogle || this.props.user.registeredWithLinkedin) && (
                              <li>
                                <Link to="/edit-password">
                                  <i className="fa fa-lock"></i> Edit Password
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link to="/signOut">
                                <i className="fa fa-sign-out"></i> Logout
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className={this.state.notification ? 'notify-section active' : 'notify-section'}>
                  <Link onClick={this.toggleNotification} className="closeicon">
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="notification-list">
                    <div className="top-section">
                      <h3>
                        Notifications
                        <Link className="readallright" onClick={this.readAll}>
                          <img src="/../assets/images/icons/readall.png" alt="Read All" />
                        </Link>
                      </h3>
                    </div>
                    <div className="list-part">
                      <ul>
                        {this.state.notificationList.map((notify, index) => {
                          return (
                            <li key={index} className={notify.isRead ? 'list1' : 'list'}>
                              {[
                                21, 22, 23, 24, 31, 32, 33, 34, 41, 42, 43, 44, 5, 61, 62, 63, 64, 71, 72, 73, 74,
                              ].includes(parseInt(notify.type)) &&
                                notify.postType == 'post' && (
                                  <Link
                                    onClick={() =>
                                      this.read(
                                        notify._id,
                                        'like',
                                        notify.model
                                          ? `/post-detail/bookClubPost/${notify.postId}`
                                          : '/post-detail/post/' + notify.postId,
                                      )
                                    }
                                  >
                                    <img
                                      className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                      src={
                                        notify.createdBy.profilePicture
                                          ? notify.createdBy.profilePicture
                                          : '/../assets/images/user/01.jpg'
                                      }
                                      alt="user"
                                    />
                                    <span>{notify.createdBy.name}</span> Reacted on your Post
                                  </Link>
                                )}
                              {[11, 12, 13, 14].includes(parseInt(notify.type)) && notify.postType == 'post' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'like',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Liked your Post
                                </Link>
                              )}
                              {[
                                21, 22, 23, 24, 31, 32, 33, 34, 41, 42, 43, 44, 5, 61, 62, 63, 64, 71, 72, 73, 74,
                              ].includes(parseInt(notify.type)) &&
                                notify.postType == 'question' && (
                                  <Link
                                    onClick={() =>
                                      this.read(
                                        notify._id,
                                        'like',
                                        notify.model
                                          ? `/post-detail/bookClubPost/${notify.postId}`
                                          : '/post-detail/post/' + notify.postId,
                                      )
                                    }
                                  >
                                    <img
                                      className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                      src={
                                        notify.createdBy.profilePicture
                                          ? notify.createdBy.profilePicture
                                          : '/../assets/images/user/01.jpg'
                                      }
                                      alt="user"
                                    />
                                    <span>{notify.createdBy.name}</span> Reacted on your Question
                                  </Link>
                                )}
                              {[11, 12, 13, 14].includes(parseInt(notify.type)) && notify.postType == 'question' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'like',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Liked your Question
                                </Link>
                              )}
                              {notify.type == 'comment' && notify.postType == 'post' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'comment',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Commented on your Post
                                </Link>
                              )}
                              {notify.type == 'comment' && notify.postType == 'question' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'comment',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId,
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Answered your Question
                                </Link>
                              )}
                              {notify.type == 'insight' && (
                                <Link
                                  onClick={() =>
                                    this.read(
                                      notify._id,
                                      'comment',
                                      notify.model
                                        ? `/post-detail/bookClubPost/${notify.postId}`
                                        : '/post-detail/post/' + notify.postId + '?type=insight',
                                    )
                                  }
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Insighted on your Post
                                </Link>
                              )}
                              {!notify.type && (
                                <Link
                                  onClick={() => this.read(notify._id, 'clike', '/collection-detail/' + notify.postId)}
                                >
                                  <img
                                    className={notify.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      notify.createdBy.profilePicture
                                        ? notify.createdBy.profilePicture
                                        : '/../assets/images/user/01.jpg'
                                    }
                                    alt="user"
                                  />
                                  <span>{notify.createdBy.name}</span> Reacted on your Collection
                                </Link>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          {this.state.show && (
            <div className="user-profile-bar-2">
              <ul>
                <li style={{ marginBottom: '-10px' }}>
                  <Link className="nav-link search-user" to="/blogger-list">
                    <img src="/../assets/images/icons/searchuser.png" alt="Search User" /> Partners
                  </Link>
                </li>
                <li>
                  <Link to="/user-profile">
                    <i className="fa fa-user"></i> My Profile
                  </Link>
                </li>
                <li>
                  <Link to="/edit-profile">
                    <i className="fa fa-edit"></i> Edit Profile
                  </Link>
                </li>
                {!(this.props.user.registeredWithGoogle || this.props.user.registeredWithLinkedin) && (
                  <li>
                    <Link to="/edit-password">
                      <i className="fa fa-lock"></i> Edit Password
                    </Link>
                  </li>
                )}
                <li>
                  <Link onClick={this.contactModalShowHide}>
                    <i className="fa fa-address-book"></i>Contact Support
                  </Link>
                </li>
                <li>
                  <Link onClick={this.handleModalShowHide}>
                    <i className="fa fa-comments"></i>Give Feedback
                  </Link>
                </li>
                <li>
                  <Link to="/signOut">
                    <i className="fa fa-sign-out"></i> Logout
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <Modal show={this.state.contactshowHide} className="addcollection-modal ">
            <Modal.Body>
              <Link className="closeicon" onClick={() => this.contactModalShowHide()}>
                <i className="fa fa-close"></i>
              </Link>
              <div className="dashboard-contactus-section">
                <div className="top-section text-center">
                  <h3>Contact Us</h3>
                  <p>Kindly fill the detail below</p>
                </div>
                <div className="form-section wow bounceIn">
                  <form>
                    {this.renderInput('name', 'Name', 'Enter Name')}
                    {this.renderInput('email', 'Email', 'Enter Email')}
                    {this.renderTextArea('message', 'Message')}
                    {this.renderButton('Submit', this.handleSubmit)}
                  </form>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal show={this.state.showHide} className="addcollection-modal">
            <Modal.Body>
              <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                <i className="fa fa-close"></i>
              </Link>
              <Feedbacksupport onSubmit={this.handleModalShowHide} />
            </Modal.Body>
          </Modal>
        </MediaQuery>
      </>
    );
  }
}

export default UserHeader;
