import React from 'react';
import { Link } from 'react-router-dom';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
import * as registerService from './../services/registerServices';

class ForgotPasswordPage extends Form {
  state = {
    formData: {
      email: '',
    },
    errors: {},
  };
  schema = {
    email: Joi.string()
      .email({ tlds: { allow: ['com', 'net'] } })
      .required(),
  };
  doSubmit = async () => {
    const { data, error } = await registerService.otpGeneration(this.state.formData.email);
    if (data) {
      this.props.history.push({
        pathname: `/forgotPassword/otpVerification`,
        email: this.state.formData.email,
      });
    }
    if (error) {
      return;
    }
  };

  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="loginpage">
        <div className="container-fluid login-header">
          <Header {...this.props} />
        </div>
        <div className="login-section ">
          <div className="container">
            <div className="top-section text-center">
              <Link to="#">
                <img src="assets/images/icons/login.png" alt="Login logo" />{' '}
              </Link>
              <h3>Reset Your Password</h3>
              <p>
                When you click the button, a verification code will be sent to <br></br>you via email
              </p>
            </div>
            <div className="form-section wow bounceIn">
              <form>
                {this.renderInput('email', 'Email Address', 'Enter Email Address')}
                {this.renderButton('Send', this.handleSubmit)}
              </form>
            </div>
            <div className="login-footer text-center mt-5">
              <p>
                New to B-Thrive? <Link to="/registration">Create An Account</Link>
              </p>
              <p>
                Already got a account? <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default ForgotPasswordPage;
