import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
class Cardsview extends Component {
  state = {};
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="cards-view-part wow fadeInUp">
        <div className="main-section">
          <div className="row">
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Total Number of Users</p>
                <h3>{this.props.user.usersCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>New Signups</p>
                <h3>{this.props.user.usersCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>No of Logins</p>
                <h3>{this.props.user.loggedInCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>New Posts</p>
                <h3>{this.props.user.postCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Comments</p>
                <h3>{this.props.user.commentCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Questions</p>
                <h3>{this.props.user.questionCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Collections</p>
                <h3>{this.props.user.collectionCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Reactions</p>
                <h3>{this.props.user.reactionCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Event Host Request</p>
                <h3>{this.props.user.eventHostCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Event Attendees</p>
                <h3>{this.props.user.eventAttendeesCount}</h3>
              </div>
            </div>
            {/* <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Average Time Spent</p>
                <h3>{this.props.user.avgTime} min</h3>
              </div>
            </div> */}
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Reported Posts</p>
                <h3>{this.props.user.postsReportedCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Blogger Profilest</p>
                <h3>{this.props.user.bloggersCount}</h3>
              </div>
            </div>
            <div className="col-4">
              <div className="cards-part wow fadeInUp">
                <p>Deactivated Account</p>
                <h3>{this.props.user.deactivatedAccountCount}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Cardsview;
