import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
class BannerSection extends Component {
  state = {
    email: '',
  };
  handleChange = (e) => {
    this.setState({ email: e.target.value });
  };
  ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };
  getStarted = async () => {
    if (!this.state.email) {
      toast.error('Please enter email');
      return;
    }
    if (!this.ValidateEmail(this.state.email)) {
      toast.error('Invalid Email');
      return;
    }
    try {
      const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/getStart`, {
        email: this.state.email,
      });
      if (data) {
        return await this.props.history.push({
          pathname: '/registration',
          state: this.state.email,
        });
      }
    } catch (error) {
      toast.error(error.message);
      // return;
    }
  };
  render() {
    return (
      <div className="banner-section about-us-banner">
        <div className="container">
          <div className="main-section">
            <div className="heading-section text-center">
              <h1 className="wow fadeInUp">A COMMUNITY FOCUSED ON UPWARD MOBILITY FOR ALL BLACK PEOPLE</h1>
              <p className="wow fadeInUp">
                In the business of establishing cultural change, B-Thrive is a visionary tech company committed to
                uniting and inspiring people of the African diaspora to reclaim and embody our excellence. Our cultural
                revolution will be televised.
              </p>
            </div>
            <div className="notification-section wow fadeInUp">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email address"
                  name="email"
                  onChange={this.handleChange}
                ></input>
                <Link onClick={this.getStarted}>
                  <button type="button" className="btn btn-default">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BannerSection);
