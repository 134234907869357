import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/userheader';
import WOW from 'wowjs';
import Sidebar from './../commonComponents/sidebar';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import httpService from './../services/httpService';
import { toast } from 'react-toastify';
import SpacesSection from './spacesSection/spacesSection';
import SpacesRightside from './spacesSection/spacesRightside';
import Contactus from '../commonComponents/contactus';
import MediaQuery from 'react-responsive';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class SpacesPage extends Component {
  state = {
    postDetail: '',
    user: '',
    interests: [],
    numberOfInterest: 0,
  };
  componentDidMount = async () => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/myData`);
      if (data) {
        const user = data.user;
        this.setState({ user: user });
        this.trendingInterest();

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  async trendingInterest() {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/trendingInterest`);
      if (data) {
        const interests = data.interests;
        this.setState({ interests: interests });
        this.finalInterestList();
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  handleSpace = (id) => {
    this.addInterestApi(id);
  };

  removeInterest = () => {
    this.componentDidMount();
  };

  addInterestApi = async (id) => {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/addInterestInUser/${id}`);
      if (data) {
        this.componentDidMount();
        this.setState({ numberOfInterest: this.state.numberOfInterest + 1 });
        toast.success('Interest Added Successfully');

        return;
      }
    } catch (error) {
      //toast.error(error.message);
      return;
    }
  };

  finalInterestList = () => {
    if (this.state.user && this.state.interests) {
      const list1 = this.state.interests;
      const list2 = this.state.user.interest;
      var list = list1.filter((item1) => !list2.some((item2) => item2._id === item1._id && item2.name === item1.name));
      this.setState({ interests: list });
    }
  };
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }

  render() {
    return (
      <>
        <div className="userhomepage">
          <div className="container-fluid menu-parts wow slideInDown">
            <Header user={this.state.user} {...this.props} />
          </div>
          <div className="container-fluid user-activity">
            <div className="container">
              <Contactus />
              <div className="row">
                <div className="col-2  ">
                  <Sidebar {...this.props} />
                </div>
                <div className="col-8 wow bounceIn">
                  <SpacesSection
                    user={this.state.user}
                    numberOfInterest={this.state.numberOfInterest}
                    onRemoveSpace={this.removeInterest}
                  />
                </div>
                <div className="col-2 ">
                  <SpacesRightside interests={this.state.interests} onAddSpace={this.handleSpace} />
                </div>
              </div>
            </div>
          </div>
          <ScrollUpButton />
        </div>

        <MediaQuery query="(max-width:767px)">
          <div className="userhomepage1">
            <div className="container-fluid menu-parts wow slideInDown">
              <Header user={this.state.user} {...this.props} />
            </div>
            <div className=" user-activity">
              <div className="">
                {/* <Contactus /> */}
                <div className="row">
                  <div className="col-2  ">
                    <Sidebar {...this.props} />
                  </div>
                  <Modal show={this.state.showHide} className="upcommingevent-modal ">
                    <Modal.Body>
                      <Link className="closeicon" onClick={() => this.handleModalShowHide()}>
                        <i className="fa fa-close"></i>
                      </Link>
                      <SpacesRightside interests={this.state.interests} onAddSpace={this.handleSpace} />
                    </Modal.Body>
                  </Modal>

                  <div className="col-12 wow bounceIn" style={{ marginTop: '60px' }}>
                    <SpacesSection
                      user={this.state.user}
                      numberOfInterest={this.state.numberOfInterest}
                      onRemoveSpace={this.removeInterest}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="plus">
              <p className="plus-btn" onClick={() => this.handleModalShowHide()}>
                <i className="fa fa-plus "></i>
              </p>
            </div>
            <ScrollUpButton />
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default SpacesPage;
