import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const TermsAndConditionsModal = ({ show, handleClose, handleAccept }) => {
  const [accepted, setAccepted] = useState(false);

  const handleCheckboxChange = () => {
    setAccepted(!accepted);
  };

  const handleConfirm = () => {
    if (accepted) {
      handleAccept();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header >
        <Modal.Title>Community Guidelines</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please read and accept the community guidelines before continuing.</p>
      
        <p>You are entering a safe space and platform for the Black Community.
         We operate under the values of <strong>Mutual Respect, Care,</strong> and <strong>Acceptance</strong>  of each other.
         Thus we condemn any form of bullying, humiliation, and harassment on this platform. 
         We are here to learn, educate each other, heal, and celebrate our heritage. 
         Therefore we welcome the diversity of opinions and discussions while making sure to keep this safe environment alive.
        By clicking the box below, you are approving the described values above and agreeing to act with respect for your community members.</p>
        <Form.Check
          type="checkbox"
          label="I accept the community guidelines"
          checked={accepted}
          onChange={handleCheckboxChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" disabled={!accepted}  onClick={handleConfirm}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditionsModal;
