import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import ReadMoreAndLess from 'react-read-more-less';
import * as postService from '../../services/postServices';
import { Tab, Tabs, TabList } from 'react-tabs';
import { Modal } from 'react-bootstrap';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
import MediaQuery from 'react-responsive';
import Prince1 from '../../assets/images/icons/Prince1.png';
import Prince2 from '../../assets/images/icons/Prince2.png';
import Prince3 from '../../assets/images/icons/Prince3.png';
import Prince4 from '../../assets/images/icons/Prince4.png';
import Heart1 from '../../assets/images/icons/Heart1.png';

class CollectionListSection extends Component {
  state = {
    formData1: {
      type: '',
      postId: '',
    },
    collectionLikeList: [],
    collectionLikeCount: '',
    postId: '',
    deleteId: '',
    ikeshowHide: false,
    likeshowHide2: false,
    likeshowHide3: false,
    likeshowHide4: false,
    likeshowHide5: false,
    likeshowHide7: false,
  };
  async hitcollectionLikeList(postId, type) {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/collectionLikeList?id=${postId}&type=${type}`,
      );

      if (data) {
        const collectionLikeList = data.collectionLikeList;
        this.setState({ collectionLikeList: collectionLikeList });
        this.setState({ collectionLikeCount: data.likes });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  likePopup = () => {
    this.setState({ likeshowHide: !this.state.likeshowHide });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide: false,
      });
    }, 6000);
  };
  likePopup2 = () => {
    this.setState({ likeshowHide2: !this.state.likeshowHide2 });
    this.setState({
      likeshowHide: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide2: false,
      });
    }, 6000);
  };
  likePopup3 = () => {
    this.setState({ likeshowHide3: !this.state.likeshowHide3 });
    this.setState({
      likeshowHide2: false,
      likeshowHide: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide3: false,
      });
    }, 6000);
  };
  likePopup4 = () => {
    this.setState({ likeshowHide4: !this.state.likeshowHide4 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide4: false,
      });
    }, 6000);
  };
  likePopup5 = () => {
    this.setState({ likeshowHide5: !this.state.likeshowHide5 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide5: false,
      });
    }, 6000);
  };
  likePopup7 = () => {
    this.setState({ likeshowHide7: !this.state.likeshowHide7 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide7: false,
      });
    }, 6000);
  };

  async hitLikeApi(postId, likeType, index) {
    if (window.location.href.includes('admin')) return;
    this.state.formData1.type = likeType;
    this.state.formData1.postId = postId;
    const { data, error } = await postService.createCollectionLike(this.state.formData1);
    if (error) {
      // this.loader('none');
      return;
    } else {
      if (data.isLike == 1) {
        this.props.onLikeChange(likeType, index, 1);
      } else {
        this.props.onLikeChange(likeType, index, 0);
      }
      // alert('Liked successfully')

      return;
    }
  }
  emojiModalShowHide(postId = '', type = '') {
    if (postId) {
      this.setState({ postId: postId });
      this.hitcollectionLikeList(postId, (type = ''));
    }
    if (type) {
      if (type == 'all') type = '';
      this.hitcollectionLikeList(this.state.postId, type);
    } else {
      this.setState({ emojishowHide: !this.state.emojishowHide });
    }
  }
  nFormatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
  };

  deleteCollection = async () => {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/deleteCollection/${this.state.deleteId}`,
      );

      if (data) {
        toast.success(data.message);
        this.deleteModalShowHide('');
        this.props.onDelete();
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  deleteModalShowHide = (id) => {
    this.setState({ deleteId: id });
    this.setState({ deleteShowHide: !this.state.deleteShowHide });
  };

  render() {
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank">
        {text}
      </a>
    );
    return (
      <>
        <div className="collection-section mt-3">
          <div className="collection-display-section ">
            <div className="main-section">
              {this.props.listData.length ? (
                this.props.listData.map((data, index) => {
                  return (
                    <div className="post-block mb-3 wow fadeInUp" key={index}>
                      <div className="row">
                        <div className="col-4">
                          <Link
                            to={
                              window.location.href.includes('admin')
                                ? '/admin/collection-detail/' + data._id
                                : '/collection-detail/' + data._id
                            }
                          >
                            <div
                              className="collection-image-part collback"
                              style={{
                                background: `url(${data.image})`,
                              }}
                            ></div>
                          </Link>
                        </div>
                        <div className="col-8">
                          <div className="collection-content">
                            <div className="collection-toppart">
                              <div className="left-part">
                                <Link
                                  className="nav-link"
                                  to={
                                    window.location.href.includes('admin')
                                      ? '/admin/collection-detail/' + data._id
                                      : '/collection-detail/' + data._id
                                  }
                                >
                                  <p>{data.name}</p>
                                </Link>
                              </div>
                              <div className="right-part d-flex">
                                <Link
                                  className="smile-icon"
                                  to={
                                    window.location.href.includes('admin')
                                      ? '/admin/collection-detail/' + data._id
                                      : '/collection-detail/' + data._id
                                  }
                                >
                                  {' '}
                                  <p className="back">
                                    <i className="fa fa-database"></i>{' '}
                                    {this.nFormatter(
                                      data.bookClubPost
                                        ? data.post.length + data.bookClubPost?.length
                                        : data.post.length,
                                    )}{' '}
                                    posts
                                  </p>
                                </Link>
                                {!window.location.href.includes('admin') &&
                                this.props.user._id == data.createdBy._id ? (
                                  <Link className="dotsbar" to="#">
                                    <i className="fa fa-ellipsis-v"></i>
                                    <div className="post-dot-option">
                                      <ul>
                                        <li>
                                          <Link onClick={() => this.deleteModalShowHide(data._id)}>
                                            <i className="fa fa-trash"></i> Delete Collection
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </Link>
                                ) : null}
                              </div>
                            </div>
                            <div className="messge-box">
                              <Link
                                to={
                                  window.location.href.includes('admin')
                                    ? '/admin/collection-detail/' + data._id
                                    : '/collection-detail/' + data._id
                                }
                              >
                                <ReadMoreAndLess
                                  ref={this.ReadMore}
                                  className="read-more-content"
                                  charLimit={350}
                                  readMoreText="Read more"
                                  readLessText="Read less"
                                >
                                  {data.description}
                                </ReadMoreAndLess>
                              </Link>
                            </div>
                            <div className="post-toppart">
                              <div className="left-part">
                                <Link
                                  className="nav-link"
                                  to={
                                    !window.location.href.includes('admin')
                                      ? this.props.user._id != data.createdBy._id
                                        ? `/blogger-profile/${data.createdBy._id}`
                                        : `/user-profile`
                                      : `/admin/user-profile-detail/${data.createdBy._id}`
                                  }
                                >
                                  <img
                                    className={data.createdBy.isBlogger ? 'blogger' : ''}
                                    src={
                                      data.createdBy.profilePicture
                                        ? data.createdBy.profilePicture
                                        : '../../assets/images/user/01.jpg'
                                    }
                                    alt="User Profile"
                                  />
                                  <p>
                                    {data.createdBy.name}
                                    {data.createdBy.isBlogger ? (
                                      <span style={{ fontSize: '13px' }}>
                                        {' '}
                                        <i
                                          className="fa fa-circle ml-1 mr-2"
                                          style={{
                                            fontSize: '8px',
                                            color: 'black',
                                          }}
                                        ></i>
                                        Blogger
                                      </span>
                                    ) : null}
                                  </p>{' '}
                                  <span>Posted : {data.creationDate}</span>
                                </Link>
                              </div>
                              <div className="right-part">
                                <div className="liked-user">
                                  <Link onClick={() => this.emojiModalShowHide(data._id)}>
                                    {data.likeList.length
                                      ? `Liked by ${data.likeList[data.likeList.length - 1].createdBy.name} ${
                                          data.likeList.length > 1 ? `and others` : ''
                                        } `
                                      : ''}
                                  </Link>
                                </div>
                                <div className="reactionpart">
                                  <Link onClick={() => this.emojiModalShowHide(data._id)}>
                                    {data.my_like.length ? (
                                      data.my_like[0].type == '11' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Thumbsup1.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '12' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Thumbsup2.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '13' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Thumbsup3.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '14' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Thumbsup4.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '21' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Handraise1.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '22' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Handraise2.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '23' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Handraise3.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '24' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Handraise4.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '31' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Raised-fist1.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '32' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Raised-fist2.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '33' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Raised-fist3.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '34' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Raised-fist4.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '41' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Folded-hand1.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '42' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Folded-hand2.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '43' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Folded-hand3.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '44' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Folded-hand4.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '61' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Princess1.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '62' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Princess2.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '63' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Princess3.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '64' ? (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            src="/../assets/images/icons/Princess4.png"
                                          />
                                        </span>
                                      ) : data.my_like[0].type == '71' ? (
                                        <span>
                                          {' '}
                                          <img className="emoji-list-icon3" src={Prince1} />
                                        </span>
                                      ) : data.my_like[0].type == '72' ? (
                                        <span>
                                          {' '}
                                          <img className="emoji-list-icon3" src={Prince2} />
                                        </span>
                                      ) : data.my_like[0].type == '73' ? (
                                        <span>
                                          {' '}
                                          <img className="emoji-list-icon3" src={Prince3} />
                                        </span>
                                      ) : data.my_like[0].type == '74' ? (
                                        <span>
                                          {' '}
                                          <img className="emoji-list-icon3" src={Prince4} />
                                        </span>
                                      ) : (
                                        <span>
                                          {' '}
                                          <img
                                            className="emoji-list-icon3"
                                            style={{
                                              fontSize: '25px!important',
                                            }}
                                            src={Heart1}
                                          />
                                        </span>
                                      )
                                    ) : (
                                      <span>
                                        {' '}
                                        <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                                      </span>
                                    )}{' '}
                                  </Link>
                                  {!window.location.href.includes('admin') && (
                                    <div className="emoji-list">
                                      <ul>
                                        <li className="dropUp" onClick={() => this.likePopup()}>
                                          <Link>
                                            <img
                                              className="emoji-list-icon"
                                              src="/../assets/images/icons/Thumbsup1.png"
                                            />

                                            <div
                                              className={this.state.likeshowHide ? 'emoji-list_active' : 'emoji-list2'}
                                            >
                                              <ul>
                                                <li className="dropUp">
                                                  <div className="d-flex">
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 11, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon "
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-30px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Thumbsup1.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 12, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-24px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Thumbsup2.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 13, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-20px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Thumbsup3.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 14, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-16px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Thumbsup4.png"
                                                      />
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Link>
                                        </li>

                                        <li className="dropUp3" onClick={() => this.likePopup2()}>
                                          <Link>
                                            <img
                                              className="emoji-list-icon"
                                              src="/../assets/images/icons/Handraise1.png"
                                            />

                                            <div
                                              className={
                                                this.state.likeshowHide2 ? 'emoji-list3_active' : 'emoji-list3'
                                              }
                                            >
                                              <ul>
                                                <li className="dropUp">
                                                  <div className="d-flex">
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 21, index)}
                                                      className="mr-3 ml-4"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-30px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Handraise1.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 22, index)}
                                                      className="mr-3"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-40px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Handraise2.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 23, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-50px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Handraise3.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 24, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-45px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Handraise4.png"
                                                      />
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Link>
                                        </li>
                                        <li className="dropUp4" onClick={() => this.likePopup3()}>
                                          <Link>
                                            <img
                                              className="emoji-list-icon"
                                              src="/../assets/images/icons/Raised-fist1.png"
                                            />
                                            <div
                                              className={
                                                this.state.likeshowHide3 ? 'emoji-list4_active' : 'emoji-list4'
                                              }
                                            >
                                              <ul>
                                                <li className="dropUp">
                                                  <div className="d-flex">
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 31, index)}
                                                      className="mr-3 ml-4"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-30px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Raised-fist1.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 32, index)}
                                                      className="mr-3"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-40px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Raised-fist2.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 33, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-50px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Raised-fist3.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 34, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-45px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Raised-fist4.png"
                                                      />
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Link>
                                        </li>
                                        <li className="dropUp5" onClick={() => this.likePopup4()}>
                                          <Link>
                                            <img
                                              className="emoji-list-icon"
                                              src="/../assets/images/icons/Folded-hand1.png"
                                            />

                                            <div
                                              className={
                                                this.state.likeshowHide4 ? 'emoji-list5_active' : 'emoji-list5'
                                              }
                                            >
                                              <ul>
                                                <li className="dropUp">
                                                  <div className="d-flex">
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 41, index)}
                                                      className="mr-3 ml-4"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-30px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Folded-hand1.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 42, index)}
                                                      className="mr-3"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-40px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Folded-hand2.png"
                                                      />
                                                    </Link>

                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 43, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-50px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Folded-hand3.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 44, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-45px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Folded-hand4.png"
                                                      />
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Link>
                                        </li>
                                        <li className="dropUp">
                                          <Link onClick={() => this.hitLikeApi(data._id, 5, index)}>
                                            <img
                                              className="emoji-list-icon"
                                              style={{
                                                fontSize: '25px!important',
                                              }}
                                              src={Heart1}
                                            />
                                          </Link>
                                        </li>
                                        <li className="dropUp6" onClick={() => this.likePopup5()}>
                                          <Link>
                                            <img
                                              className="emoji-list-icon"
                                              src="/../assets/images/icons/Princess1.png"
                                            />

                                            <div
                                              className={
                                                this.state.likeshowHide5 ? 'emoji-list6_active' : 'emoji-list6'
                                              }
                                            >
                                              <ul>
                                                <li className="dropUp">
                                                  <div className="d-flex">
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 61, index)}
                                                      className="mr-3 ml-4"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-30px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Princess1.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 62, index)}
                                                      className="mr-3"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-40px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Princess2.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 63, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-50px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Princess3.png"
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 64, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-45px',
                                                          top: '-8px',
                                                        }}
                                                        src="/../assets/images/icons/Princess4.png"
                                                      />
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Link>
                                        </li>
                                        <li className="dropUp7" onClick={() => this.likePopup7()}>
                                          <Link>
                                            <img className="emoji-list-icon" src={Prince1} />
                                            <div
                                              className={
                                                this.state.likeshowHide7 ? 'emoji-list7_active' : 'emoji-list7'
                                              }
                                            >
                                              <ul>
                                                <li className="dropUp">
                                                  <div className="d-flex">
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 71, index)}
                                                      className="mr-3 ml-4"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-30px',
                                                          top: '-8px',
                                                        }}
                                                        src={Prince1}
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 72, index)}
                                                      className="mr-3"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-40px',
                                                          top: '-8px',
                                                        }}
                                                        src={Prince2}
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 73, index)}
                                                      className="mr-3"
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-50px',
                                                          top: '-8px',
                                                        }}
                                                        src={Prince3}
                                                      />
                                                    </Link>
                                                    <Link
                                                      onClick={() => this.hitLikeApi(data._id, 74, index)}
                                                      className=""
                                                    >
                                                      <img
                                                        className="emoji-list-icon"
                                                        style={{
                                                          position: 'absolute',
                                                          left: '-60px',
                                                          top: '-8px',
                                                        }}
                                                        src={Prince4}
                                                      />
                                                    </Link>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h1 className="no-data-found">No Collection Made Yet</h1>
                </div>
              )}
              <Modal show={this.state.emojishowHide} className="emoji-modal ">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="collection">
                    <h4 className="">Reactions </h4>
                    <div className="reaction-parts">
                      <Tabs>
                        <TabList id="style-1">
                          <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                            All {this.nFormatter(this.state.collectionLikeCount.total_likes)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                            </span>{' '}
                            {this.nFormatter(this.state.collectionLikeCount.total_likes_1)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.collectionLikeCount.total_likes_2)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Raised-fist1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.collectionLikeCount.total_likes_3)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Folded-hand1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.collectionLikeCount.total_likes_4)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                            <span>
                              <img className="emoji-list-icon2" src={Heart1} />
                            </span>{' '}
                            {this.nFormatter(this.state.collectionLikeCount.total_likes_5)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.collectionLikeCount.total_likes_6)}
                          </Tab>
                          <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                            <span>
                              {' '}
                              <img className="emoji-list-icon2" src={Prince1} />{' '}
                            </span>{' '}
                            {this.nFormatter(this.state.collectionLikeCount.total_likes_7)}
                          </Tab>
                        </TabList>
                      </Tabs>
                      <div className="likeuser-list">
                        <ul>
                          {this.state.collectionLikeList.length
                            ? this.state.collectionLikeList.map((data, index) => {
                                return (
                                  <li>
                                    <Link
                                      to={
                                        !window.location.href.includes('admin')
                                          ? this.props.user._id != data.createdBy._id
                                            ? `/blogger-profile/${data.createdBy._id}`
                                            : `/user-profile`
                                          : '#'
                                      }
                                    >
                                      <h5>
                                        {data.createdBy.name}
                                        {data.createdBy.isBlogger ? (
                                          <span style={{ fontSize: '13px' }}>
                                            {' '}
                                            <i
                                              className="fa fa-circle ml-1 mr-2"
                                              style={{
                                                fontSize: '8px',
                                                color: 'black',
                                              }}
                                            ></i>
                                            Blogger
                                          </span>
                                        ) : null}
                                      </h5>
                                      {data.createdBy.location ? (
                                        <span>{data.createdBy.location}</span>
                                      ) : (
                                        <span style={{ color: 'white' }}>Noida</span>
                                      )}
                                      <img
                                        className={data.createdBy.isBlogger ? 'blogger' : ''}
                                        src={
                                          data.createdBy.profilePicture
                                            ? data.createdBy.profilePicture
                                            : '../../assets/images/user/01.jpg'
                                        }
                                        alt="User Profile"
                                      />
                                    </Link>
                                  </li>
                                );
                              })
                            : ''}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal show={this.state.deleteShowHide} className="addcollection-modal">
                <Modal.Body>
                  <Link className="closeicon" onClick={() => this.deleteModalShowHide()}>
                    <i className="fa fa-close"></i>
                  </Link>
                  <div className="dashboard-contactus-section">
                    <div className="top-section text-center">
                      <h3>Delete Collection</h3>
                      <p>Are you sure, You Want to delete this Collection?</p>
                    </div>
                    <div className="d-flex new-btn justify-content-center">
                      <div className="submit-buttons delete_btn" style={{ marginRight: '15px' }}>
                        <Link
                          type="button"
                          className=""
                          style={{ color: 'black' }}
                          onClick={() => this.deleteCollection()}
                        >
                          Yes
                        </Link>
                      </div>
                      <div className="submit-buttons delete_btn" style={{ marginLeft: '15px' }}>
                        <Link type="button" style={{ color: 'black' }} onClick={() => this.deleteModalShowHide()}>
                          No
                        </Link>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="collection-section mt-3">
            <div className="collection-display-section1 ">
              <div className="main-section">
                {this.props.listData.length ? (
                  this.props.listData.map((data, index) => {
                    return (
                      <div className="post-block mb-3 wow fadeInUp" key={index}>
                        <div className="row">
                          <div className="col-5">
                            <Link
                              to={
                                window.location.href.includes('admin')
                                  ? '/admin/collection-detail/' + data._id
                                  : '/collection-detail/' + data._id
                              }
                            >
                              <div
                                className="collection-image-part collback"
                                style={{
                                  background: `url(${data.image})`,
                                }}
                              ></div>
                            </Link>
                          </div>
                          <div className="col-7">
                            <div className="collection-content">
                              <div className="collection-toppart">
                                <div className="right-part">
                                  <Link
                                    className="smile-icon"
                                    to={
                                      window.location.href.includes('admin')
                                        ? '/admin/collection-detail/' + data._id
                                        : '/collection-detail/' + data._id
                                    }
                                  >
                                    <p className="back">
                                      <i className="fa fa-database"></i>{' '}
                                      {this.nFormatter(
                                        data.bookClubPost
                                          ? data.post.length + data.bookClubPost?.length
                                          : data.post.length,
                                      )}{' '}
                                      posts
                                    </p>
                                  </Link>
                                  {!window.location.href.includes('admin') &&
                                  this.props.user._id == data.createdBy._id ? (
                                    <Link className="dotsbar" to="#">
                                      <i className="fa fa-ellipsis-v"></i>
                                      <div className="post-dot-option">
                                        <ul>
                                          <li>
                                            <Link onClick={() => this.deleteModalShowHide(data._id)}>
                                              <i className="fa fa-trash"></i> Delete Collection
                                            </Link>
                                          </li>
                                        </ul>
                                      </div>
                                    </Link>
                                  ) : null}
                                </div>
                                <div className="left-part">
                                  <Link
                                    className="nav-link"
                                    to={
                                      window.location.href.includes('admin')
                                        ? '/admin/collection-detail/' + data._id
                                        : '/collection-detail/' + data._id
                                    }
                                  >
                                    <p className="mt-3">{data.name}</p>
                                  </Link>
                                </div>
                              </div>
                              <div className="messge-box">
                                <Link
                                  to={
                                    window.location.href.includes('admin')
                                      ? '/admin/collection-detail/' + data._id
                                      : '/collection-detail/' + data._id
                                  }
                                >
                                  <ReadMoreAndLess
                                    ref={this.ReadMore}
                                    className="read-more-content"
                                    charLimit={200}
                                    readMoreText="Read more"
                                    readLessText="Read less"
                                  >
                                    {data.description}
                                  </ReadMoreAndLess>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="post-toppart">
                          <div className="left-part">
                            <Link
                              className="nav-link"
                              to={
                                !window.location.href.includes('admin')
                                  ? this.props.user._id != data.createdBy._id
                                    ? `/blogger-profile/${data.createdBy._id}`
                                    : `/user-profile`
                                  : `/admin/user-profile-detail/${data.createdBy._id}`
                              }
                            >
                              <img
                                className={data.createdBy.isBlogger ? 'blogger' : ''}
                                src={
                                  data.createdBy.profilePicture
                                    ? data.createdBy.profilePicture
                                    : '../../assets/images/user/01.jpg'
                                }
                                alt="User Profile"
                              />
                              <p>
                                {data.createdBy.name}
                                {data.createdBy.isBlogger ? (
                                  <span style={{ fontSize: '13px' }}>
                                    {' '}
                                    <i
                                      className="fa fa-circle ml-1 mr-2"
                                      style={{
                                        fontSize: '8px',
                                        color: 'black',
                                      }}
                                    ></i>
                                    Blogger
                                  </span>
                                ) : null}
                              </p>{' '}
                              <span>Posted : {data.creationDate}</span>
                            </Link>
                          </div>
                          <div className="right-part">
                            <div className="liked-user">
                              <Link onClick={() => this.emojiModalShowHide(data._id)}>
                                {data.likeList.length
                                  ? `Liked by ${data.likeList[data.likeList.length - 1].createdBy.name} ${
                                      data.likeList.length > 1 ? `and others` : ''
                                    } `
                                  : ''}
                              </Link>
                            </div>
                            <div className="reactionpart">
                              <Link onClick={() => this.emojiModalShowHide(data._id)}>
                                {data.my_like.length ? (
                                  data.my_like[0].type == '11' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup1.png" />
                                    </span>
                                  ) : data.my_like[0].type == '12' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup2.png" />
                                    </span>
                                  ) : data.my_like[0].type == '13' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup3.png" />
                                    </span>
                                  ) : data.my_like[0].type == '14' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup4.png" />
                                    </span>
                                  ) : data.my_like[0].type == '21' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise1.png" />
                                    </span>
                                  ) : data.my_like[0].type == '22' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise2.png" />
                                    </span>
                                  ) : data.my_like[0].type == '23' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise3.png" />
                                    </span>
                                  ) : data.my_like[0].type == '24' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise4.png" />
                                    </span>
                                  ) : data.my_like[0].type == '31' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist1.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '32' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist2.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '33' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist3.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '34' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist4.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '41' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand1.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '42' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand2.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '43' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand3.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '44' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand4.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '61' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess1.png" />
                                    </span>
                                  ) : data.my_like[0].type == '62' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess2.png" />
                                    </span>
                                  ) : data.my_like[0].type == '63' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess3.png" />
                                    </span>
                                  ) : data.my_like[0].type == '64' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess4.png" />
                                    </span>
                                  ) : data.my_like[0].type == '71' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince1} />
                                    </span>
                                  ) : data.my_like[0].type == '72' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince2} />
                                    </span>
                                  ) : data.my_like[0].type == '73' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince3} />
                                    </span>
                                  ) : data.my_like[0].type == '74' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince4} />
                                    </span>
                                  ) : (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        style={{ fontSize: '25px!important' }}
                                        src={Heart1}
                                      />
                                    </span>
                                  )
                                ) : (
                                  <span>
                                    {' '}
                                    <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                                  </span>
                                )}{' '}
                              </Link>
                              {!window.location.href.includes('admin') && (
                                <div className="emoji-list">
                                  <ul>
                                    <li className="dropUp" onClick={() => this.likePopup()}>
                                      <Link>
                                        <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup1.png" />

                                        <div className={this.state.likeshowHide ? 'emoji-list_active' : 'emoji-list2'}>
                                          <ul>
                                            <li className="dropUp">
                                              <div className="d-flex">
                                                <Link onClick={() => this.hitLikeApi(data._id, 11, index)} className="">
                                                  <img
                                                    className="emoji-list-icon "
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-30px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Thumbsup1.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 12, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-24px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Thumbsup2.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 13, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-20px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Thumbsup3.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 14, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-16px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Thumbsup4.png"
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Link>
                                    </li>

                                    <li className="dropUp3" onClick={() => this.likePopup2()}>
                                      <Link>
                                        <img className="emoji-list-icon" src="/../assets/images/icons/Handraise1.png" />

                                        <div
                                          className={this.state.likeshowHide2 ? 'emoji-list3_active' : 'emoji-list3'}
                                        >
                                          <ul>
                                            <li className="dropUp">
                                              <div className="d-flex">
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 21, index)}
                                                  className="mr-3 ml-4"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-30px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Handraise1.png"
                                                  />
                                                </Link>
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 22, index)}
                                                  className="mr-3"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-40px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Handraise2.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 23, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-50px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Handraise3.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 24, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-45px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Handraise4.png"
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Link>
                                    </li>
                                    <li className="dropUp4" onClick={() => this.likePopup3()}>
                                      <Link>
                                        <img
                                          className="emoji-list-icon"
                                          src="/../assets/images/icons/Raised-fist1.png"
                                        />
                                        <div
                                          className={this.state.likeshowHide3 ? 'emoji-list4_active' : 'emoji-list4'}
                                        >
                                          <ul>
                                            <li className="dropUp">
                                              <div className="d-flex">
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 31, index)}
                                                  className="mr-3 ml-4"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-30px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Raised-fist1.png"
                                                  />
                                                </Link>
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 32, index)}
                                                  className="mr-3"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-40px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Raised-fist2.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 33, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-50px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Raised-fist3.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 34, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-45px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Raised-fist4.png"
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Link>
                                    </li>
                                    <li className="dropUp5" onClick={() => this.likePopup4()}>
                                      <Link>
                                        <img
                                          className="emoji-list-icon"
                                          src="/../assets/images/icons/Folded-hand1.png"
                                        />

                                        <div
                                          className={this.state.likeshowHide4 ? 'emoji-list5_active' : 'emoji-list5'}
                                        >
                                          <ul>
                                            <li className="dropUp">
                                              <div className="d-flex">
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 41, index)}
                                                  className="mr-3 ml-4"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-30px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Folded-hand1.png"
                                                  />
                                                </Link>
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 42, index)}
                                                  className="mr-3"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-40px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Folded-hand2.png"
                                                  />
                                                </Link>

                                                <Link onClick={() => this.hitLikeApi(data._id, 43, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-50px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Folded-hand3.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 44, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-45px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Folded-hand4.png"
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Link>
                                    </li>
                                    <li className="dropUp">
                                      <Link onClick={() => this.hitLikeApi(data._id, 5, index)}>
                                        <img
                                          className="emoji-list-icon"
                                          style={{
                                            fontSize: '25px!important',
                                          }}
                                          src={Heart1}
                                        />
                                      </Link>
                                    </li>
                                    <li className="dropUp6" onClick={() => this.likePopup5()}>
                                      <Link>
                                        <img className="emoji-list-icon" src="/../assets/images/icons/Princess1.png" />

                                        <div
                                          className={this.state.likeshowHide5 ? 'emoji-list6_active' : 'emoji-list6'}
                                        >
                                          <ul>
                                            <li className="dropUp">
                                              <div className="d-flex">
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 61, index)}
                                                  className="mr-3 ml-4"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-30px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Princess1.png"
                                                  />
                                                </Link>
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 62, index)}
                                                  className="mr-3"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-40px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Princess2.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 63, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-50px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Princess3.png"
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 64, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-45px',
                                                      top: '-8px',
                                                    }}
                                                    src="/../assets/images/icons/Princess4.png"
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Link>
                                    </li>
                                    <li className="dropUp7" onClick={() => this.likePopup7()}>
                                      <Link>
                                        <img className="emoji-list-icon" src={Prince1} />
                                        <div
                                          className={this.state.likeshowHide7 ? 'emoji-list7_active' : 'emoji-list7'}
                                        >
                                          <ul>
                                            <li className="dropUp">
                                              <div className="d-flex">
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 71, index)}
                                                  className="mr-3 ml-4"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-30px',
                                                      top: '-8px',
                                                    }}
                                                    src={Prince1}
                                                  />
                                                </Link>
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 72, index)}
                                                  className="mr-3"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-40px',
                                                      top: '-8px',
                                                    }}
                                                    src={Prince2}
                                                  />
                                                </Link>
                                                <Link
                                                  onClick={() => this.hitLikeApi(data._id, 73, index)}
                                                  className="mr-3"
                                                >
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-50px',
                                                      top: '-8px',
                                                    }}
                                                    src={Prince3}
                                                  />
                                                </Link>
                                                <Link onClick={() => this.hitLikeApi(data._id, 74, index)} className="">
                                                  <img
                                                    className="emoji-list-icon"
                                                    style={{
                                                      position: 'absolute',
                                                      left: '-60px',
                                                      top: '-8px',
                                                    }}
                                                    src={Prince4}
                                                  />
                                                </Link>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <h1 className="no-data-found">No Collection Made Yet</h1>
                  </div>
                )}
                <Modal show={this.state.emojishowHide} className="emoji-modal ">
                  <Modal.Body>
                    <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                      <i className="fa fa-close"></i>
                    </Link>
                    <div className="collection">
                      <h4 className="">Reactions </h4>
                      <div className="reaction-parts">
                        <Tabs>
                          <TabList id="style-1">
                            <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                              All {this.nFormatter(this.state.collectionLikeCount.total_likes)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_1)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_2)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Raised-fist1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_3)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Folded-hand1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_4)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                              <span>
                                <img className="emoji-list-icon2" src={Heart1} />
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_5)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_6)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src={Prince1} />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_7)}
                            </Tab>
                          </TabList>
                        </Tabs>
                        <div className="likeuser-list">
                          <ul>
                            {this.state.collectionLikeList.length
                              ? this.state.collectionLikeList.map((data, index) => {
                                  return (
                                    <li>
                                      <Link
                                        to={
                                          !window.location.href.includes('admin')
                                            ? this.props.user._id != data.createdBy._id
                                              ? `/blogger-profile/${data.createdBy._id}`
                                              : `/user-profile`
                                            : '#'
                                        }
                                      >
                                        <h5>
                                          {data.createdBy.name}
                                          {data.createdBy.isBlogger ? (
                                            <span style={{ fontSize: '13px' }}>
                                              {' '}
                                              <i
                                                className="fa fa-circle ml-1 mr-2"
                                                style={{
                                                  fontSize: '8px',
                                                  color: 'black',
                                                }}
                                              ></i>
                                              Blogger
                                            </span>
                                          ) : null}
                                        </h5>
                                        {data.createdBy.location ? (
                                          <span>{data.createdBy.location}</span>
                                        ) : (
                                          <span style={{ color: 'white' }}>Noida</span>
                                        )}
                                        <img
                                          className={data.createdBy.isBlogger ? 'blogger' : ''}
                                          src={
                                            data.createdBy.profilePicture
                                              ? data.createdBy.profilePicture
                                              : '../../assets/images/user/01.jpg'
                                          }
                                          alt="User Profile"
                                        />
                                      </Link>
                                    </li>
                                  );
                                })
                              : ''}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default CollectionListSection;
