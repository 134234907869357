import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import BannerSection from './homePageSection/bannerSection';
import WOW from 'wowjs';
import BigIdeaSection from './homePageSection/bigIdeaSection';
import BThriveFeature from './homePageSection/bThriveFeature';
import UpCommingEvents from './homePageSection/upCommingEvents';
import CreatingNewWorld from './homePageSection/creatingNewWorld';
import JoinBthrive from './homePageSection/joinBthrive';
import Footer from './../commonComponents/footer';
import BlogsSlider from './homePageSection/blogsSlider';
class HomePage extends Component {
  state = {};
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="homepage">
        <div className="container-fluid ">
          <Header {...this.props} />
          <BannerSection />
        </div>
        <BigIdeaSection />
        <BThriveFeature />
        <BlogsSlider />
        {/* <UpCommingEvents /> */}
        <CreatingNewWorld />
        <JoinBthrive />
        <Footer />
      </div>
    );
  }
}

export default HomePage;
