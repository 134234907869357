import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import BookMarkPostquestionList from '../../../commonComponents/bookMarkPostQuestions';
class Bookmark extends Component {
  render() {
    return <BookMarkPostquestionList {...this.props} />;
  }
}

export default Bookmark;
