import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import WOW from 'wowjs';
import AdminHeader from './../commonComponents/adminheader';
import { CircleArrow as ScrollUpButton } from 'react-scroll-up-button';
import Topmenubar from '../commonComponents/topmenubar';
import EventsTabs from './pastevents/eventsTabs';

class EventsList extends Component {
  state = {};
  componentDidMount() {
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <div className="userhomepage learnwith">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Topmenubar user={this.props.user} {...this.props} />
                <div className="col-8 offset-2 wow fadeInUp">
                  <EventsTabs user={this.props.user} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <ScrollUpButton />
      </div>
    );
  }
}

export default EventsList;
