import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import ShowMoreText from 'react-show-more-text';
class About extends Component {
  render() {
    if (!this.props.user) return null;
    return (
      <div className="about-section">
        <div className="about-me">
          <h3>About Me</h3>
          <p>
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Read more"
              less="Read less"
              onClick={this.executeOnClick}
              expanded={false}
            >
              {this.props.user ? this.props.user.description : ''}
            </ShowMoreText>
          </p>
        </div>
        <div className="about-me">
          <h3>Interests</h3>
          <ul>
            {this.props.user.interest.length
              ? this.props.user.interest.map((user, index) => <li>{user ? <Link>{user.name}</Link> : null}</li>)
              : ''}
          </ul>
        </div>
      </div>
    );
  }
}

export default About;
