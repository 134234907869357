import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Modal, Button } from 'react-bootstrap';

const settings = {
  dots: false,
  infinite: false,
  speed: 300,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const teamMembers = [
  {
    id: 1,
    name: 'Biko Somuah',
    title: 'Founder & CEO, Writer',
    description: `Black empowerment is one of the topics I am very passionate about. As a conscious entrepreneur and spiritual life coach,
     building the B-Thrive platform is near and dear to my heart, because self and spiritual empowerment, creativity, mindful business, and
entrepreneurship are avenues for our overall...`,
    fullDescription: `Black empowerment is one of the topics I am very passionate about. As a conscious entrepreneur and spiritual life coach, building the B-Thrive platform is near and dear to my heart, because self and spiritual empowerment, creativity, mindful business, and
entrepreneurship are avenues for our overall growth. Founding B-Thrive is more than a "tech venture" to me. B-Thrive is a powerful platform where Black and African descendants can build community, connect with their roots and ancestral heritage and legacy, celebrate, restore and also heal from the past, and forge a path towards a bright, united future.`,
    image: 'assets/images/teams/1.jpeg',
    role: 'Founder & CEO, Writer Healing Content',
    modalTitle: 'Biko Somuah',
  },
  {
    id: 2,
    name: 'Tima Fofana',
    title: 'Marketing & Writer',
    description: `As a storyteller with an enthusiasm for all things self-empowerment, ancestral wisdom, and entrepreneurship,
     I wanted to join the B-Thrive team to empower my people. It’s time for us to take control, shift, and spread our own 
     narratives. I want my people to have a sense of pride in their heritage, a positive outlook about their future, 
     and a desire to unite as a people - to ensure we thrive together.`,
    fullDescription: `As a storyteller with an enthusiasm for all things self-empowerment, ancestral wisdom, and 
    entrepreneurship, I wanted to join the B-Thrive team to empower my people. It’s time for us to take control, shift,
     and spread our own narratives. I want my people to have a sense of pride in their heritage, a positive outlook about
      their future, and a desire to unite as a people - to ensure we thrive together.`,
    image: 'assets/images/teams/2.jpg',
    role: 'Marketing | Writer Educational & Healing Content, Mandinka with roots in Gambia and Mali',
    modalTitle: 'Tima Fofana',
  },
  {
    id: 3,
    name: 'Lindah Ahomo',
    title: 'Social Media Manager',
    description: `I am passionate about uplifting and sharing the powerful stories of the Black community. From
                  fashion to history, culture, and empowerment. I aim to highlight the brilliance of our heritage.
                  My travels have taught me the beauty of diversity and have deepened my love for my own
                  cultural heritage...`,
    fullDescription: `I am passionate about uplifting and sharing the powerful stories of the Black community. From
                      fashion to history, culture, and empowerment. I aim to highlight the brilliance of our heritage.
                      My travels have taught me the beauty of diversity and have deepened my love for my own
                      cultural heritage. Through B-Thrive I am able to bring these stories to life, uniting and
                      empowering us all to embrace who we are, celebrate our roots, and create a future that’s as rich
                      as our history.`,
    image: 'assets/images/teams/3.jpeg',
    role: 'Social Media Manager',
    modalTitle: 'Lindah Ahomo',
  },
  {
    id: 4,
    name: 'Dami',
    title: 'Community & Project Manager',
    description: `My role at B-Thrive is to help connect the community and support in showcasing the work of
content creators.My goal is building a vibrant community where black voices are celebrated and
positivity is spread. WIth a background in Project Management I am passionate about B-Thrive
being a platform that highlights outstanding projects and creativity from our community and
encouraging more content creators to join us on this journey.`,

    fullDescription: `My role at B-Thrive is to help connect the community and support in showcasing the work of
content creators.My goal is building a vibrant community where black voices are celebrated and
positivity is spread. WIth a background in Project Management I am passionate about B-Thrive
being a platform that highlights outstanding projects and creativity from our community and
encouraging more content creators to join us on this journey.`,
    image: 'assets/images/teams/4.jpeg',
    role: 'Community & Project Manager',
    modalTitle: 'Dami',
  },

  {
    id: 5,
    name: 'Esther Wambui',
    title: 'Writer & Social Media Manager',
    description: `With 8 years experience in social media managing my role at B-Thrive is to drive engagement and growth through
                 thoughtful content on Instagram and the app, supporting the platform’s
                  mission to connect the global Black community. By highlighting Black history, culture, and
                empowerment, my dedication to purpose-driven storytelling aligns with B-Thrive’s core
                message: "The Ancient ways are the key to our Future."`,

    fullDescription: `With 8 years experience in social media managing my role at B-Thrive is to drive engagement
                    and growth through thoughtful content on Instagram and the app, supporting the platform’s
                    mission to connect the global Black community. By highlighting Black history, culture, and
                    empowerment, my dedication to purpose-driven storytelling aligns with B-Thrive’s core
message: "The Ancient ways are the key to our Future."`,
    image: 'assets/images/teams/5.jpeg',
    role: 'Writer & Social Media Manager',
    modalTitle: 'Esther Wambui',
  },

];

const TeamMember = ({ member, onReadMore }) => (
  <Link className="active">
    <div className="rightpart">
      <img src={member.image} alt="team" />
      <p>
        <b>{member.name}</b> <br />
        {member.title}
      </p>
      <p className="des px-2">
        {member.description.slice(0,200)}{'...'}
        <Link className="color" onClick={onReadMore}>
          Read More
        </Link>
      </p>
    </div>
  </Link>
);

const ModalContent = ({ member, onClose }) => (
  <Modal show={!!member} onHide={onClose} className="newupdate-modal2">
    <Modal.Body>
      <div className="newupdate2">
        <h4 className="text-center">{member?.modalTitle}</h4>
        <p className="text-center">
          <b>{member?.role}</b>
        </p>
        <div className="messege-box">
          <p>{member?.fullDescription}</p>
        </div>
        <div className="submit-buttons text-center">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
);

const HowItWork = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const handleReadMore = (member) => {
    setSelectedMember(member);
  };

  const handleClose = () => {
    setSelectedMember(null);
  };

  return (
    <div className="howit-work">
      <div className="container">
        <div className="main-section">
          <div className="heading-section text-center">
            <h3 className="wow fadeInUp">Meet the Team</h3>
          </div>
          <div className="space-itemislider ourteams">
            <div className="slider-parts">
              <Slider {...settings}>
                {teamMembers.map((member) => (
                  <TeamMember key={member.id} member={member} onReadMore={() => handleReadMore(member)} />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {selectedMember && <ModalContent member={selectedMember} onClose={handleClose} />}
    </div>
  );
};

export default HowItWork;
