import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Modal } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import * as postService from './../services/postServices';
import httpService from '../services/httpService';
import AllPostQuestionList from '../commonComponents/allPostQuestionList';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { RplFetcher } from '../lib/helpers';
class Postquestion extends Component {
  state = {
    selectedTags: [],
    interestList: [],
    old_arr: [],
    description: '',
    link: '',
    web_link: '',
    file: [],
    listData: '',
    page: Number(1),
  };

  fileArray = [];
  sendFile = [];
  modalType = '';
  pageData = [];
  tabType = 'post';
  sortType = 'desc';
  totalData = '';

  componentDidMount = async () => {
    this.postAPI('post', this.state.page);
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/getInterest`);
      this.setState({ interestList: data.interests });
      this.setState({ old_arr: data.interests, isLoading: false });
    } catch (error) {
      toast.error(error.message);
      return;
    }
    window.addEventListener('scroll', this.loadMore);
    document.body.addEventListener('touchmove', this.loadMore);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
    document.body.removeEventListener('touchmove', this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  postAPI = async (name, page) => {
    //this.loader("block");
    this.setState({ isLoading: true });

    if (!page) page = 1;
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/post/${name}?page=${page}&sort=${this.sortType}`,
      );
      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.totalData = data.postList[0]?.metadata[0]?.total;
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });
        if (!window.location.href.includes('admin')) this.filterlist(this.state.listData);

        //this.loader("none");
        this.setState({ isLoading: false });
        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      this.setState({ isLoading: false });
      return;
    }
  };
  filterlist = (data) => {
    let listData = data;
    if (this.props.user) {
      for (var index = 0; index < listData.length; index++) {
        if (
          this.props.user.usersBlocked.includes(listData[index].createdBy._id) ||
          listData[index].createdBy.usersBlocked.includes(this.props.user._id)
        ) {
          listData.splice(index, 1);
          index--;
        }
      }
    }
    this.setState({ listData: listData });
  };
  onBlock = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.componentDidMount();
  };

  constructor(props) {
    super(props);
    // this.state = {
    //     file: [null]
    // }
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    // this.uploadMultipleFiles1 = this.uploadMultipleFiles1.bind(this)
    // this.upload = this.upload.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);

    // this.tags = this.tags.bind(this);
  }

  loadMore = () => {
    const { page } = this.state;
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 200 &&
      this.pageData.length !== this.totalData &&
      page * 10 <= this.totalData // Adjust this condition as needed
    ) {
      if (this.state.isLoading) return;
      const nextPage = page + 1;
      this.setState({ page: nextPage }, () => {
        this.postAPI(this.tabType, nextPage);
      });
    }
  };

  toggleLinkpart = () => {
    this.setState({ linkpart: !this.state.linkpart });
  };
  toggleWebsitepart = () => {
    this.setState({ websitepart: !this.state.websitepart });
  };
  uploadMultipleFiles(e) {
    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > 20242880) {
        toast.error('File size exceeded. Max size should be 20MB.');
        continue;
      }
      let obj = {
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        fileurl: URL.createObjectURL(e.target.files[i]),
      };
      this.fileArray.push(obj);
      this.sendFile.push(e.target.files[i]);
    }

    this.setState({ file: this.sendFile });
  }
  getExtension(name) {
    if (name) {
      return name.split('.')[name.split('.').length - 1].toLowerCase();
    }
  }

  postModalHandler(type) {
    if (type == '') {
      this.setState({ linkpart: false });
      this.setState({ websitepart: false });
    }
    this.modalType = type;
    this.fileArray = [];
    this.sendFile = [];
    this.setState({ web_link: '' });
    this.setState({ link: '' });
    this.setState({ description: '' });
    this.setState({ selectedTags: [] });
    this.setState({ file: [] });
    this.setState({ interestList: [] });
    this.setState({ showHide: !this.state.showHide });
  }

  hitAPI(tabName) {
    this.tabType = tabName;
    this.setState({
      page: Number(1),
    });
    this.pageData = [];
    if (tabName == 'question') {
      this.postAPI('question', Number(1));
    } else {
      this.postAPI('post', Number(1));
    }
  }

  onFilterChange(e) {
    this.pageData = [];
    this.setState({
      page: Number(1),
    });
    this.sortType = e.target.value;
    this.postAPI(this.tabType, Number(1), e.target.value);
  }

  onRemoveFileSelection(index) {
    this.fileArray.splice(index, 1);
    this.sendFile.splice(index, 1);
    this.setState({ file: this.sendFile });
  }

  handleInputChange(event) {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value,
    });
  }

  handleKeyPress(e) {
    let originalArr = JSON.parse(JSON.stringify(this.state.old_arr));
    if (e.target.value) {
      let interestList = this.state.old_arr.filter((item, index) => {
        if (item['name'].toLowerCase().includes(e.target.value.toLowerCase())) {
          return item;
        }
      });
      this.setState({ interestList: interestList });
    } else {
      this.setState({ interestList: [...originalArr] });
    }
  }

  tags(name) {
    if (this.state.selectedTags.indexOf(name) == -1) {
      this.state.selectedTags.push(name);
    }
  }

  removeTags(name) {
    var array = [...this.state.selectedTags]; // make a separate copy of the array
    var index = array.indexOf(name);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ selectedTags: array });
    }
  }

  isUrl(s) {
    var shortLink = /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/;
    var fullLink = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return fullLink.test(s) || shortLink.test(s);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.link) {
      let checkUrl = this.isUrl(this.state.link);
      if (!checkUrl) {
        toast.error('Link is invalid');
        return;
      }
    }
    if (this.state.web_link) {
      let checkUrlWebsite = this.isUrl(this.state.web_link);
      if (!checkUrlWebsite) {
        toast.error('Website Link is invalid');
        return;
      }
    }
    const files = this.state.file;
    if (this.state.description == '' && this.state.link == '' && files.length == 0) {
      toast.error('Please enter description');
      return;
    }
    if (files.length == 0 && this.state.description == '') {
      toast.error('Please enter description or add image');
      return;
    }
    this.loader('block');
    const interests = this.state.selectedTags;
    const formData = new FormData();
    formData.append('type', this.modalType);
    formData.append('description', this.state.description);
    formData.append('link', this.state.link);
    formData.append('web_link', this.state.web_link);

    for (let i = 0; i < interests.length; i++) {
      formData.append('interest', interests[i]);
    }
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    // const formData = {
    //   type: this.modalType,
    //   description: this.state.description,
    //   link: this.state.link,
    //   web_link: this.state.web_link,
    //   interest: interests,
    //   files: files,
    // };

    const { data, error } = await postService.createPost(formData);
    if (error) {
      this.loader('none');
      return;
    } else {
      this.hitAPI(this.modalType);
      this.setState({ showHide: !this.state.showHide });
      this.setState({ linkpart: false });
      this.setState({ websitepart: false });
      this.setState({ selectedTags: [] });
      this.setState({ link: '' });
      this.setState({ web_link: '' });
      this.fileArray = [];
      this.sendFile = [];
      this.loader('none');

      return;
    }
  };
  onEditorStateChange = (e) => {
    this.setState({ description: e.target.value });
  };

  handleLike = (likeType, index, isChange) => {
    const listData = [...this.state.listData];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.props.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }

      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.props.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.props.user.name, _id: this.props.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.props.user.name, _id: this.props.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };

  onEdit = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.componentDidMount();
  };

  render() {
    if (!(this.props.user && this.state.listData)) return null;

    return (
      <div>
        <div className="user-post-question">
          <div className="tabs-section">
            <Tabs>
              <TabList>
                <Tab onClick={() => this.hitAPI('post')}>All Post</Tab>
                <Tab onClick={() => this.hitAPI('question')}>
                  Questions <i className="fa fa-circle"></i>
                </Tab>
              </TabList>
              <TabPanel>
                <div className="postlink">
                  <Link onClick={() => this.postModalHandler('post')}>
                    <img
                      className={this.props.user.isBlogger ? 'blogger' : ''}
                      src={
                        this.props.user.profilePicture
                          ? this.props.user.profilePicture
                          : '/../assets/images/user/01.jpg'
                      }
                      alt="user"
                    />{' '}
                    Make a Post.. <span>Post</span>
                  </Link>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="postlink">
                  <Link onClick={() => this.postModalHandler('question')}>
                    <img
                      className={this.props.user.isBlogger ? 'blogger' : ''}
                      src={
                        this.props.user.profilePicture
                          ? this.props.user.profilePicture
                          : '/../assets/images/user/01.jpg'
                      }
                      alt="user"
                    />{' '}
                    Ask a Question.. <span>Ask</span>
                  </Link>
                </div>
              </TabPanel>
            </Tabs>
          </div>
          <Modal show={this.state.showHide} className="messge-post-modal ">
            <Modal.Body>
              <div className="postsection">
                <a className="closeicon" onClick={() => this.postModalHandler('')}>
                  <i className="fa fa-close"></i>
                </a>
                <div className="heading-part">
                  <h3>{this.modalType == 'post' ? 'Make a Post' : 'Ask a question'}</h3>
                </div>
                <div className="write-messge-post">
                  <div className="textarea-part">
                    {this.modalType == 'post' ? (
                      <textarea
                        rows="3"
                        id="description"
                        name="description"
                        className="demo-editor"
                        onChange={this.onEditorStateChange}
                        placeholder="Write your Post"
                      ></textarea>
                    ) : (
                      <textarea
                        rows="3"
                        id="description"
                        name="description"
                        className="demo-editor"
                        onChange={this.onEditorStateChange}
                        placeholder="Ask your Question"
                      ></textarea>
                    )}
                    <img
                      src={
                        this.props.user.profilePicture
                          ? this.props.user.profilePicture
                          : '/../assets/images/user/01.jpg'
                      }
                      alt="user"
                      className={this.props.user.isBlogger ? 'user-icon1' : 'user-icon'}
                    />
                  </div>
                  <div className="form-group preview">
                    <div className="row" style={{ width: '100%' }}>
                      {this.fileArray.map((item, index) => {
                        return (
                          <div className="col-md-3" key={index}>
                            <i className="fa fa-times close-icon" onClick={() => this.onRemoveFileSelection(index)}></i>
                            {this.getExtension(item.name) == 'png' ||
                            this.getExtension(item.name) == 'jpg' ||
                            this.getExtension(item.name) == 'jpeg' ? (
                              <img src={item.fileurl} alt="..." height="120" width="120" />
                            ) : (
                              <video height="120" width="120" controls>
                                <source src={item.fileurl} type="video/mp4" />
                              </video>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="tags-part">
                    <ul>
                      {this.state.selectedTags.map((item, index) => {
                        return (
                          <li key={index}>
                            {item}
                            <a href="#" onClick={() => this.removeTags(item)}>
                              <i className="fa fa-times-circle-o"></i>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {this.state.linkpart && this.state.link ? (
                    <div>
                      <LinkPreview
                        fetcher={RplFetcher}
                        url={this.state.link}
                        width="580px"
                        height="250px"
                        imageHeight="320px"
                        descriptionLength="80"
                        className="link-preview ml-5"
                      />
                    </div>
                  ) : null}
                  <div className={this.state.linkpart ? 'link-part active' : 'link-part'}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="link"
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Enter Your Link"
                      />
                    </div>
                  </div>
                  {this.state.websitepart && this.state.web_link ? (
                    <div>
                      <LinkPreview
                        fetcher={RplFetcher}
                        url={this.state.web_link}
                        width="580px"
                        height="250px"
                        imageHeight="320px"
                        descriptionLength="80"
                        className="link-preview ml-5 mt-3"
                      />
                    </div>
                  ) : null}
                  <div className={this.state.websitepart ? 'link-part active' : 'link-part'}>
                    <div className="form-group">
                      <input
                        type="text"
                        name="web_link"
                        onChange={this.handleInputChange}
                        className="form-control"
                        placeholder="Enter Your Website Link"
                      />
                    </div>
                  </div>
                  <div className="post-footer-part">
                    <div className="left-part">
                      <ul>
                        <li style={{ cursor: 'pointer!important' }}>
                          <div className="upload-image ">
                            <label>
                              <i className="fa fa-picture-o"></i>
                              <input
                                type="file"
                                className="form-control"
                                accept=".jpeg,.jpg,.png"
                                onChange={this.uploadMultipleFiles}
                                multiple
                              />{' '}
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="upload-image">
                            <label>
                              <i className="fa fa-play-circle"></i>
                              <input
                                type="file"
                                className="form-control"
                                accept=".mp4"
                                onChange={this.uploadMultipleFiles}
                              />{' '}
                            </label>
                          </div>
                        </li>
                        <li>
                          <Link onClick={this.toggleLinkpart}>
                            <i className="fa fa-link"></i>
                          </Link>
                        </li>
                        <li>
                          <Link onClick={this.toggleWebsitepart}>
                            <i className="fa fa-globe"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="right-part">
                      <div className="button-part">
                        <button className="btn btn-primary" onClick={this.handleSubmit} type="button ">
                          {this.modalType == 'post' ? 'Post' : 'Ask'}
                        </button>
                      </div>
                      <div className="search-part">
                        <input
                          className="form-control mr-sm-2"
                          onChange={(e) => this.handleKeyPress(e)}
                          type="search "
                          placeholder="Add Tags"
                          aria-label="Search"
                        />
                        <div className="tags-search">
                          <ul>
                            {this.state.interestList.map((user, index) => (
                              <li key={index} onClick={() => this.tags(user.name)}>
                                <Link
                                  to={{
                                    pathName: '#',
                                    state: { user: this.props.user },
                                  }}
                                >
                                  {user.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <span className="imagesizedefine">Max size of video should be 20MB</span>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <div className="shorting-post mt-3 ">
          <div className="main-section">
            <div className="left-part"></div>
            <div className="right-part">
              {this.state.listData.length > 1 ? (
                <div className="form-group">
                  <label>Sort By :</label>
                  <select className="fomr-control" onChange={this.onFilterChange}>
                    <option value="desc">Latest</option>
                    <option value="popular">Most Popular</option>
                  </select>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <AllPostQuestionList
          listData={this.state.listData}
          user={this.props.user}
          onLikeChange={this.handleLike}
          onBlock={this.onBlock}
          onEdit={this.onEdit}
        />
        {this.state.isLoading && <div style={{ textAlign: 'center', color: 'brown' }}>Loading...</div>}
      </div>
    );
  }
}

export default Postquestion;
