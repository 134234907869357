import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Modal } from 'react-bootstrap';
import 'react-tabs/style/react-tabs.css';
import * as postService from './../services/postServices';
import httpService from '../services/httpService';
import { toast } from 'react-toastify';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import ShowMoreText from 'react-show-more-text';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import Prince1 from '../assets/images/icons/Prince1.png';
import Prince2 from '../assets/images/icons/Prince2.png';
import Prince3 from '../assets/images/icons/Prince3.png';
import Prince4 from '../assets/images/icons/Prince4.png';
import Heart1 from '../assets/images/icons/Heart1.png';
import { RplFetcher } from '../lib/helpers';

class AdminLearnWithBthrivePostQuestionList extends Form {
  constructor(props) {
    super(props);
    this.state.listData = props.listData;
  }
  async componentDidMount() {
    // this.postDetail();
    //this.hitAllLikeDataApi();
  }
  handleModalShowHide(data = '') {
    if (data) {
      this.setState({
        collectionData: '',
      });
      this.setState({
        collectionData: data,
      });
    }
    this.setState({ formData: {} });
    this.setState({ showHide: !this.state.showHide });
  }
  // const likeArray = [];
  state = {
    user: '',
    formData1: {
      type: '',
      postId: '',
    },
    formData: {
      collection_name: '',
    },
    errors: {},
    collectionData: '',
    collectionListData: [],
    allLikeData: [],
    allLikeCount: '',
    postId: '',
    likeshowHide: false,
    likeshowHide2: false,
    likeshowHide3: false,
    likeshowHide4: false,
    likeshowHide5: false,
    likeshowHide7: false,
  };

  schema = {
    collection_name: Joi.string().required(),
    //  selectedFile1: Joi.required(),
  };

  emojiModalShowHide(postId = '', type = '') {
    if (postId) {
      this.setState({ postId: postId });
      this.hitAllLikeDataApi(postId, (type = ''));
    }
    if (type) {
      if (type == 'all') type = '';
      this.hitAllLikeDataApi(this.state.postId, type);
    } else {
      this.setState({ emojishowHide: !this.state.emojishowHide });
    }
  }

  async hitAllLikeDataApi(postId, type) {
    try {
      const { data } = await httpService.get(`${process.env.REACT_APP_APIENDPOINT}/likeList?id=${postId}&type=${type}`);

      if (data) {
        const allLikeData = data.likeList;
        this.setState({ allLikeData: allLikeData });
        this.setState({ allLikeCount: data.likes });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  doSubmit = async () => {
    const { error } = await postService.addPostToCollection(this.state.formData, this.state.collectionData._id);
    if (error) return;
    this.setState({ showHide: !this.state.showHide });
    return;
  };

  likePopup = () => {
    this.setState({ likeshowHide: !this.state.likeshowHide });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide: false,
      });
    }, 6000);
  };
  likePopup2 = () => {
    this.setState({ likeshowHide2: !this.state.likeshowHide2 });
    this.setState({
      likeshowHide: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide2: false,
      });
    }, 6000);
  };
  likePopup3 = () => {
    this.setState({ likeshowHide3: !this.state.likeshowHide3 });
    this.setState({
      likeshowHide2: false,
      likeshowHide: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide3: false,
      });
    }, 6000);
  };
  likePopup4 = () => {
    this.setState({ likeshowHide4: !this.state.likeshowHide4 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide: false,
      likeshowHide5: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide4: false,
      });
    }, 6000);
  };
  likePopup5 = () => {
    this.setState({ likeshowHide5: !this.state.likeshowHide5 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide: false,
      likeshowHide7: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide5: false,
      });
    }, 6000);
  };
  likePopup7 = () => {
    this.setState({ likeshowHide7: !this.state.likeshowHide7 });
    this.setState({
      likeshowHide2: false,
      likeshowHide3: false,
      likeshowHide4: false,
      likeshowHide5: false,
      likeshowHide: false,
    });
    setTimeout(() => {
      this.setState({
        likeshowHide7: false,
      });
    }, 6000);
  };

  async hitLikeApi(postId, likeType, index) {
    this.state.formData1.type = likeType;
    this.state.formData1.postId = postId;

    const { data, error } = await postService.createLike(this.state.formData1);
    if (error) {
      // this.loader('none');
      return;
    } else {
      if (data.isLike == 1) {
        this.props.onLikeChange(likeType, index, 1);
      } else {
        this.props.onLikeChange(likeType, index, 0);
      }
      // alert('Liked successfully')

      return;
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.listData != prevProps.listData) {
      const listData = this.props.listData;
      this.setState({ listData });
    }
  };
  nFormatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
  };

  render() {
    return (
      <div>
        <div className="post-display-section">
          <div className="main-section">
            {this.state.listData.length ? (
              this.state.listData.map((data, index) => {
                return (
                  <div className="post-block mb-3 learnboder wow fadeInUp" key={index}>
                    <div className="post-toppart">
                      <div className="left-part">
                        <Link className="nav-link" style={{ cursor: 'default' }}>
                          <img src="../../assets/images/user/02.png" alt="User Profile" />
                          <p>Learn With B-thrive</p>
                          <span>Posted : {data.creationDate}</span>
                        </Link>
                      </div>
                    </div>
                    <div className="messge-box">
                      <Link
                        style={{ cursor: 'pointer' }}
                        to={
                          window.location.href.includes('admin')
                            ? '/admin/learn-b-thrive-detail/' + data._id
                            : '/learn-with-bthrive-detail/' + data._id
                        }
                      >
                        <p>
                          <ShowMoreText
                            /* Default options */
                            lines={3}
                            more="Read more"
                            less="Read less"
                            onClick={() =>
                              this.props.history.push(
                                window.location.href.includes('admin')
                                  ? '/admin/learn-b-thrive-detail/' + data._id
                                  : '/learn-with-bthrive-detail/' + data._id,
                              )
                            }
                            expanded={false}
                          >
                            {data.description}
                          </ShowMoreText>
                        </p>
                      </Link>
                      {data.link ? (
                        <>
                          <LinkPreview
                            fetcher={RplFetcher}
                            url={
                              data.link.includes('https://www.') || data.link.includes('http')
                                ? data.link
                                : `https://www.${data.link.replace('www.', '')}`
                            }
                            width="680px"
                            height="350px"
                            imageHeight="320px"
                            descriptionLength="80"
                            className="link-preview ml-1"
                          />
                          <a
                            href={
                              data.link.includes('https://www.') || data.link.includes('http')
                                ? data.link
                                : `https://www.${data.link.replace('www.', '')}`
                            }
                            target="_blank"
                            className="postlinks mt-0 mb-1"
                          >
                            <i className="fa fa-link"></i> {data.link}
                          </a>
                        </>
                      ) : (
                        ''
                      )}
                      {data.web_link ? (
                        <>
                          <LinkPreview
                            fetcher={RplFetcher}
                            url={
                              data.web_link.includes('https://www.') || data.web_link.includes('http')
                                ? data.web_link
                                : `https://www.${data.web_link.replace('www.', '')}`
                            }
                            width="660px"
                            height="350px"
                            imageHeight="320px"
                            descriptionLength="80"
                            className="link-preview"
                          />
                          <a
                            href={
                              data.web_link.includes('https://www.') || data.web_link.includes('http')
                                ? data.web_link
                                : `https://www.${data.web_link.replace('www.', '')}`
                            }
                            target="_blank"
                            className="postlinks mt-0 mb-1"
                          >
                            <i className="fa fa-globe"></i> {data.web_link}
                          </a>
                        </>
                      ) : (
                        ''
                      )}
                      <Link
                        to={{
                          pathname: window.location.href.includes('admin')
                            ? '/admin/learn-b-thrive-detail/' + data._id
                            : '/learn-with-bthrive-detail/' + data._id,
                          state: { user: this.props.user },
                        }}
                      >
                        {data.images.length || data.videos.length ? (
                          <div className="image-post">
                            {data.images.length ? (
                              <img src={data.images[0]} alt="Event" />
                            ) : data.videos.length ? (
                              <video controls height="400px" width="100%">
                                <source src={data.videos[0]} type="video/mp4"></source>
                              </video>
                            ) : null}
                            {data.images.length + data.videos.length - 1 > 0 ? (
                              <p>
                                {' '}
                                {data.images.length + data.videos.length - 1 > 0
                                  ? '+' + (data.images.length + data.videos.length - 1)
                                  : ''}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                      </Link>
                    </div>
                    <div className="bottom-part">
                      <div className="left-part">
                        <ul>
                          {data.interest.length > 2 ? (
                            <div>
                              <li>{data.interest[0].name}</li>
                              <li>{data.interest[1].name}</li>
                              <li>
                                <Link
                                  to={{
                                    pathname: window.location.href.includes('admin')
                                      ? '/admin/learn-b-thrive-detail/' + data._id
                                      : '/learn-with-bthrive-detail/' + data._id,
                                    state: { user: this.props.user },
                                  }}
                                >
                                  +{data.interest.length - 2}
                                </Link>
                              </li>
                            </div>
                          ) : (
                            data.interest.map((tagName, index1) => {
                              return <li key={index1}>{tagName.name}</li>;
                            })
                          )}
                        </ul>
                      </div>
                      {!window.location.href.includes('admin') && (
                        <div className="right-part">
                          <div className="liked-user">
                            <Link onClick={() => this.emojiModalShowHide(data._id)}>
                              {data.likeList.length
                                ? `Liked by ${data.likeList[data.likeList.length - 1].createdBy.name} ${
                                    data.likeList.length > 1 ? `and others` : ''
                                  } `
                                : ''}
                            </Link>
                          </div>
                          <ul>
                            <li>
                              <Link onClick={() => this.emojiModalShowHide(data._id)}>
                                {data.my_like.length ? (
                                  data.my_like[0].type == '11' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup1.png" />
                                    </span>
                                  ) : data.my_like[0].type == '12' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup2.png" />
                                    </span>
                                  ) : data.my_like[0].type == '13' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup3.png" />
                                    </span>
                                  ) : data.my_like[0].type == '14' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Thumbsup4.png" />
                                    </span>
                                  ) : data.my_like[0].type == '21' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise1.png" />
                                    </span>
                                  ) : data.my_like[0].type == '22' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise2.png" />
                                    </span>
                                  ) : data.my_like[0].type == '23' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise3.png" />
                                    </span>
                                  ) : data.my_like[0].type == '24' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Handraise4.png" />
                                    </span>
                                  ) : data.my_like[0].type == '31' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist1.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '32' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist2.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '33' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist3.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '34' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Raised-fist4.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '41' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand1.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '42' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand2.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '43' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand3.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '44' ? (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        src="/../assets/images/icons/Folded-hand4.png"
                                      />
                                    </span>
                                  ) : data.my_like[0].type == '61' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess1.png" />
                                    </span>
                                  ) : data.my_like[0].type == '62' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess2.png" />
                                    </span>
                                  ) : data.my_like[0].type == '63' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess3.png" />
                                    </span>
                                  ) : data.my_like[0].type == '64' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src="/../assets/images/icons/Princess4.png" />
                                    </span>
                                  ) : data.my_like[0].type == '71' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince1} />
                                    </span>
                                  ) : data.my_like[0].type == '72' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince2} />
                                    </span>
                                  ) : data.my_like[0].type == '73' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince3} />
                                    </span>
                                  ) : data.my_like[0].type == '74' ? (
                                    <span>
                                      {' '}
                                      <img className="emoji-list-icon3" src={Prince4} />
                                    </span>
                                  ) : (
                                    <span>
                                      {' '}
                                      <img
                                        className="emoji-list-icon3"
                                        style={{ fontSize: '25px!important' }}
                                        src={Heart1}
                                      />
                                    </span>
                                  )
                                ) : (
                                  <span>
                                    {' '}
                                    <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                                  </span>
                                )}{' '}
                              </Link>
                              <div className="emoji-list">
                                <ul>
                                  <li className="dropUp" onClick={() => this.likePopup()}>
                                    <Link>
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Thumbsup1.png" />

                                      <div className={this.state.likeshowHide ? 'emoji-list_active' : 'emoji-list2'}>
                                        <ul>
                                          <li className="dropUp">
                                            <div className="d-flex">
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 11, index)}
                                                className=" ml-5"
                                              >
                                                <img
                                                  className="emoji-list-icon "
                                                  src="/../assets/images/icons/Thumbsup1.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 12, index)}
                                                className="mr-3 ml-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Thumbsup2.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 13, index)}
                                                className="mr-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Thumbsup3.png"
                                                />
                                              </Link>
                                              <Link onClick={() => this.hitLikeApi(data._id, 14, index)} className="">
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Thumbsup4.png"
                                                />
                                              </Link>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Link>
                                  </li>

                                  <li className="dropUp3" onClick={() => this.likePopup2()}>
                                    <Link>
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Handraise1.png" />

                                      <div className={this.state.likeshowHide2 ? 'emoji-list3_active' : 'emoji-list3'}>
                                        <ul>
                                          <li className="dropUp">
                                            <div className="d-flex">
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 21, index)}
                                                className=" ml-5"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Handraise2.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 22, index)}
                                                className="mr-3 ml-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Handraise2.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 23, index)}
                                                className="mr-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Handraise3.png"
                                                />
                                              </Link>
                                              <Link onClick={() => this.hitLikeApi(data._id, 24, index)} className="">
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Handraise4.png"
                                                />
                                              </Link>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Link>
                                  </li>
                                  <li className="dropUp4" onClick={() => this.likePopup3()}>
                                    <Link>
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Raised-fist1.png" />
                                      <div className={this.state.likeshowHide3 ? 'emoji-list4_active' : 'emoji-list4'}>
                                        <ul>
                                          <li className="dropUp">
                                            <div className="d-flex">
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 31, index)}
                                                className=" ml-5"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Raised-fist1.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 32, index)}
                                                className="mr-3 ml-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Raised-fist2.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 33, index)}
                                                className="mr-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Raised-fist3.png"
                                                />
                                              </Link>
                                              <Link onClick={() => this.hitLikeApi(data._id, 34, index)} className="">
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Raised-fist4.png"
                                                />
                                              </Link>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Link>
                                  </li>
                                  <li className="dropUp5" onClick={() => this.likePopup4()}>
                                    <Link>
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Folded-hand1.png" />

                                      <div className={this.state.likeshowHide4 ? 'emoji-list5_active' : 'emoji-list5'}>
                                        <ul>
                                          <li className="dropUp">
                                            <div className="d-flex">
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 41, index)}
                                                className=" ml-5"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Folded-hand2.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 42, index)}
                                                className="mr-3 ml-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Folded-hand2.png"
                                                />
                                              </Link>

                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 43, index)}
                                                className="mr-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Folded-hand3.png"
                                                />
                                              </Link>
                                              <Link onClick={() => this.hitLikeApi(data._id, 44, index)} className="">
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Folded-hand4.png"
                                                />
                                              </Link>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Link>
                                  </li>
                                  <li className="dropUp">
                                    <Link onClick={() => this.hitLikeApi(data._id, 5, index)}>
                                      <img
                                        className="emoji-list-icon"
                                        style={{
                                          fontSize: '25px!important',
                                        }}
                                        src={Heart1}
                                      />
                                    </Link>
                                  </li>
                                  <li className="dropUp6" onClick={() => this.likePopup5()}>
                                    <Link>
                                      <img className="emoji-list-icon" src="/../assets/images/icons/Princess1.png" />

                                      <div className={this.state.likeshowHide5 ? 'emoji-list6_active' : 'emoji-list6'}>
                                        <ul>
                                          <li className="dropUp">
                                            <div className="d-flex">
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 61, index)}
                                                className=" ml-5"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Princess1.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 62, index)}
                                                className="mr-3 ml-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Princess2.png"
                                                />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 63, index)}
                                                className="mr-3"
                                              >
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Princess3.png"
                                                />
                                              </Link>
                                              <Link onClick={() => this.hitLikeApi(data._id, 64, index)} className="">
                                                <img
                                                  className="emoji-list-icon"
                                                  src="/../assets/images/icons/Princess4.png"
                                                />
                                              </Link>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Link>
                                  </li>
                                  <li className="dropUp7" onClick={() => this.likePopup7()}>
                                    <Link>
                                      <img className="emoji-list-icon" src={Prince1} />
                                      <div className={this.state.likeshowHide7 ? 'emoji-list7_active' : 'emoji-list7'}>
                                        <ul>
                                          <li className="dropUp">
                                            <div className="d-flex">
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 71, index)}
                                                className=" ml-5"
                                              >
                                                <img className="emoji-list-icon" src={Prince1} />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 72, index)}
                                                className="mr-3 ml-3"
                                              >
                                                <img className="emoji-list-icon" src={Prince2} />
                                              </Link>
                                              <Link
                                                onClick={() => this.hitLikeApi(data._id, 73, index)}
                                                className="mr-3"
                                              >
                                                <img className="emoji-list-icon" src={Prince3} />
                                              </Link>
                                              <Link onClick={() => this.hitLikeApi(data._id, 74, index)} className="">
                                                <img className="emoji-list-icon" src={Prince4} />
                                              </Link>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li>
                              <Link
                                to={{
                                  pathname: window.location.href.includes('admin')
                                    ? '/admin/learn-b-thrive-detail/' + data._id
                                    : '/learn-with-bthrive-detail/' + data._id,
                                  state: { user: this.props.user },
                                }}
                              >
                                <img
                                  src="/../assets/images/icons/comment.png"
                                  style={{ width: '25px', marginTop: '-5px' }}
                                />
                                {this.nFormatter(data.total_comments)}
                              </Link>
                            </li>
                            {data.type == 'post' ? (
                              <li>
                                <Link
                                  to={
                                    window.location.href.includes('admin')
                                      ? '/admin/learn-b-thrive-detail/' + data._id + '?type=insight'
                                      : '/learn-with-bthrive-detail/' + data._id + '?type=insight'
                                  }
                                >
                                  <img
                                    src="/../assets/images/icons/insight.png"
                                    style={{
                                      width: '25px',
                                      marginTop: '-5px',
                                    }}
                                  />
                                  {this.nFormatter(data.total_insights)}
                                </Link>
                              </li>
                            ) : (
                              ''
                            )}
                            {data.type == 'question' ? (
                              <li>
                                <Link
                                  to={{
                                    pathname: window.location.href.includes('admin')
                                      ? '/admin/learn-b-thrive-detail/' + data._id
                                      : '/learn-with-bthrive-detail/' + data._id,
                                    state: { user: this.props.user },
                                  }}
                                >
                                  {' '}
                                  Answer
                                </Link>
                              </li>
                            ) : (
                              ''
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <h1 className="no-data-found">no Post made yet</h1>
              </div>
            )}
            <Modal show={this.state.emojishowHide} className="emoji-modal ">
              <Modal.Body>
                <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                  <i className="fa fa-close"></i>
                </Link>
                <div className="collection">
                  <h4 className="">Reactions </h4>
                  <div className="reaction-parts">
                    <Tabs>
                      <TabList id="style-1">
                        <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                          All {this.nFormatter(this.state.allLikeCount.total_likes)}
                        </Tab>
                        <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                          <span>
                            {' '}
                            <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                          </span>{' '}
                          {this.nFormatter(this.state.allLikeCount.total_likes_1)}
                        </Tab>
                        <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                          <span>
                            {' '}
                            <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                          </span>{' '}
                          {this.nFormatter(this.state.allLikeCount.total_likes_2)}
                        </Tab>
                        <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                          <span>
                            {' '}
                            <img className="emoji-list-icon2" src="/../assets/images/icons/Raised-fist1.png" />{' '}
                          </span>{' '}
                          {this.nFormatter(this.state.allLikeCount.total_likes_3)}
                        </Tab>
                        <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                          <span>
                            {' '}
                            <img className="emoji-list-icon2" src="/../assets/images/icons/Folded-hand1.png" />{' '}
                          </span>{' '}
                          {this.nFormatter(this.state.allLikeCount.total_likes_4)}
                        </Tab>
                        <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                          <span>
                            <img className="emoji-list-icon2" src={Heart1} />
                          </span>{' '}
                          {this.nFormatter(this.state.allLikeCount.total_likes_5)}
                        </Tab>
                        <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                          <span>
                            {' '}
                            <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                          </span>{' '}
                          {this.nFormatter(this.state.allLikeCount.total_likes_6)}
                        </Tab>
                        <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                          <span>
                            {' '}
                            <img className="emoji-list-icon2" src={Prince1} />{' '}
                          </span>{' '}
                          {this.nFormatter(this.state.allLikeCount.total_likes_7)}
                        </Tab>
                      </TabList>
                    </Tabs>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <TabPanel></TabPanel>
                    <div className="likeuser-list">
                      <ul>
                        {this.state.allLikeData.length
                          ? this.state.allLikeData.map((data, index) => {
                              return (
                                <li key={index}>
                                  <Link
                                    to={
                                      !window.location.href.includes('admin')
                                        ? this.props.user._id != data.createdBy._id
                                          ? `/blogger-profile/${data.createdBy._id}`
                                          : `/user-profile`
                                        : `/admin/user-profile-detail/${data.createdBy._id}`
                                    }
                                  >
                                    <h5>
                                      {data.createdBy.name}
                                      {data.createdBy.isBlogger ? (
                                        <span style={{ fontSize: '13px' }}>
                                          {' '}
                                          <i
                                            className="fa fa-circle ml-1 mr-2"
                                            style={{
                                              fontSize: '8px',
                                              color: 'black',
                                            }}
                                          ></i>
                                          Blogger
                                        </span>
                                      ) : null}
                                    </h5>
                                    {data.createdBy.location ? (
                                      <span>{data.createdBy.location}</span>
                                    ) : (
                                      <span style={{ color: 'white' }}>Noida</span>
                                    )}
                                    <img
                                      className={data.createdBy.isBlogger ? 'pic2' : ''}
                                      src={
                                        data.createdBy.profilePicture
                                          ? data.createdBy.profilePicture
                                          : '/../assets/images/user/01.jpg'
                                      }
                                      alt="User Profile"
                                    />
                                  </Link>
                                </li>
                              );
                            })
                          : ''}
                      </ul>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminLearnWithBthrivePostQuestionList);
