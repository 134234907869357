import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import httpAdminService from '../../../services/httpAdminService';
import { toast } from 'react-toastify';
import WOW from 'wowjs';
import moment from 'moment';
class BookClubDetailPart extends Component {
  state = {
    host: '',
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/getHostDetail/${this.props.match.params.id}`,
      );
      if (data) {
        data.host.date = moment(data.host.date).format('MMM DD, YYYY h:mm A');
        this.setState({ host: data.host });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  }
  createHost = async (id) => {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/createHost/${id}`);
      if (data) {
        this.componentDidMount();
        toast.success(data.message);
        return;
      }
    } catch (error) {
      toast.error(error.response.data.error);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };
  removeHost = async (id) => {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/removeHost/${id}`);
      if (data) {
        this.componentDidMount();
        toast.success(data.message);
        return;
      }
    } catch (error) {
      toast.error(error.response.data.error);
      return;
    }

    new WOW.WOW({
      live: false,
    }).init();
  };

  render() {
    if (!this.state.host) return null;
    return (
      <div className="collection-section mt-5">
        <div className="bookclub-detail-section ">
          <div className="main-section">
            <div className="post-block mb-3 wow fadeInUp">
              <div className="row">
                <div className="col-4">
                  <div className="collection-image-part">
                    <img src={this.state.host.coverImage} alt="Book Club" />
                  </div>
                </div>
                <div className="col-8">
                  <div className="collection-content">
                    <div className="collection-toppart">
                      <div className="left-part">
                        <Link>
                          <p>Become a Host Detail</p>
                        </Link>
                      </div>
                    </div>
                    <div className="messge-box">
                      <p>{this.state.host.idea}</p>
                    </div>
                    <div className="bookclub-points">
                      <ul>
                        <li>
                          <i className="fa fa-calendar"></i>{' '}
                          {`${this.state.months[this.state.host.month - 1]}, ${this.state.host.year}`}
                        </li>
                        <li>
                          <i className="fa fa-user"></i> {this.state.host.host.name}{' '}
                        </li>
                        <li>
                          <i className="fa fa-envelope"></i> {this.state.host.host.email}{' '}
                        </li>
                      </ul>
                    </div>
                    <div className="link-attend">
                      {!this.state.host.isApproved ? (
                        <Link onClick={() => this.createHost(this.state.host._id)}>Create Host</Link>
                      ) : (
                        <Link onClick={() => this.removeHost(this.state.host._id)}>Remove Host</Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BookClubDetailPart;
