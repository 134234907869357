import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
class BannerSection extends Component {
  componentDidMount() {
    let deferredPrompt;
    const addBtn = document.querySelector('.add-button');
    addBtn.style.display = 'none';
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI to notify the user they can add to home screen
      addBtn.style.display = 'block';
      addBtn.addEventListener('click', (e) => {
        // hide our user interface that shows our A2HS button
        addBtn.style.display = 'none';
        // Show the prompt
        deferredPrompt
          .prompt()
          .then((res) => console.log(res))
          .catch((error) => {
            console.log(`----> ${error}`);
          });
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          deferredPrompt = null;
        });
      });
    });
  }
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
  }
  state = {
    email: '',
  };
  handleChange = (e) => {
    this.setState({ email: e.target.value });
  };
  ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };
  getStarted = async () => {
    if (!this.state.email) {
      toast.error('Please enter email');
      return;
    }
    if (!this.ValidateEmail(this.state.email)) {
      toast.error('Invalid Email');
      return;
    }
    try {
      const { data } = await httpService.post(`${process.env.REACT_APP_APIENDPOINT}/getStart`, {
        email: this.state.email,
      });
      if (data) {
        return await this.props.history.push({
          pathname: '/registration',
          state: this.state.email,
        });
      }
    } catch (error) {
      toast.error(error.message);
      // return;
    }
  };
  render() {
    return (
      <div className="banner-section">
        <div className="container">
          <div className="row">
            <div className="">
              <div className="main-section">
                <button class="add-button">Add app to home screen</button>
                <div className="update-section wow bounceIn">
                  <p>
                    <span>New</span>We have an update for you!{' '}
                    <Link variant="primary" onClick={() => this.handleModalShowHide()}>
                      Read More
                    </Link>
                  </p>
                </div>
                <div className="heading-section">
                  <h1 className="wow fadeInUp">A Social Educational Platform For The Global Black Community</h1>
                  <p className="wow fadeInUp">
                    B-Thrive provides a safe space to connect and learn about Black history, culture, and lifestyle to
                    inspire upward mobility so that we can be our truest, most empowered Black selves.
                  </p>
                </div>
                <div className="notification-section wow fadeInLeft">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email address"
                      onChange={this.handleChange}
                      name="email"
                    ></input>
                    <Link onClick={this.getStarted}>
                      <button type="button" className="btn btn-default">
                        Get Started
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="header-img">
                <div className="header-main-section"></div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showHide} className="newupdate-modal ">
          <Modal.Body>
            <div className="newupdate">
              <h4 className="text-center">Hey There! </h4>
              <div className="messege-box">
                <p>We're super excited to announce the launch of the B-Thrive app.</p>
                <p>
                  Now you can browse through spaces or interests & see beautiful items curated by the community that you
                  can purchase.
                </p>
                <p>Join us in supporting black content creators in the community today. Cheers, B-Thrive Team</p>
              </div>
              <div className="submit-buttons text-center">
                <Button variant="secondary" onClick={() => this.handleModalShowHide()}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default withRouter(BannerSection);
