import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import ShowMoreText from 'react-show-more-text';
class ProfileImage extends Component {
  render() {
    if (!this.props.user || !this.props.checkUser) return null;
    return (
      <div>
        <div className="profile-image-section">
          <div className="user-image">
            <img
              className={this.props.user.isBlogger ? 'blogger' : ''}
              src={
                this.props.user.profilePicture ? this.props.user.profilePicture : '/../../../assets/images/user/01.jpg'
              }
              alt="User Profile"
            />
          </div>
          <div className="user-detail text-center">
            <h2 className={this.props.user.isBlogger == 1 ? 'blogger' : 'user'}>{this.props.user.name}</h2>
            <p>
              <ShowMoreText
                /* Default options */
                lines={4}
                more="Read more"
                less="Read less"
                onClick={this.executeOnClick}
                expanded={false}
              >
                {this.props.user.description ? this.props.user.description : ''}
              </ShowMoreText>
            </p>
            {this.props.user.blogLink ? (
              <p>
                <a href={this.props.user.blogLink} target="_blank" className="websitelink">
                  {this.props.user.blogLink}
                </a>
              </p>
            ) : (
              ''
            )}
            {this.props.user.website ? (
              <p>
                <a href={this.props.user.website} target="_blank" className="websitelink">
                  {this.props.user.website}
                </a>
              </p>
            ) : (
              ''
            )}
            <div className="setlite-ghana">
              {this.props.user.location ? (
                <Link>
                  <i className="fa fa-map-marker"></i>
                  {this.props.user.location}
                </Link>
              ) : null}
              {this.props.user.origin ? (
                <Link>
                  <i className="fa fa-dot-circle-o"></i>
                  {this.props.user.origin}
                </Link>
              ) : null}
            </div>
          </div>
          <div className="count-section">
            <div className="row">
              <div className="col-4">
                <div className="count-box">
                  <p>
                    {this.props.checkUser[0].total_posts} <span>Post</span>
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="count-box">
                  <p>
                    {this.props.checkUser[0].total_collections}
                    <span>Collections</span>
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="count-box">
                  <p>
                    {this.props.checkUser[0].total_bookmarks}
                    <span>Bookmarks</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileImage;
