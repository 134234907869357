import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import ReadMoreAndLess from 'react-read-more-less';
import { toast } from 'react-toastify';
import httpAdminService from '../../services/httpAdminService';
import MediaQuery from 'react-responsive';
class BloggerListSection extends Component {
  state = {
    bloggerdata: [],
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/bloggers`);
      if (data) {
        this.setState({ bloggerdata: data.collaboratedBloggers });
        this.filterlist(this.state.bloggerdata);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  filterlist = (data) => {
    let listData = data;
    if (this.props.user.usersBlocked) {
      for (var index = 0; index < listData.length; index++) {
        if (
          this.props.user.usersBlocked.includes(listData[index]._id) ||
          listData[index].usersBlocked.includes(this.props.user._id)
        ) {
          listData.splice(index, 1);
          index--;
        }
      }
    }
    this.setState({ bloggerdata: listData });
  };
  render() {
    return (
      <>
        <div className="blogger-section mt-3">
          <div className="row mt-3">
            {this.state.bloggerdata.length ? (
              this.state.bloggerdata.map((blogger) => {
                return (
                  <>
                    <div className="blogger-display-section col-md-12 ">
                      <div className="main-section  ">
                        <Link
                          to={
                            this.props?.user?._id == blogger._id ? `/user-profile` : `/blogger-profile/${blogger._id}`
                          }
                        >
                          <div className="post-block mb-3 wow fadeInUp ">
                            <div className=" d-flex">
                              <div className=" col-3 mt-1 mb-1">
                                <div className="blogger-image-part text-center">
                                  <img className="blogger" src={blogger.profilePicture} alt="Blogger Profile" />
                                </div>
                              </div>
                              <div className="col-md-3 ml-2 mr-2 p-0">
                                <div className="blogger-name-part">
                                  <div className="bloger-detail">
                                    <h3>{blogger.name}</h3>
                                    <p>
                                      <i className="fa fa-calendar mr-1"></i>
                                      {blogger.numberOfPosts} Post
                                    </p>
                                    <p>
                                      <i className="fa fa-clock-o mr-1"></i>
                                      {blogger.location}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-5 p-0">
                                <div className="blogger-description-part">
                                  <div className="bloger-description">
                                    <p className="bloger-desc">
                                      {blogger.description ? (
                                        <ReadMoreAndLess
                                          ref={this.ReadMore}
                                          className="read-more-content"
                                          charLimit={150}
                                          readMoreText="Read more"
                                          readLessText="Read less"
                                        >
                                          {blogger.description}
                                        </ReadMoreAndLess>
                                      ) : null}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <div className="col-md-12">
                <h1 className="no-data-found">no Blogger Collaborated yet</h1>
              </div>
            )}
          </div>
        </div>
        <MediaQuery query="(max-width:767px)">
          <div className="blogger-section1 mt-3">
            <div className="row mt-3">
              {this.state.bloggerdata.length ? (
                this.state.bloggerdata.map((blogger) => {
                  return (
                    <>
                      <div className="blogger-display-section col-md-12 ">
                        <div className="main-section  ">
                          <Link
                            to={
                              this.props?.user?._id == blogger._id ? `/user-profile` : `/blogger-profile/${blogger._id}`
                            }
                          >
                            <div className="post-block mb-3 wow fadeInUp ">
                              <div className="row">
                                <div className=" col-md-8 mt-1 mb-1">
                                  <div className="blogger-image-part text-center">
                                    <img className="blogger" src={blogger.profilePicture} alt="Blogger Profile" />
                                    <div className="blogger-name-part">
                                      <div className="bloger-detail">
                                        <h3>{blogger.name}</h3>
                                        <p>
                                          <i className="fa fa-calendar mr-1"></i>
                                          {blogger.numberOfPosts} Post
                                        </p>
                                        <p>
                                          <i className="fa fa-clock-o mr-1"></i>
                                          {blogger.location}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 p-0">
                                  <div className="blogger-description-part">
                                    <div className="bloger-description">
                                      <p className="bloger-desc">
                                        {blogger.description ? (
                                          <ReadMoreAndLess
                                            ref={this.ReadMore}
                                            className="read-more-content"
                                            charLimit={90}
                                            readMoreText="Read more"
                                            readLessText="Read less"
                                          >
                                            {blogger.description}
                                          </ReadMoreAndLess>
                                        ) : null}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="col-md-12">
                  <h1 className="no-data-found">no Blogger Collaborated yet</h1>
                </div>
              )}
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default BloggerListSection;
