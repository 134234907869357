import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import ReadMoreAndLess from 'react-read-more-less';
import { Tab, Tabs, TabList } from 'react-tabs';
import { Modal } from 'react-bootstrap';
import httpService from '../../services/httpService';
import { toast } from 'react-toastify';
import Prince1 from '../../assets/images/icons/Prince1.png';
import Heart1 from '../../assets/images/icons/Heart1.png';
class AdminCollectionDetailSection extends Component {
  state = {
    collectionLikeList: [],
    collectionLikeCount: [],
    postId: '',
  };
  emojiModalShowHide(postId = '', type = '') {
    if (postId) {
      this.setState({ postId: postId });
      this.hitcollectionLikeList(postId, (type = ''));
    }
    if (type) {
      if (type === 'all') type = '';
      this.hitcollectionLikeList(this.state.postId, type);
    } else {
      this.setState({ emojishowHide: !this.state.emojishowHide });
    }
  }

  async hitcollectionLikeList(postId, type) {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/collectionLikeList?id=${postId}&type=${type}`,
      );

      if (data) {
        const collectionLikeList = data.collectionLikeList;
        this.setState({ collectionLikeList: collectionLikeList });
        this.setState({ collectionLikeCount: data.likes });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  constructor(props) {
    super(props);
  }
  nFormatter = (num) => {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(1).replace(rx, '$1') + item.symbol : '0';
  };
  render() {
    if (!this.props.collectionDetail[0] || !this.props.collectionDetail[0].createdBy) return null;
    return (
      <div className="collection-section collectiondetail[0] mt-3">
        <div className="collection-display-section ">
          <div className="main-section">
            <div className="post-block mb-3 wow fadeInUp">
              <div className="row">
                <div className="col-4">
                  <div
                    className="collection-image-part collback"
                    style={{
                      backgroundImage: `url(${this.props.collectionDetail[0].image})`,
                    }}
                  ></div>
                </div>
                <div className="col-8">
                  <div className="collection-content">
                    <div className="collection-toppart">
                      <div className="left-part">
                        <Link className="nav-link">
                          <p>{this.props.collectionDetail[0].name}</p>
                        </Link>
                      </div>
                      <div className="right-part">
                        <Link className="smile-icon">
                          <i className="fa fa-database"></i> {this.props.collectionDetail[0].post.length} posts
                        </Link>
                      </div>
                    </div>
                    <div className="messge-box">
                      <ReadMoreAndLess
                        ref={this.ReadMore}
                        className="read-more-content"
                        charLimit={350}
                        readMoreText="Read more"
                        readLessText="Read less"
                      >
                        {this.props.collectionDetail[0].description}
                      </ReadMoreAndLess>
                    </div>
                    <div className="post-toppart">
                      <div className="left-part">
                        <Link className="nav-link">
                          <img
                            className={this.props.collectionDetail[0].createdBy.isBlogger ? 'pic2' : ''}
                            src={
                              this.props.collectionDetail[0].createdBy.profilePicture
                                ? this.props.collectionDetail[0].createdBy.profilePicture.includes(
                                    'googleusercontent.com',
                                  ) || this.props.collectionDetail[0].createdBy.profilePicture.includes('licdn.com')
                                  ? this.props.collectionDetail[0].createdBy.profilePicture
                                  : `this.props.collectionDetail[0].creatBy.profilePicture}`
                                : '../../assets/images/user/01.jpg'
                            }
                            alt="User Profile"
                          />
                          <p>
                            {this.props.collectionDetail[0].createdBy.name}
                            {this.props.collectionDetail[0].createdBy.isBlogger ? (
                              <span style={{ fontSize: '13px' }}>
                                {' '}
                                <i className="fa fa-circle ml-1 mr-2" style={{ fontSize: '8px', color: 'black' }}></i>
                                Blogger
                              </span>
                            ) : null}
                          </p>{' '}
                          <span>Posted : {this.props.collectionDetail[0].creationDate}</span>
                        </Link>
                      </div>
                      <div className="right-part">
                        <div className="liked-user">
                          <Link onClick={() => this.emojiModalShowHide(this.props.collectionDetail[0]._id)}>
                            {this.props.collectionDetail[0].likeList.length
                              ? `Liked by ${
                                  this.props.collectionDetail[0].likeList[
                                    this.props.collectionDetail[0].likeList.length - 1
                                  ].createdBy.name
                                } ${this.props.collectionDetail[0].likeList.length > 1 ? `and others` : ''} `
                              : ''}
                          </Link>
                        </div>
                        <div className="reactionpart">
                          <Link
                            className="smile-icon"
                            onClick={() => this.emojiModalShowHide(this.props.collectionDetail[0]._id)}
                          >
                            <span>
                              {' '}
                              <img style={{ width: '21px' }} src="/../assets/images/icons/Thumbsup1.png" />
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Modal show={this.state.emojishowHide} className="emoji-modal ">
                  <Modal.Body>
                    <Link className="closeicon" onClick={() => this.emojiModalShowHide()}>
                      <i className="fa fa-close"></i>
                    </Link>
                    <div className="collection">
                      <h4 className="">Reactions </h4>
                      <div className="reaction-parts">
                        <Tabs>
                          <TabList id="style-1">
                            <Tab onClick={() => this.emojiModalShowHide('', 'all')}>
                              All {this.nFormatter(this.state.collectionLikeCount.total_likes)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 11)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Thumbsup1.png" />
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_1)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 21)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Handraise1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_2)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 31)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Raised-fist1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_3)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 41)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Folded-hand1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_4)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 5)}>
                              <span>
                                <img className="emoji-list-icon2" src={Heart1} />
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_5)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 61)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src="/../assets/images/icons/Princess1.png" />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_6)}
                            </Tab>
                            <Tab onClick={() => this.emojiModalShowHide('', 71)}>
                              <span>
                                {' '}
                                <img className="emoji-list-icon2" src={Prince1} />{' '}
                              </span>{' '}
                              {this.nFormatter(this.state.collectionLikeCount.total_likes_7)}
                            </Tab>
                          </TabList>
                        </Tabs>
                        <div className="likeuser-list">
                          <ul>
                            {this.state.collectionLikeList.length
                              ? this.state.collectionLikeList.map((data, index) => {
                                  return (
                                    <li>
                                      <Link to={`/admin/user-profile-detail/${data.createdBy._id}`}>
                                        <h5>
                                          {data.createdBy.name}
                                          {data.createdBy.isBlogger ? (
                                            <span style={{ fontSize: '13px' }}>
                                              {' '}
                                              <i
                                                className="fa fa-circle ml-1 mr-2"
                                                style={{
                                                  fontSize: '8px',
                                                  color: 'black',
                                                }}
                                              ></i>
                                              Blogger
                                            </span>
                                          ) : null}
                                        </h5>
                                        {data.createdBy.location ? (
                                          <span>{data.createdBy.location}</span>
                                        ) : (
                                          <span style={{ color: 'white' }}>Noida</span>
                                        )}
                                        <img
                                          className={data.createdBy.isBlogger ? 'pic2' : ''}
                                          src={
                                            data.createdBy.profilePicture
                                              ? data.createdBy.profilePicture
                                              : '../../assets/images/user/01.jpg'
                                          }
                                          alt="User Profile"
                                        />
                                      </Link>
                                    </li>
                                  );
                                })
                              : ''}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminCollectionDetailSection;
