import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
class BigIdeaSection extends Component {
  state = {};
  render() {
    return (
      <div className="bigidea-section">
        <div className="container">
          <div className="main-section">
            <div className="heading-section text-center">
              <p className="wow fadeInUp">The Big Idea</p>
              <h3 className="wow fadeInUp">
                THE ANCIENT WAYS ARE THE <br /> KEYS TO OUR FUTURE
              </h3>
            </div>
          </div>
          <div className="slider-part ">
            <Carousel infiniteLoop useKeyboardArrows autoPlay>
              <div className="wow slideInUp">
                <img src="assets/images/home/slider3.jpg" alt="Slider 1" />
              </div>
              <div>
                <img src="assets/images/home/slider4.png" alt="Slider 2" />
              </div>
              <div>
                <img src="assets/images/home/slider5.png" alt="Slider 3" />
              </div>
            </Carousel>
          </div>
          <div className="sider-bottom-text text-center wow fadeInUp">
            <p>
              Our purpose is to shine light and share knowledge about our collective historical
              <br /> past to inspire healing, restore unity and cultivate forward movement.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default BigIdeaSection;
