import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import AdminHeader from './../commonComponents/adminheader';
import Usertopmenubar from '../commonComponents/usertopmenubar';
import httpAdminService from './../../services/httpAdminService';
import { toast } from 'react-toastify';
import UseractivityShorting from './useractivitySection/useractivityShorting';
import Useractivitylisttable from './useractivitySection/useractivitylisttable';
import moment from 'moment';
class Useractivitylist extends Component {
  state = {
    usersData: '',
    usersActivity: [],
  };
  async componentDidMount() {
    this.userApi();
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersProfileDetail/${this.props.match.params.id}`,
      );
      if (data) {
        this.setState({ usersData: data.adminUsersData[0] });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }

  userApi = async () => {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersActivityList/${this.props.match.params.id}`,
      );
      if (data) {
        data.usersActivity.activity.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState({ usersActivity: data.usersActivity });

        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };

  userActivitySortingApi = async (sort = '') => {
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/usersActivityList/${this.props.match.params.id}?sort=${sort}`,
      );
      if (data) {
        data.usersActivity.activity.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('LLL');
        });
        this.setState(data);
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  };
  render() {
    return (
      <div className="userhomepage">
        <div className="container-fluid menu-parts wow slideInDown">
          <AdminHeader />
        </div>
        <div className="container-fluid user-activity">
          <div className="container">
            <div className="row">
              <div className="col-12 wow fadeInUp">
                <Usertopmenubar user={this.state.usersData} {...this.props} />
                <div className="row">
                  <div className="col-12 wow fadeInUp">
                    <UseractivityShorting
                      user={this.state.usersData}
                      data={this.state.usersActivity}
                      {...this.props}
                      onSortChange={this.userActivitySortingApi}
                    />
                    {this.state.usersActivity?.activity?.length ? (
                      <Useractivitylisttable
                        user={this.state.usersData}
                        data={this.state.usersActivity}
                        {...this.props}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Useractivitylist;
