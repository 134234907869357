import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import httpAdminService from '../../services/httpAdminService';
import { toast } from 'react-toastify';
class AdminHeader extends Component {
  state = {
    data: '',
  };
  async componentDidMount() {
    try {
      const { data } = await httpAdminService.get(`${process.env.REACT_APP_APIENDPOINT}/admin/getAdmin`);
      if (data) {
        this.setState({ data: data.admin });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  render() {
    if (!this.state.data) return null;
    return (
      <div className="menu-design-userhome">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="logo-part">
              <Link className="navbar-brand" to="/admin/dashboard">
                <img src="/../../assets/images/logo/logo.png" alt="B-Thrive logo" />{' '}
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item ">
                  {' '}
                  <div className="nav-link user-profile" style={{ cursor: 'pointer!important' }}>
                    <img
                      src={
                        this.state.data.profilePicture
                          ? this.state.data.profilePicture
                          : '/../../assets/images/user/01.jpg'
                      }
                      alt="User Profile"
                    />{' '}
                    <span>{this.state.data.name}</span>
                    <div className="user-profile-bar">
                      <ul>
                        <li>
                          <Link to="/login" target="_blank">
                            <i className="fa fa-user"></i> User Panel
                          </Link>
                        </li>
                        <li>
                          <Link to="/admin/changePassword">
                            <i className="fa fa-lock"></i> Change Password
                          </Link>
                        </li>
                        <li>
                          <Link to="/admin/signOut">
                            <i className="fa fa-sign-out"></i> Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default AdminHeader;
