import React, { Component } from 'react';
import LinkedIn from 'linkedin-login-for-react';
import { Link, withRouter } from 'react-router-dom';
import * as registerService from '../services/registerServices';

class LinkedinRegisteration extends Component {
  constructor(props) {
    super(props);
    this.handleSuccess = this.handleSuccess.bind(this);
  }
  handleSuccess = async (error, code, redirectUri) => {
    const { data } = await registerService.linkedinAuthentication(code, redirectUri);
    if (error) return;
    if (data.isregistered)
      return await this.props.history.push({
        pathname: `/choose-interest`,
        state: data.user,
      });
    else return await this.props.history.push(`/user-home`);
  };

  render() {
    return (
      <Link>
        <div className="Login-sec2">
          <LinkedIn
            clientId="78739r4d1fs2b3"
            callback={this.handleSuccess}
            text="Continue to Signin with Linkedin"
            scope={['r_emailaddress', 'r_liteprofile']}
          ></LinkedIn>
        </div>
      </Link>
    );
  }
}

export default withRouter(LinkedinRegisteration);
