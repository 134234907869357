import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import Form from '../macroComponents/form/form';
import { withRouter } from 'react-router-dom';
import Joi from 'joi-browser';
import * as registerService from './../services/registerServices';

class EditPassword extends Form {
  state = {
    formData: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    errors: {},
  };
  schema = {
    oldPassword: Joi.string().required().min(8),
    newPassword: Joi.string().required().min(8),
    confirmNewPassword: Joi.string().required().min(8),
  };

  doSubmit = async () => {
    const { error } = await registerService.changePassword(this.state.formData);
    if (error) return;
    this.state.changePassword = false;
    this.setState({
      formData: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
    });
    return await this.props.history.push('/signOut');
  };
  constructor() {
    super();
    this.state.changePassword = false;
    this.doSubmit = this.doSubmit.bind(this);
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="edit-password">
            <div className="cardStyle">
              <h3 className="formTitle mt-4 mb-4">Edit Password</h3>
              <div className="dashboard-contactus-section">
                <div className="form-section wow bounceIn">
                  <form>
                    {this.renderInput('oldPassword', 'Old Password', 'Enter Old Password', 'password')}
                    {this.renderInput('newPassword', 'New Password', 'Enter New Password', 'password')}
                    {this.renderInput('confirmNewPassword', 'Confirm Password', ' Confirm Password', 'password')}
                    <div className="mt-5">{this.renderButton('Submit', this.handleSubmit)}</div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EditPassword);
