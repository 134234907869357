import React from 'react';
import Form from '../macroComponents/form/form';
import Joi from 'joi-browser';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './../commonComponents/header';
import WOW from 'wowjs';
import Footer from './../commonComponents/footer';
import * as registerService from './../services/registerServices';
import GoogleRegisteration from './googleRegisteration';
import LinkedinRegisteration from './linkedinRegisteration';
import { toast } from 'react-toastify';
import TermsAndConditionsModal from './TermsAndConditionModel';

class SignupPage extends Form {
  state = {
    formData: {
      email: '',
      password: '',
    },
    errors: {},
    showTermsAndConditions: true,
  };
  schema = {
    email: Joi.string().min(3).required().email(),
    password: Joi.string().required().min(8),
  };
  doSubmit = async () => {
    if (!this.ValidateEmail(this.state.formData.email)) {
      toast.error('Invalid Email');
      return;
    }
    const { error } = await registerService.createAccount(this.state.formData);
    if (error) return;
    this.props.submitEmail(this.state.formData.email);
    this.props.history.push({
      pathname: `/verify`,
      email: this.state.formData.email,
    });
  };
  getEmail = async (email) => {
    const register = { email: email };
    this.setState({ register });
  };
  ValidateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) return true;
    return false;
  };
  componentDidMount() {
    if (this.props.history.location.state)
      this.setState({
        formData: {
          email: this.props.history.location.state,
          password: '',
        },
      });
    new WOW.WOW({
      live: false,
    }).init();
  }
  render() {
    return (
      <React.Fragment>
      <TermsAndConditionsModal

        show={this.state.showTermsAndConditions}
        handleClose={() => this.setState({ showTermsAndConditions: true })}
        handleAccept={() => this.setState({ showTermsAndConditions: false })}
      />
        <div className="loginpage">
          <div className="container-fluid login-header">
            <Header {...this.props} />
          </div>
          <div className="login-section ">
            <div className="container">
              <div className="top-section text-center">
                <Link to="#">
                  <img src="assets/images/icons/login.png" alt="Login logo" />{' '}
                </Link>
                <h3>Create An Account </h3>
                <p>Join the B-Thrive Community</p>
              </div>
              <div className="form-section wow bounceIn">
                <form>
                  {this.renderInput('email', 'Enter Email Address', 'Enter Email Address')}
                  {this.renderInput('password', 'Enter Password', 'Enter Strong Password', 'password')}
                  {this.renderButton('Sign Up', this.handleSubmit)}
                  <div className="social-login ">
                    <p className="mt-3 mb-3 text-center">or</p>
                    <GoogleRegisteration />
                    <LinkedinRegisteration />
                  </div>
                </form>
              </div>
              <div className="login-footer text-center mt-5">
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p>
                <p>
                  Can't Login? <Link to="/forgot-password">Reset Password</Link>
                </p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default SignupPage;
