import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { toast } from 'react-toastify';
import moment from 'moment';
import Form from '../../../../macroComponents/form/form';
import ShowMoreText from 'react-show-more-text';
import httpAdminService from '../../../../services/httpAdminService';

class Blogs extends Form {
  state = {
    listData: '',
  };
  id = '';
  async componentDidMount() {
    this.blogApi();
  }

  async blogApi() {
    // this.loader('block');
    try {
      const { data } = await httpAdminService.get(
        `${process.env.REACT_APP_APIENDPOINT}/admin/otherBlogList/${this.props.user._id}`,
      );

      if (data) {
        data.blogList.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
        });
        const listData = data.blogList;
        this.setState({ listData: listData });

        // this.loader('none');

        return;
      }
    } catch (error) {
      // this.loader('none');
      toast.error(error.message);
      return;
    }
  }

  render() {
    return (
      <>
        <div className="learn-itemislider"></div>
        <div className="user-post-section">
          {this.state.listData.length ? (
            this.state.listData.map((data, index) => {
              return (
                <div className="post-display-section ">
                  <div className="main-section">
                    <div className="post-block mb-3 wow fadeInUp">
                      <div className="post-toppart">
                        <div className="left-part">
                          <Link className="nav-link">
                            <img src={data.createdBy.profilePicture} alt="User Profile" />
                            <p>{data.createdBy.name}</p> <span>Posted : {data.creationDate}</span>
                          </Link>
                        </div>
                      </div>
                      <div className="messge-box blogs-part">
                        <h5>
                          <ShowMoreText
                            /* Default options */
                            lines={3}
                            more="Read more"
                            less="Read less"
                            onClick={this.executeOnClick}
                            expanded={false}
                          >
                            {data.description}
                          </ShowMoreText>
                        </h5>
                        <a href={data.link} target="_blank">
                          {data.link}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>
              <h1 className="no-data-found">no Post made yet</h1>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default Blogs;
