import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-tabs/style/react-tabs.css';
import httpService from '../../services/httpService';
import AllPostQuestionList from '../../commonComponents/allPostQuestionList';
import { toast } from 'react-toastify';
import moment from 'moment';
import parse from 'html-react-parser';
import $ from 'jquery';
import * as eventServices from '../../services/learnWithBthriveServices';
import EventsSection from '../../adminComponent/eventsComponents/pastevents/eventsSection';

class ResultsTabs extends Component {
  state = {
    selectedTab: '',
    listData: '',
    eventList: '',
    page: Number(1),
  };
  pageData = [];
  tabType = '';
  searchKeyWord = '';
  componentDidMount = async () => {
    this.getPArams();
    if (this.tabType == 'event') this.eventList(this.searchKeyWord);
    else this.postAPI(this.tabType, this.state.page, this.searchKeyWord);
    //window.addEventListener('scroll', this.loadMore);
    // alert(searchKeyWord);
  };
  getPArams() {
    const search = window.location.search;
    const searchKeyWord = this.props.match.params.searchKeyWord;
    const getTabName = this.props.match.params.type;
    this.tabType = getTabName;
    this.searchKeyWord = searchKeyWord;
  }
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
    document.body.removeEventListener('touchmove', this.loadMore);
  }

  loader(type) {
    var shwload = document.getElementById('page_loader');
    shwload.style.display = type;
  }

  async postAPI(name, page, search) {
    this.tabType = name;
    this.searchKeyWord = search;
    //this.loader('block');
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/post/${name}?page=${page}&search=${search}`,
      );

      if (data) {
        data.postList[0].data.forEach((item) => {
          item.creationDate = item.creationDate && moment(item.creationDate).format('MMM DD, YYYY h:mm A');
          item.description = parse(item.description);
        });
        const listData = data.postList[0].data;
        this.totalData = data.postList[0].metadata[0] ? data.postList[0].metadata[0].total : 0;
        this.pageData = [...this.pageData, ...listData];
        this.setState({ listData: this.pageData });
        this.filterlist(this.state.listData);

        //this.loader('none');

        return;
      }
    } catch (error) {
      this.loader('none');
      toast.error(error.message);
      return;
    }
  }

  loadMore = () => {
    if (
      $(window).scrollTop() + $(window).height() == $(document).height() ||
      $(window).scrollTop() + window.innerHeight >= document.body.scrollHeight
    ) {
      if (this.pageData.length != this.totalData) {
        var page_count = this.state.page + Number(1);
        this.setState({
          page: page_count,
        });
        this.postAPI(this.tabType, this.state.page, this.searchKeyWord);
      }
    }
  };

  handleLike = (likeType, index, isChange) => {
    this.getPArams();
    const listData = [...this.state.listData];

    if (isChange == 1) {
      listData[index].my_like[0].type = 1;
      if (listData[index].likeList.length) {
        var index2 = listData[index].likeList.findIndex((p) => p.createdBy._id === this.props.user._id);
        if (index2 > -1) {
          listData[index].likeList.splice(index2, 1);
        }
      }
    } else {
      if (listData[index].my_like.length) {
        listData[index].my_like[0].type = likeType;
      } else {
        listData[index].my_like.push({ type: likeType });
      }
      if (listData[index].likeList.length) {
        const found2 = listData[index].likeList.some((el) => el.createdBy._id === this.props.user._id);
        if (!found2) {
          listData[index].likeList.push({
            createdBy: { name: this.props.user.name, _id: this.props.user._id },
          });
        }
      } else {
        listData[index].likeList.push({
          createdBy: { name: this.props.user.name, _id: this.props.user._id },
        });
      }
    }
    this.setState({
      listData,
    });
    // this.setState({language: langValue});
  };
  async eventList(search) {
    this.tabType = 'event';
    this.searchKeyWord = search;
    const events = (await eventServices.getEvents('', search, 'upcoming')).data.events;
    const classes = (await eventServices.getClasses('', search, 'upcoming')).data.classes;
    let temp = events.concat(classes);
    temp.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));
    this.setState({ eventList: temp });
  }

  async hitApi(name, page, search) {
    this.pageData = [];
    if (name == 'event') this.eventList(search);
    else this.postAPI(name, page, search);
    await this.props.history.push(`/search-result/${search}/${name}`);
  }

  filterlist = (data) => {
    let listData = data;
    if (this.props.user) {
      for (var index = 0; index < listData.length; index++) {
        if (
          this.props.user.usersBlocked.includes(listData[index].createdBy._id) ||
          listData[index].createdBy.usersBlocked.includes(this.props.user._id)
        ) {
          listData.splice(index, 1);
          index--;
        }
      }
    }
    this.setState({ listData: listData });
  };
  onEdit = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.componentDidMount();
  };

  onBlock = () => {
    this.setState({ page: Number(1) });
    this.pageData = [];
    this.componentDidMount();
  };
  render() {
    return (
      <div>
        <div className="shorting-dashboard mt-3 ">
          <div className="main-section">
            <div className="left-part">
              <p>Search Result for "{this.searchKeyWord}"</p>
            </div>
          </div>
        </div>
        <div className="result-tabspart mt-3 ">
          <div className="main-section">
            <div className="searchtabs">
              <ul>
                <li className={this.tabType == 'post' ? 'active' : ''}>
                  <Link onClick={() => this.hitApi('post', 1, this.searchKeyWord)}>Posts</Link>
                </li>
                <li className={this.tabType == 'question' ? 'active' : ''}>
                  <Link onClick={() => this.hitApi('question', 1, this.searchKeyWord)}>Questions </Link>
                </li>
                <li className={this.tabType == 'event' ? 'active' : ''}>
                  <Link onClick={() => this.hitApi('event', 1, this.searchKeyWord)}>Events</Link>
                </li>
              </ul>
            </div>
            {this.tabType == 'event' ? (
              <EventsSection data={this.state.eventList} />
            ) : (
              <AllPostQuestionList
                listData={this.state.listData}
                onLikeChange={this.handleLike}
                user={this.props.user}
                onBlock={this.onBlock}
                onEdit={this.onEdit}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ResultsTabs;
