import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Footer from '../commonComponents/footer';
import Header from '../commonComponents/header';
class FaqSection extends Component {
  state = {};
  render() {
    return (
      <>
        <Header {...this.props} />
        <div className="guide mb-5">
          <div className="guidebody">
            <div className="rules">
              <center>
                <h1>
                  <b>B-Thrive FAQ/ Support</b>
                </h1>
              </center>
              <ul>
                <li>
                  <p>
                    <b>I’m having technical issues, how do I contact the B-Thrive team?</b>
                  </p>
                  <p>
                    If you have a technical issue, please send us a message using the contact support box shown on the
                    left-hand side of every page.
                  </p>
                </li>
                <li>
                  <p>
                    <b>I’m unable to log in. What now?</b>
                  </p>
                  <p>
                    If you are having issues logging into your profile, please send us a message using the “Contact Us”
                    box on the landing page.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Will my information be stored? Is B-Thrive GDPR compliant?</b>
                  </p>
                  <p>Please refer to our “Cookies Policy” in the footer page on the Homepage</p>
                </li>
                <li>
                  <p>
                    <b>How do I delete my account?</b>
                  </p>
                  <p>Log in to your profile and click on the delete button at the bottom of the page.</p>
                </li>
                <li>
                  <p>
                    <b>How do I create a post?</b>
                  </p>
                  <p>
                    First, enter the community space. Then click on the “Create Post” button or create a post from
                    anywhere through the plus (+) button.
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I use the “Insight” feature?</b>
                  </p>
                  <p>
                    Use the “Insight” feature to add additional information to a post. The original creator of the post
                    will then review and accept or decline your additions. If approved, your content will be published.
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I become a Blogger/Super Blogger?</b>
                  </p>
                  <p>
                    When registering, under the registration question, “Are you a blogger”, choose the “yes” option. Or,
                    if you have an existing profile, click on the button, “Switch to blogger profile” at the bottom
                    section of your profile.
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I get verified as a blogger?</b>
                  </p>
                  <p>
                    To begin the blogger verification process, from your profile, scroll to the bottom and click on the
                    “Verify” button. Complete and submit the form. Your information will be reviewed, approved or denied
                    by the admins. Please keep in mind, your request will be rejected if we find you’ve submitted false,
                    inaccurate information.
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I provide general feedback?</b>
                  </p>
                  <p>
                    Sharing feedback is crucial to improve the B-Thrive app created for you. Whether suggestions for
                    improvement, website issues, questions, and/or concerns, please use the “Contact Us” box to send
                    your feedback.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Where do I find current and past event information?</b>
                  </p>
                  <p>
                    Event information can be found in the “Learning with B-Thrive” worklet and in the community section,
                    on the right-hand side. Past events, book clubs, and classes can be found by clicking on the “Past
                    Events” button in the “Learning with B-Thrive” worklet.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Where do I find my personal referral link?</b>
                  </p>
                  <p>Your referral link can be found in your profile settings.</p>
                </li>
                <li>
                  <p>
                    <b>How to become a host?</b>
                  </p>
                  <p>
                    Everyone can become a host for a month. Become the next Queen/King Host by clicking on become host
                    and select the timeframe within the available dates. Our admins will review your request and then
                    approve if aligned with our guidelines.
                  </p>
                </li>
                <li>
                  <p>
                    <b>Is there a blueprint to engaging when hosting a Book Club event?</b>
                  </p>
                  <p>
                    Please feel free to bring your own “flava”, but if you’re new to book clubs, here are some tips:
                  </p>
                  <ol>
                    <li>Post a picture of the selected book in the community section.</li>
                    <li>Explain why this book was chosen</li>
                    <li>
                      Define a subject (and what you want to focus on: theme/ general aspect of the book/ learning/
                      etc.)
                    </li>
                    <li>
                      From time to time, check-in on your audience and engage. See where they’re at in the book, share
                      your thoughts, a quote, related music, etc. Create a sense of belonging and community while
                      reading the book.
                    </li>
                    <li>Before the event: Create a Zoom link maximum 2 weeks prior to the event.</li>
                    <li>
                      During the event: Remind your attendees of the topic, the focus, purpose, and open up with your
                      favorite part of the book relative to the main topic or question. Make sure to moderate the event
                      when necessary (re-focusing the conversation; asking questions to help attendees to open up;
                      summarise time to time when necessary; etc.).
                    </li>
                    <li>After the event: If the zoom call has been recorded, please share the recording.</li>
                  </ol>
                </li>
                <li>
                  <p>
                    <b>How do I learn with B-Thrive?</b>
                  </p>
                  <p>
                    In the “Learn with B-Thrive” section we will provide our own educational content and content in
                    collaboration with our partners. You can access this space through the community space on the
                    left-hand side. Our “Learn with B-Thrive” posts will also be marked and shared in the community
                    space.
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I become a B-Thrive ambassador?</b>
                  </p>
                  <p>
                    If you are interested in becoming an ambassador, please reach out to us at{' '}
                    <u>partnershipmanagement@b-thrivecommunity.com</u>
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I create a collection?</b>
                  </p>
                  <p>
                    In the “Learn with B-Thrive” section we will provide our own educational content and content in
                    collaboration with our partners. You can access this space through the community space on the
                    left-hand side. Our “Learn with B-Thrive” posts will also be marked and shared in the community
                    space.
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I learn with B-Thrive?</b>
                  </p>
                  <p>
                    Use the collection feature to save and share interesting content with the B-Thrive community. To
                    save content directly from a post you’re interacting with, click on the ellipsis in the upper-right
                    corner and choose “Save to collection” or “Create a new one”.
                  </p>
                  <p>
                    You also have the ability to navigate to the Collection through the community space by clicking on
                    “Collection” on the left-hand side. Then follow the steps above.
                  </p>
                </li>
                <li>
                  <p>
                    <b>What is the difference between a Collection and Bookmark?</b>
                  </p>
                  <p>
                    Collections are public, any B-Thrive member can view your collection(s). Bookmarks, on the other
                    hand, allow you to save content privately.
                  </p>
                </li>
                <li>
                  <p>
                    <b>How do I use the “Spaces” section?</b>
                  </p>
                  <p>
                    B-Thrive’s “Spaces” section is your universe to navigate through different categories of interest.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default FaqSection;
