import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import httpService from '../../../services/httpService';
import { toast } from 'react-toastify';
import ShowMoreText from 'react-show-more-text';
class BloggerProfileImage extends Component {
  state = {
    usersData: {},
  };
  async componentDidMount() {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_APIENDPOINT}/bloggersProfile/${this.props.match.params.id}`,
      );
      if (data) {
        this.setState({
          usersData: data.usersData[0],
        });
        return;
      }
    } catch (error) {
      toast.error(error.message);
      return;
    }
  }
  render() {
    return (
      <div>
        <div className="profile-image-section">
          <div className="user-image">
            <img
              className={this.state.usersData.isBlogger ? 'blogger' : ''}
              src={
                this.state.usersData.profilePicture
                  ? this.state.usersData.profilePicture
                  : '../../../assets/images/user/01.jpg'
              }
              alt="User Profile"
            />
          </div>
          <div className="user-detail text-center">
            <h2 className="">{this.state.usersData.name}</h2>
            <p>
              <ShowMoreText
                /* Default options */
                lines={3}
                more="Read more"
                less="Read less"
                onClick={this.executeOnClick}
                expanded={false}
              >
                {this.state.usersData.description ? this.state.usersData.description : ''}
              </ShowMoreText>
            </p>
            {this.state.usersData.blogLink ? (
              <p>
                <a href={this.state.usersData.blogLink} target="_blank" className="websitelink">
                  {this.state.usersData.blogLink}
                </a>
              </p>
            ) : (
              ''
            )}
            {this.state.usersData.website ? (
              <p>
                <a href={this.state.usersData.website} target="_blank" className="websitelink">
                  {this.state.usersData.website}
                </a>
              </p>
            ) : (
              ''
            )}
            <div className="setlite-ghana">
              {this.state.usersData.location ? (
                <Link>
                  <i className="fa fa-map-marker"></i>
                  {this.state.usersData.location}
                </Link>
              ) : null}
              {this.state.usersData.origin ? (
                <Link>
                  <i className="fa fa-dot-circle-o"></i>
                  {this.state.usersData.origin}
                </Link>
              ) : null}
            </div>
          </div>
          <div className="blogger-count-section" style={{ marginRight: '60px' }}>
            <div className="row ">
              <div className="col-4">
                <div className="count-box">
                  <p>
                    {this.state.usersData.total_posts} <span>Post</span>
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="count-box ">
                  <p>
                    {this.state.usersData.total_collections}
                    <span>Collections</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BloggerProfileImage;
