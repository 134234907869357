import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import MediaQuery from 'react-responsive';

class SpacesRightside extends Component {
  state = {};

  handleModalShowHide1 = () => {
    this.setState({ showHide1: !this.state.showHide1 });
  };
  render() {
    return (
      <>
        <div style={{ overflow: 'auto' }}>
          <div className="spaceright-side">
            <div className="heading-part">
              <h3>Trending Spaces</h3>
            </div>
            <div className="main-part">
              <ul>
                {this.props.interests
                  ? this.props.interests.map((item, index) => {
                      return (
                        <li key={index}>
                          <i className="fa fa-plus-circle" onClick={() => this.props.onAddSpace(item._id)}></i>
                          {item.name}
                        </li>
                      );
                    })
                  : ''}
              </ul>
            </div>
          </div>
        </div>
        <MediaQuery query="(max-width:767px)">
          <Link onClick={() => this.handleModalShowHide1()}>
            <span className="event-btn">Interests</span>
          </Link>
          <div style={{ overflow: 'auto' }}>
            <div className="spaceright-side1 ">
              <div className="heading-part">
                <h3>Trending Spaces</h3>
              </div>
              <div className="main-part">
                <ul>
                  {this.props.interests
                    ? this.props.interests.map((item, index) => {
                        return (
                          <li key={index}>
                            <i className="fa fa-plus-circle" onClick={() => this.props.onAddSpace(item._id)}></i>
                            {item.name}
                          </li>
                        );
                      })
                    : ''}
                </ul>
              </div>
            </div>
          </div>
        </MediaQuery>
      </>
    );
  }
}

export default SpacesRightside;
